import type { CardListResult, ListResult } from "@/api/model/listModel";
import { request } from "@/utils/request";

const Api = {
  BaseList:
    "https://service-bv448zsw-1257786608.gz.apigw.tencentcs.com/api/get-list",
  CardList:
    "https://service-bv448zsw-1257786608.gz.apigw.tencentcs.com/api/get-card-list",
};

export function getList() {
  return request.get<ListResult>({
    url: Api.BaseList,
  });
}

export function getCardList() {
  return request.get<CardListResult>({
    url: Api.CardList,
  });
}
