import { createPinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate";

const store = createPinia();
store.use(createPersistedState());

export { store };

export * from "./modules/notification";
export * from "./modules/setting";
export * from "./modules/tabs-router";
export * from "./modules/user";
export * from "./modules/config";

export default store;
