<script lang="tsx">
export default {
  name: "pagesMobileOrder"
};
</script>
<script setup lang="tsx">
import { OrderState } from "@/apis/enum";
import UTILS from "@/utils";
import {
  DialogPlugin,
  MessagePlugin,
  PageInfo,
  RadioValue,
} from "tdesign-vue-next";
const rData = ref<OrderPageData>();
const pageData = ref<OrderPage>({
  pageNum: 1,
  pageSize: 20,
  entity: { ...INIT.Order, orderState: OrderState.Unshipped },
});

const getData = async () => {
  const r = await API.Mobile.GetOrder(pageData.value);
  rData.value = r;

  if (pageData.value.entity?.orderState == OrderState.Shipped)
    r.dataList.forEach(async order => {
      order.traceList = await API.Mobile.GetLogisticsTrade(order.id);
    })
};

onMounted(async () => {
  await getData();
});



const onChange = (pageInfo: PageInfo) => {
  pageData.value.pageNum = pageInfo.current;
  pageData.value.pageSize = pageInfo.pageSize;
  getData();
};

const tabChange = (value: RadioValue) => {
  pageData.value.entity.orderState = value as any;
  getData();
};


const getTract = (order: Order, exNum: string) => {
  const tractList = order.traceList?.find(s => s.outSid == exNum)?.traceList;
  if (!tractList) return "暂无轨迹信息"
  const tract = tractList[tractList.length - 1]
  return tract.statusTime + " " + tract.statusDesc
}



const sign = async (orderId: number) => {
  const dialog = DialogPlugin.confirm({
    theme: "warning",
    width: "80%",
    closeBtn: false,
    header: "确认收货？",
    onConfirm: async () => {
      const r = await API.Mobile.Sign(orderId);
      if (r) {
        MessagePlugin.success("操作成功")
        getData();
      }
      dialog.destroy();

    },
  });
}

const keys: typeof pageData.value.entity.orderState[] = [OrderState.Unshipped, OrderState.Shipped, OrderState.Signed]

let startX = 0;
let startY = 0;
const touchstart = (event: TouchEvent) => {
  startX = event.changedTouches[0].pageX;
  startY = event.changedTouches[0].pageY;
}
const touchend = (event: TouchEvent) => {
  const ele = event.target as Element;
  const parents = UTILS.getAllParents(ele);
  if (parents.some(s => s.className.startsWith("t-tabs") || s.className.startsWith("t-dialog")) 
  || Math.abs(event.changedTouches[0].pageY - startY) > Math.abs(event.changedTouches[0].pageX - startX))
    return;
  let index = keys.indexOf(pageData.value?.entity?.orderState)
  if (event.changedTouches[0].pageX - startX > 10) index--;
  else if (event.changedTouches[0].pageX - startX < -10) index++;
  if (index < 0 || index >= keys.length) return;
  pageData.value.entity.orderState = keys[index];

  getData();
}

</script>

<template>
  <div class="body" @touchstart="touchstart" @touchend="touchend">
    <t-tabs v-model="pageData.entity.orderState" @change="tabChange">
      <t-tab-panel :value="OrderState.Unshipped" label="未发货"> </t-tab-panel>
      <t-tab-panel :value="OrderState.Shipped" label="已发货"> </t-tab-panel>
      <t-tab-panel :value="OrderState.Signed" label="已收货"> </t-tab-panel>
      </t-tabs>

    <t-list split class="tlist">
      <t-list-item v-for="item in rData?.dataList">
        <table class="datalist">
        <tbody>
          <tr>
            <td class="title" valign="top">订单编号:</td>
            <td style="display: flex;justify-content: space-between;">
              {{ item.code }}
              <t-button v-if="item.orderState == OrderState.Shipped" variant="outline" size="small"
                @click="sign(item.id)" theme="success">确定收货</t-button>
            </td>
          </tr>
          <tr>
            <td class="title" valign="top">卡券编号:</td>
            <td>{{ item.card?.cardNo }}</td>
          </tr>
          <tr>
            <td class="title" valign="top">下单日期:</td>
            <td>{{ item.creadTime }}</td>
          </tr>
          <tr>
            <td class="title" valign="top">收货信息:</td>
            <td>
              <div>
                {{ item.address?.name }} {{ item.address?.phone }}
              </div>
              <div>
                {{ item.address?.province }} {{ item.address?.city }}
                {{ item.address?.region }} {{ item.address?.town }}
                {{ item.address?.detail }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="title" valign="top">商品信息:</td>
            <td> <b v-for="detail in item.detailList">
                {{ detail.goods.name }} {{ detail.goods.stand }} x {{ detail.qty }} <br>
              </b></td>
          </tr>
          <tr v-if="item.orderState != OrderState.Unshipped">
            <td class="title" valign="top">快递信息:</td>
            <td>
              <div v-for="exNum in item.expressNumber.split('|').filter(s => s)">
                <b>
                  {{ item.expressCompany }} {{ exNum }}
                </b>
                <div>{{ getTract(item, exNum) }} </div>
              </div>
            </td>
          </tr>
        </tbody>
        </table>
      </t-list-item>
      <template #footer v-if="rData?.pageCount > 1">
        <t-pagination class="page" v-model="pageData.pageNum" :showPageSize="false" :totalContent="false"
          v-model:pageSize="pageData.pageSize" :total="rData?.totalCount" @change="onChange" />
      </template>
    </t-list>
    <t-empty class="empty" v-if="rData?.dataCount == 0" />

  </div>
</template>

<style lang="less" scoped>
.title {
  color: var(--td-text-color-placeholder);
  width: 70px;
}

.datalist {
  font-size: 12px;
  border-spacing: 0px 5px;
}

.body {
  padding: 0 10px;

  & .tlist {
    max-height: calc(100vh - 110px);
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .empty {
    margin-top: 60%;
  }
}

.page {
  justify-content: center;
}

.meta {
  align-items: center;

  & :first-child {
    border-radius: 0;
  }
}
</style>
