import Layout from "@/layouts/index.vue";
import { WalletIcon } from "tdesign-icons-vue-next";
import { RouteRecordRaw } from "vue-router";

import batchList from "./batchList.vue";
import typeList from "./typeList.vue";
import cardList from "./cardList.vue";

// const dd:ResultState=ResultState.BadRequest;

const router: RouteRecordRaw = {
  path: "/card",
  component: Layout,
  redirect: "/card/batchList",
  name: "card",
  meta: {
    title: "卡券管理",
    icon: shallowRef(WalletIcon),
    orderNo: 0,
  },
  children: [
    {
      path: "batchList",
      name: batchList.name,
      component: batchList,
      meta: {
        title: "批次列表",
        ishome: true,
        show: ()=>Per.CardGetBatchList
      },
    },
    {
      path: "cardList",
      name: cardList.name,
      component: cardList,
      meta: {
        title: "卡券列表",
        show: ()=>Per.CardGetCardList
      },
    },
    {
      path: "typeList",
      name: typeList.name,
      component: typeList,
      meta: {
        title: "类型列表",
        show: ()=>Per.CardTypeGetList
      },
    },
  ],
};

export default router;
