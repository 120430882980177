import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "200",
  height: "140",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g mask=\"url(#mask0_21_716)\"><path fill=\"#97A3B7\" fill-rule=\"evenodd\" d=\"M33 46.843 96.321 119l63.322-72.157C142.742 32 120.583 23 96.321 23 72.061 23 49.901 32 33 46.843\" clip-rule=\"evenodd\"></path><g filter=\"url(#filter0_f_21_716)\"><path fill=\"#E3E6EB\" d=\"M95 21h80v60H95z\"></path></g><g filter=\"url(#filter1_f_21_716)\"><path fill=\"#E3E6EB\" d=\"M-7 43h80v60H-7z\"></path></g></g><path fill=\"currentcolor\" d=\"m72.812 63.688-3.157 3.157 6.246 6.247a50.3 50.3 0 0 0-12.713 8.151L96.321 119l11.913-13.575 6.413 6.412 3.157-3.157L80.45 71.326zM129.455 81.243l-15.318 17.455-28.74-28.74a50.4 50.4 0 0 1 10.924-1.19c12.695 0 24.29 4.709 33.134 12.475\"></path><path fill=\"#fff\" stroke=\"#000\" d=\"m152 21.822 4.867 17.053.133.465.469-.117 17.202-4.312-12.335 12.741-.337.348.337.348 12.335 12.741-17.202-4.312-.469-.117-.133.465L152 74.178l-4.867-17.053-.133-.465-.469.117-17.202 4.312 12.335-12.741.337-.348-.337-.348-12.335-12.741 17.202 4.312.469.117.133-.465z\"></path><path stroke=\"#fff\" stroke-width=\"2\" d=\"M101 31 90 42l11 11-8 8\"></path>", 4)
  ])))
}
export default { render: render }