<template>
  <div>
    <div class="login-wrapper">
      <div class="login-container">
        <div v-if="showLeft" class="login-left">
          <img src="@/assets/login-bg.svg?url" />
        </div>
        <div :class="showLeft ? 'login-right' : 'login-full'">
          <div class="right-container">
            <t-loading :loading="loading" :show-overlay="false">
            <div class="login-content">
              <div class="title-container">
                <img src="/logo.png" class="sysLogo" />

                <div class="title systitle">{{ config?.sysName ?? "" }}</div>
                </div>
              <t-form
                ref="form"
                :style="{ opacity: loading ? 0 : 1 }"
                :class="['item-container', `login-${type}`]"
                :data="formData"
                :rules="FORM_RULES"
                label-width="0"
                @submit="onSubmit"
              >
                <template v-if="type == 'password'">
                  <t-form-item name="account">
                    <t-input
                      v-model="formData.account"
                      size="large"
                      placeholder="请输入账号"
                    >
                      <template #prefix-icon>
                        <t-icon name="user" />
                      </template>
                    </t-input>
                  </t-form-item>

                  <t-form-item name="password">
                    <t-input
                      v-model="formData.password"
                      size="large"
                      :type="showPsw ? 'text' : 'password'"
                      clearable
                      placeholder="请输入密码"
                    >
                      <template #prefix-icon>
                        <t-icon name="lock-on" />
                      </template>
                      <template #suffix-icon>
                        <t-icon
                          :name="showPsw ? 'browse' : 'browse-off'"
                          @click="showPsw = !showPsw"
                        />
                      </template>
                    </t-input>
                  </t-form-item>

                  </template>

                <template v-else-if="type == 'qrcode'">
                  <div class="ww_login"></div>
                  </template>

                <template v-else>
                  <t-form-item name="phone">
                    <t-input
                      v-model="formData.phone"
                      size="large"
                      placeholder="请输入手机号码"
                    >
                      <template #prefix-icon>
                        <t-icon name="mobile" />
                      </template>
                    </t-input>
                  </t-form-item>

                  <t-form-item class="verification-code" name="verifyCode">
                    <t-input
                      v-model="formData.verifyCode"
                      size="large"
                      placeholder="请输入验证码"
                    />
                    <t-button
                      size="large"
                      variant="outline"
                      :disabled="countDown > 0"
                      @click="sendCode"
                    >
                      {{
                        countDown == 0 ? "发送验证码" : `${countDown}秒后可重发`
                      }}
                    </t-button>
                  </t-form-item>
                </template>

                <t-form-item v-if="type !== 'qrcode'" class="btn-container">
                  <t-button block size="large" type="submit"> 登录 </t-button>
                </t-form-item>
                <div class="switch-container">
                  <span
                    v-if="type !== 'password'"
                    class="tip"
                    @click="switchType('password')"
                    >使用账号密码登录</span
                  >
                  <span
                    v-if="type !== 'qrcode'"
                    class="tip"
                    @click="switchType('qrcode')"
                    >使用企业微信登录</span
                  >
                  </div>
              </t-form>
            </div>
          </t-loading>

          </div>
     

          <Footer></Footer>

        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "pagesLoginIndex"
};
</script>
<script setup lang="ts">
import {
  FormInstanceFunctions,
  FormRule,
  SubmitContext,
} from "tdesign-vue-next";
import { useUserStore, useConfigStore } from "@/store";
import { useCounter } from "@/hooks";
import * as ww from "@wecom/jssdk";
import { isEmpty } from "lodash-es";
import { useRoute, useRouter } from "vue-router";
import Footer from "@/layouts/components/Footer.vue";
const router = useRouter();
const route = useRoute();

const loading = ref(false);

const loginAsync = async (userInfo: LoginData) => {
  loading.value = true;
  const state = await useUserStore().login(userInfo);
  if (!state) {
    loading.value = false;
  }
};
const type = ref("password");

if (!isEmpty(route.query.code)) {
  loginAsync({ code: route.query.code as string });
}

const FORM_RULES: Record<string, FormRule[]> = {
  phone: [{ required: true, message: "手机号必填", type: "error" }],
  account: [{ required: true, message: "账号必填", type: "error" }],
  password: [{ required: true, message: "密码必填", type: "error" }],
  verifyCode: [{ required: true, message: "验证码必填", type: "error" }],
};
const INITIAL_DATA = {
  phone: "",
  account: "",
  password: "",
  verifyCode: "",
  checked: false,
  code: "",
};

const [countDown, handleCounter] = useCounter();

const form = ref<FormInstanceFunctions>();
const formData = ref({ ...INITIAL_DATA });
const showPsw = ref(false);
const showLeft = ref(false);

const switchType = (val: string) => {
  type.value = val;
  if (val == "qrcode") {
    const path = Array.isArray(route.query.redirect ?? "/") ? route.query.redirect[0] : route.query.redirect;
    nextTick(() => {
     const {el} = ww.createWWLoginPanel({
        el: ".ww_login",
        params: {
          login_type: ww.WWLoginType.corpApp,
          appid: config.value.appid,
          agentid: config.value.agentid,
          redirect_uri: `${config.value.domain}/login?redirect=${path}`,
          state: "state",
          redirect_type: ww.WWLoginRedirectType.top,
          panel_size: ww.WWLoginPanelSizeType.small,
        },
        onCheckWeComLogin({ isWeComLogin }) {
          console.log(isWeComLogin);
        },
        onLoginSuccess({ code }) {
          loginAsync({ code });
        },
        onLoginFail(err) {
          console.log(err);
        },
      });
      el.style.width="280px";

    });
  }
};


const sendCode = () => {
  form.value.validate({ fields: ["phone"] }).then((e) => {
    if (e === true) {
      handleCounter();
    }
  });
};

const onSubmit = async (ctx: SubmitContext) => {

  if (ctx.validateResult === true) {
    await loginAsync(formData.value);
  }
};

const AutoHide = () => {
  showLeft.value = window.innerWidth > 840;
};

const configstore = useConfigStore();
const { config } = storeToRefs(configstore);
onMounted(async () => {
  AutoHide();
  window.onresize = () => {
    AutoHide();
  };
});
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
