<template>
  <div :class="layoutCls">
    <t-head-menu
      :class="menuCls"
      :theme="menuTheme"
      expand-type="popup"
      :value="active"
    >
      <template #logo>
        <span
          v-if="showLogo"
          class="header-logo-container"
          @click="handleNav('/dashboard/base')"
        >
          <div class="sysInfo">
            <img src="/logo-home.png" class="sysLogo-big" />
            </div>
        </span>
        <div v-else class="header-operate-left">
          <t-button
            theme="default"
            shape="square"
            variant="text"
            @click="changeCollapsed"
          >
            <collapsedIco
              v-if="settingStore.isSidebarCompact"
              style="height: 20px"
            ></collapsedIco>
            <expandedIco v-else style="height: 20px"></expandedIco>
          </t-button>
          <search :layout="layout" />
        </div>
      </template>
      <template #default>
        <div v-if="layout !== 'side'">
          <menu-content class="header-menu" :nav-data="menu" />
        </div>
      </template>
      <template #operations>
        <div class="operations-container">
          <search v-if="layout !== 'side'" :layout="layout" />

          <t-dropdown :min-column-width="120" trigger="click">
            <template #dropdown>
              <t-dropdown-menu>
                <t-dropdown-item
                  class="operations-dropdown-container-item"
                  @click="handleNav('/user/index')"
                >
                  <t-icon class="header-user-avatar" name="user-setting" />
                  个人信息
                </t-dropdown-item>
                <t-dropdown-item
                  class="operations-dropdown-container-item"
                  @click="handleLogout"
                >
                  <t-icon class="header-user-avatar" name="poweroff" />
                  退出登录
                </t-dropdown-item>
              </t-dropdown-menu>
            </template>
            <t-button class="header-user-btn" theme="default" variant="text">
              <template #icon>
                <avatarIco
                  v-if="!user.userInfo?.avatar"
                  style="margin-right: 4px; width: 24px; height: 24px"
                />
                <t-avatar
                  v-else
                  :image="user.userInfo.avatar"
                  :hide-on-load-failed="true"
                  style="margin-right: 4px"
                  size="small"
                />
              </template>
              <div class="header-user-account">{{ user.userInfo.name }}</div>
              <template #suffix><chevron-down-icon /></template>
            </t-button>
          </t-dropdown>
          <t-tooltip placement="bottom" content="系统设置">
            <t-button
              theme="default"
              shape="square"
              variant="text"
              @click="toggleSettingPanel"
            >
              <setting-icon />
            </t-button>
          </t-tooltip>
        </div>
      </template>
    </t-head-menu>
  </div>
</template>

<script lang="tsx">
export default {
  name: "layoutsComponentsHeader"
};
</script>
<script setup lang="tsx">
import {
  ChevronDownIcon,
  PoweroffIcon,
  SettingIcon,
  UserCircleIcon,
} from "tdesign-icons-vue-next";
import collapsedIco from "@/assets/collapsed.svg?component";
import expandedIco from "@/assets/expanded.svg?component";
import avatarIco from "@/assets/avatar.svg?component";

import type { PropType } from "vue";
import { computed } from "vue";
import { useRouter } from "vue-router";

import LogoFull from "@/assets/assets-logo-full.svg?component";
import { getActive } from "@/router";
import { useConfigStore, useSettingStore, useUserStore } from "@/store";

import MenuContent from "./MenuContent.vue";
import Notice from "./Notice.vue";
import Search from "./Search.vue";
import {
  DialogPlugin,
  FormInstanceFunctions,
  FormRule,
  MessagePlugin,
  SubmitContext,
} from "tdesign-vue-next";

const prefix = import.meta.env.VITE_BASE_NAMESPACE;

const props = defineProps({
  theme: {
    type: String,
    default: "light",
  },
  layout: {
    type: String,
    default: "top",
  },
  showLogo: {
    type: Boolean,
    default: true,
  },
  menu: {
    type: Array as PropType<MenuRoute[]>,
    default: () => [],
  },
  isFixed: {
    type: Boolean,
    default: false,
  },
  isCompact: {
    type: Boolean,
    default: false,
  },
  maxLevel: {
    type: Number,
    default: 3,
  },
});

const router = useRouter();
const settingStore = useSettingStore();
const user = useUserStore();
const configStore = useConfigStore();

const { config } = storeToRefs(configStore);

const toggleSettingPanel = () => {
  settingStore.updateConfig({
    showSettingPanel: true,
  });
};

const active = computed(() => getActive());

const layoutCls = computed(() => [`${prefix}-header-layout`]);

const menuCls = computed(() => {
  const { isFixed, layout, isCompact } = props;
  return [
    {
      [`${prefix}-header-menu`]: !isFixed,
      [`${prefix}-header-menu-fixed`]: isFixed,
      [`${prefix}-header-menu-fixed-side`]: layout === "side" && isFixed,
      [`${prefix}-header-menu-fixed-side-compact`]:
        layout === "side" && isFixed && isCompact,
    },
  ];
});
const menuTheme = computed(() => props.theme as ModeType);
const changeCollapsed = () => {
  settingStore.updateConfig({
    isSidebarCompact: !settingStore.isSidebarCompact,
  });
};

const handleNav = (url: string) => {
  const FORM_RULES: Record<string, FormRule[]> = {
    name: [{ required: true, message: "请输入用户姓名", type: "error" }],
    phone: [
      {
        required: true,
        message: "请输入手机号",
        type: "error",
      },
      {
        telnumber: true,
        message: "请输入正确手机号",
        type: "error",
      },
    ],
  };
  const userTemp = ref<User>({
    account: user.userInfo.wid,
    name: user.userInfo.name,
    phone: user.userInfo.phone,
    avatar: { fileUrl: user.userInfo.avatar },
  });

  const formSub = async (context: SubmitContext<FormData>) => {
    if (context.validateResult == true) {
      const r = await user.editSelf(userTemp.value);
      if (r) {
        MessagePlugin.success("提交成功");
        alert.destroy();
      }
    }
  };
  const alert = DialogPlugin.alert({
    header: "个人信息",
    width: 500,
    footer: false,
    body: () => (
      <t-form
        class="base-form"
        label-align="top"
        rules={FORM_RULES}
        data={userTemp.value}
        onSubmit={formSub}
      >
        <t-row class="row-gap" gutter={[30, 10]} style="margin: 0">
          <t-col span={6}>
            <t-form-item label="用户头像" name="avatar">
              <img-upload
                fileList={[userTemp.value.avatar]}
                onChange={(file: Upload) => {
                  userTemp.value.fileId = file.id;
                  userTemp.value.avatar = file;
                }}
                max-size={200}
              />
            </t-form-item>
          </t-col>
          <t-col span={6}>
            <t-row>
              <t-col span={12}>
                <t-form-item label="用户名" name="account">
                  <t-input
                    v-model={userTemp.value.account}
                    disabled
                    placeholder="用户名"
                  />
                </t-form-item>
              </t-col>
              <t-col span={12}>
                <t-form-item label="姓名" name="name">
                  <t-input
                    placeholder="姓名"
                    disabled
                    v-model={userTemp.value.name}
                  />
                </t-form-item>
              </t-col>
              <t-col span={12}>
                <t-form-item label="密码" name="password">
                  <t-input
                    v-model={userTemp.value.password}
                    type="password"
                    placeholder="密码"
                  />
                </t-form-item>
              </t-col>

              <t-col span={12}>
                <t-form-item label="手机号" name="phone">
                  <t-input
                    placeholder="手机号"
                    v-model={userTemp.value.phone}
                  />
                </t-form-item>
              </t-col>
            </t-row>
          </t-col>
          <t-col span={12} style="text-align:right;margin-top:15px">
            <t-button type="submit">提交</t-button>
          </t-col>
        </t-row>
      </t-form>
    ),
  });
};

const handleLogout = () => {
  user.logout();
};
</script>
<style lang="less" scoped>
.@{starter-prefix}-header {
  &-menu-fixed {
    position: fixed;
    top: 0;
    z-index: 1001;

    :deep(.t-head-menu__inner) {
      padding-right: var(--td-comp-margin-xl);
    }

    &-side {
      left: 232px;
      right: 0;
      z-index: 10;
      width: auto;

      &-compact {
        left: 64px;
      }
    }
  }

  &-logo-container {
    cursor: pointer;
    display: inline-flex;
  }
}

.header-menu {
  flex: 1 1 1;
  display: inline-flex;

  :deep(.t-menu__item) {
    min-width: unset;
  }
}

.operations-container {
  display: flex;
  align-items: center;

  .t-popup__reference {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .t-button {
    margin-left: var(--td-comp-margin-l);
  }
}

.header-operate-left {
  display: flex;
  align-items: normal;
  line-height: 0;
}

.header-logo-container {
  width: 184px;
  display: flex;
  margin-left: 24px;
  color: var(--td-text-color-primary);

  .t-logo {
    width: 100%;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.header-user-account {
  display: inline-flex;
  align-items: center;
  color: var(--td-text-color-primary);
}

:deep(.t-head-menu__inner) {
  border-bottom: 1px solid var(--td-component-stroke);

  .t-menu__logo .header-operate-left {
    margin-left: 0;
  }
}

.t-menu--light {
  .header-user-account {
    color: var(--td-text-color-primary);
  }
}

.t-menu--dark {
  .t-head-menu__inner {
    border-bottom: 1px solid var(--td-gray-color-10);
  }

  .header-user-account {
    color: rgb(255 255 255 / 55%);
  }
}

.operations-dropdown-container-item {
  width: 100%;
  display: flex;
  align-items: center;

  :deep(.t-dropdown__item-text) {
    display: flex;
    align-items: center;
  }

  .t-icon {
    font-size: var(--td-comp-size-xxxs);
    margin-right: var(--td-comp-margin-s);
  }

  :deep(.t-dropdown__item) {
    width: 100%;
    margin-bottom: 0;
  }

  &:last-child {
    :deep(.t-dropdown__item) {
      margin-bottom: 8px;
    }
  }
}
</style>

<style lang="less">
.operations-dropdown-container-item {
  .t-dropdown__item-text {
    display: flex;
    align-items: center;
  }
}
</style>
