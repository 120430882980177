import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import "nprogress/nprogress.css";
import login from "@/pages/login/index.vue";
import { isEmpty } from "lodash-es";
import NProgress from "nprogress"; // progress bar
import { useConfigStore, useUserStore } from "./store";
import dayjs from "dayjs";
import UTILS from "./utils";

// 导入pages路由
const pageModules = import.meta.glob("@/pages/**/router.ts", { eager: true });
export const pageRouterList: Array<RouteRecordRaw> =
  mapModuleRouterList(pageModules);

const home =
  pageRouterList.find(
    (s) => s.meta.ishome || s.children.some((c) => c.meta.ishome),
  ) ?? pageRouterList[0];
const homeUrl = home?.redirect ?? home?.children.find((s) => s.meta.ishome);

// 其他固定路由
const defaultRouterList: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/",
    name: "home",
    redirect: homeUrl,
  },
  {
    path: "/:w+",
    redirect: homeUrl,
  },
];

const allRoutes = [...defaultRouterList, ...pageRouterList];

//固定路由模块转换为路由
export function mapModuleRouterList(
  modules: Record<string, unknown>,
): Array<RouteRecordRaw> {
  const routerList: Array<RouteRecordRaw> = [];
  Object.keys(modules).forEach((key) => {
    // @ts-ignore
    const mod = modules[key].default || {};
    const modList = Array.isArray(mod) ? [...mod] : [mod];
    routerList.push(...modList);
  });
  return routerList;
}

export const getActive = (maxLevel = 3): string => {
  // 非组件内调用必须通过Router实例获取当前路由
  const route = router.currentRoute.value;
  if (!route.path) {
    return "";
  }

  return route.path
    .split("/")
    .filter((_item: string, index: number) => index <= maxLevel && index > 0)
    .map((item: string) => `/${item}`)
    .join("");
};

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: allRoutes,
  scrollBehavior() {
    return {
      el: "#app",
      top: 0,
      behavior: "smooth",
    };
  },
});
// 路由白名单
const whiteListRouters: string[] = [];

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const userStore = useUserStore();
  // userStore.setPageUrl(location.href);
  if (to.path.startsWith("/mobile")) {
    next();
    return;
  }
  else if(UTILS.isWeChat())
  {
    next("/mobile");
    return;

  }
  if (userStore.token) {
    try {
      if (to.path !== "/login" && isEmpty(userStore.permission)) {
        await userStore.initPermission();
      }
      if (to.meta.show && !to.meta.show()) {
        next("/");
        return;
      }
      next();
    } catch (error) {
      next({
        path: "/login",
        query: { redirect: to.query.redirect ?? encodeURIComponent(to.fullPath) },
      });
    }
  } else {
    /* white list router */
    if (whiteListRouters.indexOf(to.path) !== -1 || to.path === "/login") {
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.query.redirect ?? encodeURIComponent(to.fullPath) },
      });
    }
  }
});

router.afterEach(async (to) => {
  const configstore = useConfigStore();
  const config = await configstore.GetConfig();
  document.title = `${to.meta.title ? to.meta.title + " - " : ""}角山大米卡`;
  const userStore = useUserStore();
  userStore.configWxApi(["scanQRCode",'getLocation']);
  NProgress.done();
});

export default router;
