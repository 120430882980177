
export const INIT = { 
	/**  */
	AdData : {
		code : undefined,
		name : undefined,
		province : undefined,
		city : undefined,
		area : undefined,
		town : undefined,
		type : undefined,
	} as AdData,
	/** 客户地址 */
	Address : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		province : undefined,
		city : undefined,
		region : undefined,
		town : undefined,
		detail : undefined,
		phone : undefined,
		name : undefined,
		isEnabled : undefined,
		isDefault : undefined,
		clientId : undefined,
		client : undefined,
	} as Address,
	/**  */
	AttachClientData : {
		bipClient : undefined,
		cardNoList : undefined,
	} as AttachClientData,
	/**  */
	BipClient : {
		biPcode : undefined,
		biPname : undefined,
		bipShengFenBiaoShi : undefined,
		u8Code : undefined,
	} as BipClient,
	/**  */
	BipGoods : {
		code : undefined,
		name : undefined,
		danWei : undefined,
		isImport : undefined,
	} as BipGoods,
	/** api 返回类 */
	BipGoodsListResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as BipGoodsListResult,
	/** api 返回类 */
	BooleanResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as BooleanResult,
	/** 建卡批次 */
	CardBatch : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		batchNo : undefined,
		cardNoStart : undefined,
		cardNoEnd : undefined,
		cardTypeId : undefined,
		cardType : undefined,
		cardKind : undefined,
		qty : undefined,
		name : undefined,
		effectiveTime : undefined,
		expiredTime : undefined,
		days : undefined,
		remark : undefined,
		state : undefined,
		audit : undefined,
	} as CardBatch,
	/** 审核记录 */
	CardBatchAudit : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		tid : undefined,
		class : undefined,
		data : undefined,
	} as CardBatchAudit,
	/**  */
	CardBatchPage : {
		pageNum : undefined,
		pageSize : undefined,
		startTime : undefined,
		endTime : undefined,
		entity : undefined,
	} as CardBatchPage,
	/**  */
	CardBatchPageData : {
		pageNum : undefined,
		pageSize : undefined,
		dataList : undefined,
		dataCount : undefined,
		pageCount : undefined,
		totalCount : undefined,
	} as CardBatchPageData,
	/** api 返回类 */
	CardBatchPageDataResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as CardBatchPageDataResult,
	/** 卡券列表 */
	CardInfo : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		cardNo : undefined,
		remark : undefined,
		cardState : undefined,
		batchId : undefined,
		batch : undefined,
		leftCount : undefined,
		leftAmount : undefined,
		audit : undefined,
		clientId : undefined,
		client : undefined,
		effectiveTime : undefined,
		expiredTime : undefined,
		cardTypeId : undefined,
		cardType : undefined,
		key : undefined,
		bipClientName : undefined,
		bipClientCode : undefined,
	} as CardInfo,
	/** 审核记录 */
	CardInfoAudit : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		tid : undefined,
		class : undefined,
		data : undefined,
	} as CardInfoAudit,
	/** api 返回类 */
	CardInfoListResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as CardInfoListResult,
	/**  */
	CardInfoPage : {
		pageNum : undefined,
		pageSize : undefined,
		startTime : undefined,
		endTime : undefined,
		entity : undefined,
	} as CardInfoPage,
	/**  */
	CardInfoPageData : {
		pageNum : undefined,
		pageSize : undefined,
		dataList : undefined,
		dataCount : undefined,
		pageCount : undefined,
		totalCount : undefined,
	} as CardInfoPageData,
	/** api 返回类 */
	CardInfoPageDataResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as CardInfoPageDataResult,
	/**  */
	CardType : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		name : undefined,
		description : undefined,
		amount : undefined,
		count : undefined,
		amountOrCount : undefined,
		cardTypeGoodList : undefined,
		imageId : undefined,
		image : undefined,
	} as CardType,
	/**  */
	CardTypeGoods : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		cardTypeId : undefined,
		goodsId : undefined,
		qty : undefined,
		goods : undefined,
	} as CardTypeGoods,
	/**  */
	CardTypePage : {
		pageNum : undefined,
		pageSize : undefined,
		startTime : undefined,
		endTime : undefined,
		entity : undefined,
	} as CardTypePage,
	/**  */
	CardTypePageData : {
		pageNum : undefined,
		pageSize : undefined,
		dataList : undefined,
		dataCount : undefined,
		pageCount : undefined,
		totalCount : undefined,
	} as CardTypePageData,
	/** api 返回类 */
	CardTypePageDataResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as CardTypePageDataResult,
	/** 客户 */
	Client : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		openId : undefined,
		nickName : undefined,
		avatar : undefined,
		cardList : undefined,
		addressList : undefined,
	} as Client,
	/** 商品 */
	Goods : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		code : undefined,
		kmCode : undefined,
		name : undefined,
		stand : undefined,
		price : undefined,
		imageId : undefined,
		image : undefined,
		details : undefined,
	} as Goods,
	/**  */
	GoodsDetail : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		goodsId : undefined,
		imageId : undefined,
		image : undefined,
		sort : undefined,
	} as GoodsDetail,
	/**  */
	GoodsPage : {
		pageNum : undefined,
		pageSize : undefined,
		startTime : undefined,
		endTime : undefined,
		entity : undefined,
	} as GoodsPage,
	/**  */
	GoodsPageData : {
		pageNum : undefined,
		pageSize : undefined,
		dataList : undefined,
		dataCount : undefined,
		pageCount : undefined,
		totalCount : undefined,
	} as GoodsPageData,
	/** api 返回类 */
	GoodsPageDataResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as GoodsPageDataResult,
	/** 登录用户数据 */
	LoginData : {
		phone : undefined,
		account : undefined,
		password : undefined,
		verifyCode : undefined,
		code : undefined,
	} as LoginData,
	/** 操作日志 */
	OpLog : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		name : undefined,
		content : undefined,
	} as OpLog,
	/**  */
	OpLogPage : {
		pageNum : undefined,
		pageSize : undefined,
		startTime : undefined,
		endTime : undefined,
		entity : undefined,
	} as OpLogPage,
	/**  */
	OpLogPageData : {
		pageNum : undefined,
		pageSize : undefined,
		dataList : undefined,
		dataCount : undefined,
		pageCount : undefined,
		totalCount : undefined,
	} as OpLogPageData,
	/** api 返回类 */
	OpLogPageDataResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as OpLogPageDataResult,
	/** 订单 */
	Order : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		clientId : undefined,
		client : undefined,
		cardId : undefined,
		card : undefined,
		addressId : undefined,
		address : undefined,
		orderState : undefined,
		count : undefined,
		amount : undefined,
		expressNumber : undefined,
		expressCompany : undefined,
		delivery : undefined,
		deliveryTime : undefined,
		shipperId : undefined,
		shipper : undefined,
		detailList : undefined,
		code : undefined,
		tid : undefined,
		sid : undefined,
		traceList : undefined,
		traceTime : undefined,
	} as Order,
	/** 订单详情 */
	OrderDetail : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		orderId : undefined,
		goodsId : undefined,
		goods : undefined,
		qty : undefined,
	} as OrderDetail,
	/**  */
	OrderPage : {
		pageNum : undefined,
		pageSize : undefined,
		startTime : undefined,
		endTime : undefined,
		entity : undefined,
	} as OrderPage,
	/**  */
	OrderPageData : {
		pageNum : undefined,
		pageSize : undefined,
		dataList : undefined,
		dataCount : undefined,
		pageCount : undefined,
		totalCount : undefined,
	} as OrderPageData,
	/** api 返回类 */
	OrderPageDataResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as OrderPageDataResult,
	/**  */
	PerBase : {
		key : undefined,
		name : undefined,
	} as PerBase,
	/** api 返回类 */
	PerBaseListResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as PerBaseListResult,
	/**  */
	PerBasePerUserPermissions : {
		parent : undefined,
		childList : undefined,
	} as PerBasePerUserPermissions,
	/** api 返回类 */
	PerBasePerUserPermissionsListResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as PerBasePerUserPermissionsListResult,
	/**  */
	PerDetail : {
		key : undefined,
		enabled : undefined,
	} as PerDetail,
	/**  */
	PerInfo : {
		pKey : undefined,
		cKey : undefined,
	} as PerInfo,
	/**  */
	PerKey : {
		key : undefined,
	} as PerKey,
	/**  */
	PerKeyPerDetailPermissions : {
		parent : undefined,
		childList : undefined,
	} as PerKeyPerDetailPermissions,
	/**  */
	PerUser : {
		key : undefined,
		name : undefined,
		enabled : undefined,
	} as PerUser,
	/** 角色 */
	Role : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		name : undefined,
		isEnabled : undefined,
		permissions : undefined,
	} as Role,
	/**  */
	RolePage : {
		pageNum : undefined,
		pageSize : undefined,
		startTime : undefined,
		endTime : undefined,
		entity : undefined,
	} as RolePage,
	/**  */
	RolePageData : {
		pageNum : undefined,
		pageSize : undefined,
		dataList : undefined,
		dataCount : undefined,
		pageCount : undefined,
		totalCount : undefined,
	} as RolePageData,
	/** api 返回类 */
	RolePageDataResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as RolePageDataResult,
	/**  */
	SelectAdData : {
		province : undefined,
		city : undefined,
		area : undefined,
		town : undefined,
	} as SelectAdData,
	/** api 返回类 */
	StringBooleanDictionaryResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as StringBooleanDictionaryResult,
	/** api 返回类 */
	StringListResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as StringListResult,
	/** api 返回类 */
	StringResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as StringResult,
	/**  */
	SysConfig : {
		appid : undefined,
		agentid : undefined,
		domain : undefined,
		sysName : undefined,
	} as SysConfig,
	/** api 返回类 */
	SysConfigResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as SysConfigResult,
	/**  */
	Trace : {
		action : undefined,
		statusDesc : undefined,
		statusTime : undefined,
	} as Trace,
	/**  */
	TradeData : {
		companyName : undefined,
		outSid : undefined,
		status : undefined,
		tid : undefined,
		traceList : undefined,
	} as TradeData,
	/** 上传文件 */
	Upload : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		fileName : undefined,
		basePath : undefined,
		contentType : undefined,
		fileUrl : undefined,
	} as Upload,
	/** api 返回类 */
	UploadResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as UploadResult,
	/** 用户 */
	User : {
		id : undefined,
		ver : undefined,
		creadTime : undefined,
		creadById : undefined,
		editTime : undefined,
		editById : undefined,
		creadBy : undefined,
		editBy : undefined,
		account : undefined,
		phone : undefined,
		password : undefined,
		name : undefined,
		isAdmin : undefined,
		isEnabled : undefined,
		roles : undefined,
		fileId : undefined,
		avatar : undefined,
	} as User,
	/**  */
	UserPage : {
		pageNum : undefined,
		pageSize : undefined,
		startTime : undefined,
		endTime : undefined,
		entity : undefined,
	} as UserPage,
	/**  */
	UserPageData : {
		pageNum : undefined,
		pageSize : undefined,
		dataList : undefined,
		dataCount : undefined,
		pageCount : undefined,
		totalCount : undefined,
	} as UserPageData,
	/** api 返回类 */
	UserPageDataResult : {
		state : undefined,
		msg : undefined,
		data : undefined,
	} as UserPageDataResult,

} 
