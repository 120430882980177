<template>
  <div :class="sideNavCls">
    <t-menu
      :class="menuCls"
      :theme="theme"
      :value="active"
      :collapsed="collapsed"
      :expanded="expanded"
      :expand-mutex="menuAutoCollapsed"
      @expand="onExpanded"
    >
      <template #logo>
        <span
          v-if="showLogo"
          :class="`${prefix}-side-nav-logo-wrapper`"
          @click="goHome"
        >
          <div class="sysInfo">
            <img v-if="collapsed"src="/logo.png" class="sysLogo" />
            <img v-else src="/logo-home.png" class="sysLogo-big" />
            </div>
        </span>
      </template>
      <menu-content :nav-data="menu" />
      <template #operations>
        <div :class="toolsCls">
          <t-link
            class="refresh-link"
            theme="primary"
            hover="color"
            @click="Refresh"
          >
            <template #content v-if="!collapsed">刷新菜单</template>
            <template #prefix-icon>
              <RefreshIcon v-if="!refreshLoading" />
              <t-loading v-else size="small"></t-loading>
            </template>
          </t-link>
        </div>
        </template>
    </t-menu>
    <div
      :class="`${prefix}-side-nav-placeholder${collapsed ? '-hidden' : ''}`"
    ></div>
  </div>
</template>

<script lang="ts">
export default {
  name: "layoutsComponentsSideNav"
};
</script>
<script setup lang="ts">
import { difference, remove, union } from "lodash-es";

import { Layout, MenuValue } from "tdesign-vue-next";
import { RefreshIcon } from "tdesign-icons-vue-next";
import type { PropType } from "vue";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import AssetLogoFull from "@/assets/assets-logo-full.svg?component";
import AssetLogo from "@/assets/assets-t-logo.svg?component";
import { getActive } from "@/router";
const prefix = import.meta.env.VITE_BASE_NAMESPACE;

import MenuContent from "./MenuContent.vue";
import { useConfigStore } from "@/store";

const configStore = useConfigStore();
const { config } = storeToRefs(configStore);
const MIN_POINT = 992 - 1;

const props = defineProps({
  menu: {
    type: Array as PropType<MenuRoute[]>,
    default: () => [],
  },
  showLogo: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  isFixed: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  layout: {
    type: String as PropType<string>,
    default: "",
  },
  headerHeight: {
    type: String as PropType<string>,
    default: "64px",
  },
  theme: {
    type: String as PropType<ModeType>,
    default: "light",
  },
  isCompact: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const userstore = useUserStore();
const refreshLoading = ref(false);
const Refresh = async () => {
  if (refreshLoading.value) return;
  refreshLoading.value = true;
  await userstore.initPermission();
  refreshLoading.value = false;
};

const collapsed = computed(() => useSettingStore().isSidebarCompact);
const menuAutoCollapsed = computed(() => useSettingStore().menuAutoCollapsed);

const active = computed(() => getActive());

const expanded = ref<MenuValue[]>([]);

watch(
  () => active.value,
  () => {
    const path = getActive();
    const parentPath = path.substring(0, path.lastIndexOf("/"));
    expanded.value = menuAutoCollapsed.value
      ? [parentPath]
      : union([parentPath], expanded.value);
  },
);

const onExpanded = (value: MenuValue[]) => {
  const currentOperationMenu = difference(expanded.value, value);
  const allExpanded = union(value, expanded.value);
  remove(allExpanded, (item) => currentOperationMenu.includes(item));
  expanded.value = allExpanded;
};

const sideMode = computed(() => {
  const { theme } = props;
  return theme === "dark";
});
const sideNavCls = computed(() => {
  const { isCompact } = props;
  return [
    `${prefix}-sidebar-layout`,
    {
      [`${prefix}-sidebar-compact`]: isCompact,
    },
  ];
});
const logoCls = computed(() => {
  return [
    `${prefix}-side-nav-logo-${collapsed.value ? "t" : "tdesign"}-logo`,
    {
      [`${prefix}-side-nav-dark`]: sideMode.value,
    },
  ];
});
const toolsCls = computed(() => {
  return [
    `tools-container`,
    {
      [`${prefix}-side-nav-dark`]: sideMode.value,
    },
    {
      [`onlyico`]: collapsed.value,
    },
  ];
});
const menuCls = computed(() => {
  const { showLogo, isFixed, layout } = props;
  return [
    `${prefix}-side-nav`,
    {
      [`${prefix}-side-nav-no-logo`]: !showLogo,
      [`${prefix}-side-nav-no-fixed`]: !isFixed,
      [`${prefix}-side-nav-mix-fixed`]: layout === "mix" && isFixed,
    },
  ];
});

const router = useRouter();
const settingStore = useSettingStore();

const autoCollapsed = () => {
  const isCompact = window.innerWidth <= MIN_POINT;
  settingStore.updateConfig({
    isSidebarCompact: isCompact,
  });
};

onMounted(() => {
  const path = getActive();
  const parentPath = path.substring(0, path.lastIndexOf("/"));
  expanded.value = union([parentPath], expanded.value);
  autoCollapsed();
  window.onresize = () => {
    autoCollapsed();
  };
});

const goHome = () => {
  router.push("/dashboard/base");
};

const getLogo = () => {
  if (collapsed.value) return AssetLogo;
  return AssetLogoFull;
};
</script>

<style lang="less" scoped></style>
