<script lang="ts">
export default {
  name: "componentsTImg"
};
</script>
<script setup lang="ts">
const {
  srcs = [],
  height = 50,
  showView = false,
} = defineProps<{
  srcs?: string[];
  height?: number;
  width?: number;
  showView?: boolean;
  text?: string;
}>();
const useStore = useUserStore();

const imgList = computed(() => {
  return srcs.filter((s) => s).map((src) => useStore.getImgUrl(src));
});

const imgIndex = ref(0);
const visible = ref(false);

const showImg = (i: number) => {
  imgIndex.value = i;
  visible.value = true;
};
</script>

<template>
  <t-image-viewer
    v-model:visible="visible"
    v-if="showView"
    :images="imgList"
    :index="imgIndex"
    @IndexChange="(i) => (imgIndex = i)"
    :close-on-overlay="true"
  />
  <div style="display: flex; align-items: center">
    <img
      v-for="(src, i) in imgList"
      :src="src"
      :width="width ?? height"
      :height="height"
      style="object-fit: contain; margin-right: 5px"
      @click="showImg(i)"
    />
    <div v-if="text">{{ text }}</div>
  </div>
</template>
