/** 权限枚举 */
export enum APIPer {
 	/** 登录 */
	AccountLogin = "AccountLogin",
	/** 查看批次列表 */
	CardGetBatchList = "CardGetBatchList",
	/** 批次导出Excel */
	CardExportBatch = "CardExportBatch",
	/** 查看卡列表 */
	CardGetCardList = "CardGetCardList",
	/** 卡作废 */
	CardCardCancel = "CardCardCancel",
	/** 新增卡批次 */
	CardAddCardBatch = "CardAddCardBatch",
	/** 修改卡批次 */
	CardEditCardBatch = "CardEditCardBatch",
	/** 删除卡批次 */
	CardDeleteCardBatch = "CardDeleteCardBatch",
	/** 获取BIP客户 */
	CardGetBIPClientList = "CardGetBIPClientList",
	/** 关联客户 */
	CardAttachClient = "CardAttachClient",
	/** 激活卡 */
	CardActivateCard = "CardActivateCard",
	/** 审核卡批次 */
	CardVerifiedCardBatch = "CardVerifiedCardBatch",
	/** 卡批次制卡 */
	CardMakeCard = "CardMakeCard",
	/** 查看卡类型列表 */
	CardTypeGetList = "CardTypeGetList",
	/** 新增卡类型 */
	CardTypeAdd = "CardTypeAdd",
	/** 编辑卡类型 */
	CardTypeEdit = "CardTypeEdit",
	/** 删除卡类型 */
	CardTypeDelete = "CardTypeDelete",
	/** 查看商品列表 */
	GoodsGetList = "GoodsGetList",
	/** 查看BIP商品 */
	GoodsGetBipGoods = "GoodsGetBipGoods",
	/** 导入商品 */
	GoodsImport = "GoodsImport",
	/** 导出商品到快麦 */
	GoodsSyncGoods = "GoodsSyncGoods",
	/** 编辑商品 */
	GoodsEdit = "GoodsEdit",
	/** 查看操作日志列表 */
	OpLogGetList = "OpLogGetList",
	/** 查看订单列表 */
	OrderGetList = "OrderGetList",
	/** 发货 */
	OrderDelivery = "OrderDelivery",
	/** 同步发货状态 */
	OrderSyncDeliveryState = "OrderSyncDeliveryState",
	/** 查看角色列表 */
	RoleGetList = "RoleGetList",
	/** 查看角色权限 */
	RoleGetPermissionsList = "RoleGetPermissionsList",
	/** 角色授权 */
	RoleAuthorization = "RoleAuthorization",
	/** 角色批量授权 */
	RoleAuthorizationList = "RoleAuthorizationList",
	/** 新增角色 */
	RoleAdd = "RoleAdd",
	/** 编辑角色 */
	RoleEdit = "RoleEdit",
	/** 删除角色 */
	RoleDelete = "RoleDelete",
	/** 上传文件 */
	FileUpload = "FileUpload",
	/** 查看用户列表 */
	UserGetList = "UserGetList",
	/** 新增用户 */
	UserAdd = "UserAdd",
	/** 编辑用户 */
	UserEdit = "UserEdit",
	/** 删除用户 */
	UserDelete = "UserDelete"
};
export type PerType = keyof typeof APIPer;
export const Per = {
	/** 登录 权限 */
	get  AccountLogin(){
		return checkPer('AccountLogin');
	},
	/** 查看批次列表 权限 */
	get  CardGetBatchList(){
		return checkPer('CardGetBatchList');
	},
	/** 批次导出Excel 权限 */
	get  CardExportBatch(){
		return checkPer('CardExportBatch');
	},
	/** 查看卡列表 权限 */
	get  CardGetCardList(){
		return checkPer('CardGetCardList');
	},
	/** 卡作废 权限 */
	get  CardCardCancel(){
		return checkPer('CardCardCancel');
	},
	/** 新增卡批次 权限 */
	get  CardAddCardBatch(){
		return checkPer('CardAddCardBatch');
	},
	/** 修改卡批次 权限 */
	get  CardEditCardBatch(){
		return checkPer('CardEditCardBatch');
	},
	/** 删除卡批次 权限 */
	get  CardDeleteCardBatch(){
		return checkPer('CardDeleteCardBatch');
	},
	/** 获取BIP客户 权限 */
	get  CardGetBIPClientList(){
		return checkPer('CardGetBIPClientList');
	},
	/** 关联客户 权限 */
	get  CardAttachClient(){
		return checkPer('CardAttachClient');
	},
	/** 激活卡 权限 */
	get  CardActivateCard(){
		return checkPer('CardActivateCard');
	},
	/** 审核卡批次 权限 */
	get  CardVerifiedCardBatch(){
		return checkPer('CardVerifiedCardBatch');
	},
	/** 卡批次制卡 权限 */
	get  CardMakeCard(){
		return checkPer('CardMakeCard');
	},
	/** 查看卡类型列表 权限 */
	get  CardTypeGetList(){
		return checkPer('CardTypeGetList');
	},
	/** 新增卡类型 权限 */
	get  CardTypeAdd(){
		return checkPer('CardTypeAdd');
	},
	/** 编辑卡类型 权限 */
	get  CardTypeEdit(){
		return checkPer('CardTypeEdit');
	},
	/** 删除卡类型 权限 */
	get  CardTypeDelete(){
		return checkPer('CardTypeDelete');
	},
	/** 查看商品列表 权限 */
	get  GoodsGetList(){
		return checkPer('GoodsGetList');
	},
	/** 查看BIP商品 权限 */
	get  GoodsGetBipGoods(){
		return checkPer('GoodsGetBipGoods');
	},
	/** 导入商品 权限 */
	get  GoodsImport(){
		return checkPer('GoodsImport');
	},
	/** 导出商品到快麦 权限 */
	get  GoodsSyncGoods(){
		return checkPer('GoodsSyncGoods');
	},
	/** 编辑商品 权限 */
	get  GoodsEdit(){
		return checkPer('GoodsEdit');
	},
	/** 查看操作日志列表 权限 */
	get  OpLogGetList(){
		return checkPer('OpLogGetList');
	},
	/** 查看订单列表 权限 */
	get  OrderGetList(){
		return checkPer('OrderGetList');
	},
	/** 发货 权限 */
	get  OrderDelivery(){
		return checkPer('OrderDelivery');
	},
	/** 同步发货状态 权限 */
	get  OrderSyncDeliveryState(){
		return checkPer('OrderSyncDeliveryState');
	},
	/** 查看角色列表 权限 */
	get  RoleGetList(){
		return checkPer('RoleGetList');
	},
	/** 查看角色权限 权限 */
	get  RoleGetPermissionsList(){
		return checkPer('RoleGetPermissionsList');
	},
	/** 角色授权 权限 */
	get  RoleAuthorization(){
		return checkPer('RoleAuthorization');
	},
	/** 角色批量授权 权限 */
	get  RoleAuthorizationList(){
		return checkPer('RoleAuthorizationList');
	},
	/** 新增角色 权限 */
	get  RoleAdd(){
		return checkPer('RoleAdd');
	},
	/** 编辑角色 权限 */
	get  RoleEdit(){
		return checkPer('RoleEdit');
	},
	/** 删除角色 权限 */
	get  RoleDelete(){
		return checkPer('RoleDelete');
	},
	/** 上传文件 权限 */
	get  FileUpload(){
		return checkPer('FileUpload');
	},
	/** 查看用户列表 权限 */
	get  UserGetList(){
		return checkPer('UserGetList');
	},
	/** 新增用户 权限 */
	get  UserAdd(){
		return checkPer('UserAdd');
	},
	/** 编辑用户 权限 */
	get  UserEdit(){
		return checkPer('UserEdit');
	},
	/** 删除用户 权限 */
	get  UserDelete(){
		return checkPer('UserDelete');
	}
}