import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "256",
  height: "256",
  class: "icon",
  viewBox: "0 0 1024 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#1296db",
      d: "M944.764 915.856c0 14.815-2.132 28.663-6.37 41.516-4.236 12.853-10.236 24.17-18.084 33.923-7.82 9.725-16.948 17.346-27.384 22.777a72.65 72.65 0 0 1-34.208 8.19H148.935c-12.398 0-24.113-2.73-35.203-8.19a93.2 93.2 0 0 1-29.317-22.777 111.4 111.4 0 0 1-20.047-33.923 115.8 115.8 0 0 1-7.337-41.516V332.469c0-29.63 8.645-54.767 25.905-75.411 17.317-20.673 38.303-30.995 63.099-30.995h709.754c24.768 0 45.782 10.322 63.07 30.995 17.26 20.644 25.877 45.781 25.877 75.411v158.984H722.824c-24.768 0-45.782 10.152-63.07 30.426-17.26 20.247-25.877 45.213-25.877 74.786.626 20.275 4.209 38.218 10.749 53.8 5.204 13.252 13.848 25.337 25.876 36.256 12.085 10.89 29.516 16.35 52.322 16.35h221.94zm-119.97-746.664H368.942A6182 6182 0 0 0 471.367 107.6a9346 9346 0 0 0 83.317-50.16c27.469-16.72 48.88-29.659 64.264-38.787 23.431-14.445 44.36-21.099 62.758-19.961 18.426 1.137 33.98 4.777 46.691 10.834 14.73 8.36 27.44 19.393 38.161 33.07zm-143.97 425.91c0-15.84 4.635-29.176 13.877-40.01 9.242-10.805 20.616-16.237 34.123-16.237s24.881 5.403 34.123 16.237 13.876 24.17 13.876 40.01-4.635 29.373-13.876 40.634c-9.242 11.232-20.616 16.862-34.123 16.862s-24.881-5.63-34.123-16.862c-9.242-11.26-13.877-24.796-13.877-40.635"
    }, null, -1)
  ])))
}
export default { render: render }