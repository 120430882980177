import Layout from "@/layouts/index.vue";
import { AssignmentIcon } from "tdesign-icons-vue-next";
import { RouteRecordRaw } from "vue-router";
import orderList from "./orderList.vue";
// const dd:ResultState=ResultState.BadRequest;

const router: RouteRecordRaw = {
  path: "/order",
  component: Layout,
  redirect: "/order/list",
  name: "order",
  meta: {
    title: "订单管理",
    icon: shallowRef(AssignmentIcon),
    orderNo: 0,
    // single:true
  },
  children: [
    {
      path: "list",
      name: orderList.name,
      component: orderList,
      meta: {
        title: "订单列表",
        show: ()=>Per.OrderGetList

      },
    },
  ],
};

export default router;
