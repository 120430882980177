<script lang="tsx">
export default {
  name: "pagesCardTypeList"
};
</script>
<script setup lang="tsx">
import { DialogPlugin, MessagePlugin, PageInfo } from "tdesign-vue-next";
import { useSettingStore } from "@/store";
import AddEdit from "./typeEditer.vue";
import UTILS from "@/utils";
import { AmountOrCount, BatchState, CardKind } from "@/apis/enum";
import TImg from "@/components/TImg.vue";

const submit = async (data: CardType) => {
  const r =
    data.id > 0 ? await API.CardType.Edit(data) : await API.CardType.Add(data);
  if (r) {
    addEditVisible.value = false;
    MessagePlugin.success("提交成功");
    await fetchData();
  }
};

const COLUMNS: columns<CardType>[] = [
  { colKey: "row-select", type: "multiple", width: 50, fixed: "left" },
  {
    title: "类型ID",
    colKey: "id",
    fixed: "left",
    width: 80,
  },
  {
    title: "类型名称",
    colKey: "name",
    width: 150,
  },
  {
    title: "图片",
    colKey: "image",
    className: "td-img",
    cell(_, { row }) {
      return <TImg showView height={34} srcs={[row.image?.fileUrl]} />;
    },
    width: 100,
  },
  {
    title: "消费方式",
    colKey: "amountOrCount",
    width: 100,
    cell(_, { row }) {
      return UTILS.getEnumDesc(AmountOrCount, row.type);
    },
  },
  {
    title: "卡金额",
    colKey: "amount",
    width: 100,
  },
  {
    title: "卡次数",
    colKey: "count",
    width: 100,
  },
  {
    title: "关联商品",
    colKey: "cardTypeGoodList",
    cell(_, { row }) {
      if (row.type == AmountOrCount.CountCard)
        return row.cardTypeGoodList
          ?.map((s) => s.goods?.name + s.goods?.stand + "x" + s.qty)
          .join("; ");
      else
        return row.cardTypeGoodList
          ?.map((s) => s.goods?.name + s.goods?.stand)
          .join("; ");
    },
    width: 300,
    ellipsis: true,
  },
  {
    title: "说明",
    colKey: "description",
  },
  {
    title: "创建时间",
    width: 180,
    colKey: "creadTime",
  },
  {
    title: "创建人",
    colKey: "creadBy",
    cell(_, { row }) {
      return row.creadBy?.name;
    },
    width: 80,
  },
  {
    title: "修改时间",
    width: 180,
    colKey: "editTime",
  },
  {
    title: "修改人",
    colKey: "editBy",
    cell(_, { row }) {
      return row.editBy?.name;
    },
    width: 80,
  },
];

const store = useSettingStore();

const tableHeight = ref(0);
watchEffect(() => {
  const height = store.getContentHeight();
  nextTick(() => {
    const headerEl = document.querySelector(".contentheader");
    const pageEl = document.querySelector(".t-table__pagination");
    tableHeight.value =
      height -
      UTILS.getOuterHeight(headerEl) -
      UTILS.getOuterHeight(pageEl) -
      40;
  });
});

const data = ref([]);
const pagination = ref({
  defaultPageSize: 20,
  total: 100,
  defaultCurrent: 1,
});

const dataLoading = ref(false);
const pageData = ref<CardTypePage>({
  pageNum: 1,
  pageSize: 20,
  entity: { ...INIT.CardInfo },
});
const fetchData = async () => {
  dataLoading.value = true;
  try {
    const PageData = await API.CardType.GetList(pageData.value);
    data.value = PageData?.dataList;
    pagination.value = {
      ...pagination.value,
      total: PageData?.totalCount,
    };
  } catch (e) {
    console.log(e);
  } finally {
    dataLoading.value = false;
  }
};

const search = () => {
  pageData.value.pageNum = 1;
  fetchData();
};
const reset = () => {
  pageData.value = {
    pageNum: 1,
    pageSize: 20,
    entity: { ...INIT.CardInfo },
  };
  fetchData();
};
onMounted(() => {
  fetchData();
});

const selectedRowKeys = ref<number[]>([]);

const rowKey = "id";

const rehandleSelectChange = (val: (string | number)[]) => {
  selectedRowKeys.value = val as number[];
};
const pageChange = (pageInfo: PageInfo) => {
  console.log("分页变化", pageInfo);
  pageData.value.pageNum = pageInfo.current;
  pageData.value.pageSize = pageInfo.pageSize;
  fetchData();
};
const rehandleChange = (changeParams: unknown, triggerAndData: unknown) => {
  console.log("统一Change", changeParams, triggerAndData);
};

const addEditVisible = ref(false);
const editData = ref<CardType>();
const addOrEditData = (id: number = 0) => {
  if (id == 0) editData.value = INIT.CardType;
  else editData.value = data.value.find((z) => z.id == id);
  addEditVisible.value = true;
};
const close = () => {
  addEditVisible.value = false;
};

const delData = (ids: number[]) => {
  const dialog = DialogPlugin.confirm({
    theme: "warning",
    header: "确认删除所选类型？",
    onConfirm: async () => {
      const r = await API.CardType.Delete(ids);
      if (r) {
        MessagePlugin.success("删除成功");
        dialog.destroy();
        fetchData();
      }
    },
  });
};
</script>
<template>
  <div>
    <t-card
      class="list-card-container"
      :bordered="false"
      style="padding-top: 8px"
    >
      <div
        class="contentheader"
        style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        "
      >
        <div style="display: flex; align-items: center">
          <t-button @click="addOrEditData()" v-if="Per.CardTypeAdd">
            新建</t-button
          >
          <t-button
            @click="addOrEditData(selectedRowKeys[0])"
            theme="success"
            v-if="Per.CardTypeEdit"
            :disabled="selectedRowKeys.length != 1"
          >
            编辑</t-button
          >
          <t-button
            @click="delData(selectedRowKeys)"
            theme="danger"
            v-if="Per.CardTypeDelete"
            :disabled="selectedRowKeys.length == 0"
          >
            删除</t-button
          >
          <p
            v-if="!!selectedRowKeys.length"
            style="margin-left: 10px; color: var(--td-text-color-secondary)"
          >
            已选{{ selectedRowKeys.length }}项
          </p>
        </div>
        <div style="display: flex">
          <t-input
            v-model="pageData.entity.name"
            placeholder="请输入类型名称"
          />
          <t-button theme="primary" @click="search" style="margin-left: 10px">
            查询
          </t-button>
          <t-button variant="base" @click="reset" theme="default">
            重置
          </t-button>
        </div>
      </div>
      <t-table
        :data="data"
        :columns="COLUMNS"
        :row-key="rowKey"
        vertical-align="top"
        :hover="true"
        :bordered="true"
        :pagination="pagination"
        :selected-row-keys="selectedRowKeys"
        :loading="dataLoading"
        :selectOnRowClick="false"
        @page-change="pageChange"
        @change="rehandleChange"
        :max-height="tableHeight"
        @select-change="rehandleSelectChange"
      >
      </t-table>
    </t-card>
    <AddEdit
      v-model:visible="addEditVisible"
      :data="editData"
      @submit="submit"
      @close="close"
    />
  </div>
</template>
