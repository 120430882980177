<template>
  <div :class="prefix + '-footer'">
    {{ footText }}
  </div>
</template>

<script lang="ts">
export default {
  name: "layoutsComponentsFooter"
};
</script>
<script setup lang="ts">
const prefix = import.meta.env.VITE_BASE_NAMESPACE;

import { footText } from "@/config/global";
</script>

<style lang="less" scoped>
.@{starter-prefix}-footer {
  color: var(--td-text-color-placeholder);
  line-height: var(--td-line-height-link-medium);
  text-align: center;
  margin-top: calc(var(--td-comp-paddingTB-m) * -1);
  width: 100%;
}
</style>
