import Layout from "@/layouts/index.vue";
import { FileCode1Icon } from "tdesign-icons-vue-next";
import { RouteRecordRaw } from "vue-router";
import logList from "./logList.vue";
// const dd:ResultState=ResultState.BadRequest;

const router: RouteRecordRaw = {
  path: "/log",
  component: Layout,
  redirect: "/log/list",
  name: "log",
  meta: {
    title: "操作日志",
    icon: shallowRef(FileCode1Icon),
    orderNo: 4,
    // single:true
  },
  children: [
    {
      path: "list",
      name: logList.name,
      component: logList,
      meta: {
        title: "日志列表",
        show: ()=>Per.OpLogGetList

      },
    },
  ],
};

export default router;
