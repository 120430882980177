import type {
  ProjectListResult,
  PurchaseListResult,
} from "@/api/model/detailModel";
import { request } from "@/utils/request";

const Api = {
  PurchaseList:
    "https://service-bv448zsw-1257786608.gz.apigw.tencentcs.com/api/get-purchase-list",
  ProjectList:
    "https://service-bv448zsw-1257786608.gz.apigw.tencentcs.com/api/get-project-list",
};

export function getPurchaseList() {
  return request.get<PurchaseListResult>({
    url: Api.PurchaseList,
  });
}

export function getProjectList() {
  return request.get<ProjectListResult>({
    url: Api.ProjectList,
  });
}
