<script lang="ts">
export default {
  name: "pagesMobileIndex"
};
</script>
<script setup lang="ts">
import kabaoIcon from "@/assets/kabao.svg?component";
import digndanIcon from "@/assets/dingdan.svg?component";
import dizhiIcon from "@/assets/dizhi.svg?component";
import { isEmpty } from "lodash-es";

const to = (path: string) => {
  router.push(path);
};
const router = useRouter();
const route = useRoute();
const loading = ref(true);

const userstore = useUserStore();
onMounted(async () => {
  if (await userstore.checkToken()) {
    loading.value = false;
  } else if (!isEmpty(route.query.openid)) {
    await userstore.mobileLogin(
      route.query.openid.toString(),
      route.query.wximage?.toString(),
      route.query.uname?.toString(),
    );
    loading.value = false;
  } else {
    const host = location.href.replace("://", "@").replace("/", "@");
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6142634b1d4a81c5&redirect_uri=http://redirect.jsalc.com/userinfo?burl=${host}&response_type=code&scope=snsapi_userinfo&state=state&connect_redirect=1`;
    return;
  }

  const redirect = Array.isArray(route.query.redirect) ? route.query.redirect[0] : route.query.redirect;
  if (!isEmpty(redirect) && !isEmpty(userstore.token))
    router.push(redirect);
});
</script>

<template>
  <div>
    <t-loading v-if="loading" fullscreen />
    <t-swiper class="tdesign-demo-block--swiper">
      <t-swiper-item>
        <img class="banner" src="/banner/01.jpg" />
      </t-swiper-item>
      <t-swiper-item>
        <img class="banner" src="/banner/02.jpg" />
      </t-swiper-item>
      <t-swiper-item>
        <img class="banner" src="/banner/03.jpg" />
      </t-swiper-item>
    </t-swiper>
    <t-row class="menu">
      <t-col :span="4" class="col">
        <t-space @click="to('/mobile/card')" direction="vertical" :size="0">
          <kabaoIcon class="ico" /> 角山米卡
        </t-space></t-col>
      <t-col :span="4" class="col">
        <t-space @click="to('/mobile/order')" direction="vertical" :size="0">
          <digndanIcon class="ico" /> 发货订单
        </t-space></t-col>
      <t-col :span="4" class="col">
        <t-space @click="to('/mobile/address')" direction="vertical" :size="0">
          <dizhiIcon class="ico" /> 地址管理
        </t-space></t-col>
    </t-row>
    </div>
</template>
<style lang="less" scoped>
.banner {
  width: 100%;
}

.menu {
  padding: 50px 20px;
  color: #000;
}

.col {
  text-align: center;

  & .ico {
    width: 50px;
    height: 50px;
  }
}
</style>
