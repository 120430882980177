<template>
  <l-header
    :show-logo="settingStore.showHeaderLogo"
    :theme="settingStore.displayMode"
    :layout="settingStore.layout"
    :is-fixed="settingStore.isHeaderFixed"
    :menu="headerMenu"
    :is-compact="settingStore.isSidebarCompact"
  />
</template>

<script lang="ts">
export default {
  name: "layoutsComponentsLayoutHeader"
};
</script>
<script setup lang="ts">
import { computed, ref } from "vue";

import { useSettingStore, useUserStore } from "@/store";

import LHeader from "./Header.vue";

const settingStore = useSettingStore();
const userStore = useUserStore();
const { menuList: menuRouters } = storeToRefs(userStore);
const headerMenu = computed(() => {
  let newMenuRouters = menuRouters.value as Array<MenuRoute>;
  if (settingStore.layout === "mix") {
    if (settingStore.splitMenu) {
      return newMenuRouters.map((menu) => ({
        ...menu,
        children: [],
      }));
    }
    return [];
  }
  return newMenuRouters;
});
</script>
