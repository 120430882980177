<template>
  <t-dialog
    :visible="visible"
    :width="560"
    :close-btn="true"
    :closeOnOverlayClick="false"
    :closeOnEscKeydown="false"
    confirm-btn="提交"
    cancel-btn="取消"
    @confirm="onSubmit"
    @close="onClose"
  >
    <template #header>{{ formData.id > 0 ? "修改用户" : "新增用户" }}</template>
    <template #body>
      <t-form
        ref="form"
        class="base-form"
        :data="formData"
        :rules="FORM_RULES"
        label-align="top"
        @submit="onSubmit"
      >
        <t-row class="row-gap" :gutter="[30, 10]" style="margin: 0">
          <t-col :span="6">
            <t-form-item label="用户头像" name="avatar">
              <ImgUpload
                :ukey="imgUploadKey"
                :fileList="[formData.avatar]"
                @change="(file) => (formData.fileId = file.id)"
                :max-size="200"
              />
              </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-row :gutter="[30, 10]">
              <t-col :span="12">
                <t-form-item label="用户名" name="account">
                  <t-input
                    v-model="formData.account"
                    placeholder="用户名"
                    :disabled="formData.id > 0"
                  />
                </t-form-item>
              </t-col>
              <t-col :span="12">
                <t-form-item label="密码" name="password">
                  <t-input
                    v-model="formData.password"
                    type="password"
                    placeholder="密码"
                  />
                </t-form-item>
              </t-col>
            </t-row>
          </t-col>

          <t-col :span="6">
            <t-form-item label="姓名" name="name">
              <t-input v-model="formData.name" placeholder="姓名" />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="手机号" name="phone">
              <t-input v-model="formData.phone" placeholder="手机号" />
            </t-form-item>
          </t-col>
          <t-col :span="12">
            <t-form-item label="角色" name="phone">
              <t-select
                v-model="roleids"
                multiple
                placeholder="- 请选择角色 -"
                :options="options"
                filterable
              />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="管理员" name="isAdmin">
              <t-radio-group v-model="formData.isAdmin">
                <t-radio :value="true"> 是 </t-radio>
                <t-radio :value="false"> 否 </t-radio>
              </t-radio-group>
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="启用状态" name="isEnabled">
              <t-radio-group v-model="formData.isEnabled">
                <t-radio :value="true"> 启用 </t-radio>
                <t-radio :value="false"> 禁用 </t-radio>
              </t-radio-group>
            </t-form-item>
          </t-col>
        </t-row>
      </t-form>
    </template>
  </t-dialog>
</template>

<script lang="ts">
export default {
  name: "pagesRoleAddEditUser"
};
</script>
<script setup lang="ts">
import dayjs from "dayjs";
import {
  FormInstanceFunctions,
  FormRule,
  SelectOption,
  SelectProps,
  SelectValue,
} from "tdesign-vue-next";

const FORM_RULES: Record<string, FormRule[]> = {
  name: [{ required: true, message: "请输入用户姓名", type: "error" }],
  isEnabled: [{ required: true, message: "请选择启用状态", type: "error" }],
  isAdmin: [{ required: true, message: "请管理员状态", type: "error" }],
  account: [{ required: true, message: "请输入用户名", type: "error" }],
  phone: [
    {
      required: false,
      telnumber: true,
      trigger: "blur",
      message: "请输入正确手机号",
      type: "error",
    },
  ],
};

interface Props {
  visible: boolean;
  data?: User;
}
const form = ref<FormInstanceFunctions>(null);

const roleids = ref<number[]>([]);
const props = defineProps<Props>();
const formData = ref<User>({ name: "", isEnabled: true, isAdmin: false });
const options = ref<SelectOption[]>();

const getRoleData = async () => {
  if (!Per.UserGetList) {
    options.value = props.data?.roles?.map((role) => ({
      label: role.name,
      value: role.id,
    }));
  } else {
    const r = await API.Role.GetList({ pageNum: 0 });
    options.value = r?.dataList?.map((s) => ({ label: s.name, value: s.id }));
  }
};
const imgUploadKey = ref(0);
watch(props, () => {
  if (props.visible) {
    imgUploadKey.value = dayjs().unix();
    form.value.reset();
    formData.value = props.data
      ? { ...props.data }
      : { name: "", isEnabled: true, isAdmin: false };
    formData.value.password = undefined;
    roleids.value = formData.value?.roles?.map((s) => s.id) ?? [];
    getRoleData();
  }
});

const emit = defineEmits(["submit", "close"]);
const onSubmit = () => {
  formData.value.roles = roleids.value.map((s) => ({ id: s }));

  form.value.validate().then((r) => {
    if (r === true) emit("submit", formData.value);
  });
};

const onClose = () => {
  emit("close");
};
</script>
