<template>
  <router-view :class="[mode]" />
</template>
<script lang="ts">
export default {
  name: "app"
};
</script>
<script setup lang="ts">
import { computed } from "vue";
import { useSettingStore, useUserStore } from "@/store";
import dayjs from "dayjs";

const store = useSettingStore();
const mode = computed(() => {
  return store.displayMode;
});
const userstore = useUserStore();
let timer: string | number | NodeJS.Timeout = null;
let uptoken = false;
document.onclick = async () => {
  if (!uptoken) {
    uptoken = true;
    await userstore.checkToken();
    uptoken = false;
  }
  if (userstore.userInfo.typ == "pc") {
    clearTimeout(timer);
    timer = setTimeout(
      () => {
        userstore.logout();
      },
      1000 * 60 * 10,
    );
  }
};
nextTick(() => {
  document.body.click();
});
</script>
<style lang="less" scoped>
#nprogress .bar {
  background: var(--td-brand-color) !important;
}
</style>
