import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "256",
  height: "256",
  class: "icon",
  viewBox: "0 0 1024 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#1296db",
      d: "M231.474 32h561.052Q992 32 992 231.474v561.052Q992 992 792.526 992H231.474Q32 992 32 792.526V231.474Q32 32 231.474 32"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M238.263 763.451c0 12.549 10.286 22.835 22.834 22.835h501.806c12.548 0 22.834-10.286 22.834-22.835V374.857H238.263zM557.05 557.737H694.47v45.669H557.05zm-45.6 91.406h183.086v45.737H511.451zM500 352.023H237.714l54.72-96.617a44.3 44.3 0 0 1 35.932-17.692H500zm286.286 0H524V237.714h171.634c14.263 0 27.36 6.857 35.932 17.692z"
    }, null, -1)
  ])))
}
export default { render: render }