import Layout from "@/layouts/index.vue";
import { Shop1Icon } from "tdesign-icons-vue-next";
import { RouteRecordRaw } from "vue-router";

import gooodsList from "./gooodsList.vue";

// const dd:ResultState=ResultState.BadRequest;

const router: RouteRecordRaw = {
  path: "/goods",
  component: Layout,
  redirect: "/goods/list",
  name: "goods",
  meta: {
    title: "商品管理",
    icon: shallowRef(Shop1Icon),
    orderNo: 0,
    // single:true
  },
  children: [
    {
      path: "list",
      name: gooodsList.name,
      component: gooodsList,
      meta: {
        title: "商品列表",
        show: ()=>Per.GoodsGetList
      },
    },
  ],
};

export default router;
