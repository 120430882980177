<template>
  <common-table />
</template>
<script lang="ts">
export default {
  name: "pagesListFilterIndex"
};
</script>
<script setup lang="ts">
import CommonTable from "@/components/CommonTable/index.vue";
</script>
