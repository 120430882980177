<template>
  <result title="404 Not Found" tip="抱歉，您访问的页面不存在" type="404">
    <t-button @click="() => router.back()">返回</t-button>
  </result>
</template>

<script lang="ts">
export default {
  name: "pagesResult404Index"
};
</script>

<script setup lang="ts">
import Result from "@/components/result/index.vue";
const router = useRouter();
</script>
