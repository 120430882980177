import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "64",
  height: "64",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentcolor",
      "fill-opacity": ".26",
      "fill-rule": "evenodd",
      d: "m32 10.69 26.248 15.155v22.31L32 63.308 5.75 48.155v-22.31zM9.75 30.464v15.381L32 58.69l22.248-12.845V30.464L32 43.309zM52.248 27 40.66 33.69l-8.66-5-8.66 5L11.75 27 32 15.31zM27.34 36 32 38.69 36.66 36 32 33.31zM30 8V0h4v8zM44.268 10.751l4-6.928 3.464 2-4 6.928zM16.268 12.751l-4-6.928 3.464-2 4 6.928z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }