<script lang="ts">
export default {
  name: "pagesMobileLayout"
};
</script>
<script setup lang="ts">

const router = useRouter();
const userstore = useUserStore();

const logout = () => {
  console.log("登出");
  userstore.logout();
};
const route = useRoute();

</script>

<template>
  <div class="page">
    <t-head-menu class="head">
      <template #logo>
        <img src="/logo-home.png" @click="router.push('/mobile')" />
      </template>
      <template #operations>
        <t-link v-if="route.path != '/mobile/index'" style="margin-right: 20px" @click="router.push('/mobile')">
          首页
        </t-link>
        <div style="margin-right: 5px">{{ userstore.userInfo.name }}</div>
        <t-avatar @dblclick="logout" style="margin-right: 10px" :image="userstore.userInfo.avatar" size="small" />
      </template>
    </t-head-menu>
    <router-view v-slot="{ Component, route }">
      <component :is="Component" :key="route.query.r" class="content" />
    </router-view>

    </div>
</template>
<style lang="less" scoped>
.page {
  height: 100vh;
}

.content {
  height: calc(100vh - 52px);
}

.banner {
  width: 100%;
}

.head {
  border-bottom: 1px solid #ddd;
  & img {
    margin-left: 10px;
    margin-top: 5px;
    height: 40px;
  }

  &>* {
    height: 50px;
  }
}
</style>
