<template>
  <t-dialog
    :visible="visible"
    :width="380"
    :close-btn="true"
    :closeOnOverlayClick="false"
    :closeOnEscKeydown="false"
    confirm-btn="提交"
    cancel-btn="取消"
    @confirm="onSubmit"
    @close="onClose"
  >
    <template #header>{{
      formData?.id > 0 ? "修改角色" : "新增角色"
    }}</template>
    <template #body>
      <t-form
        ref="form"
        class="base-form"
        :data="formData"
        :rules="FORM_RULES"
        label-align="top"
        @submit="onSubmit"
      >
        <t-row class="row-gap" :gutter="[0, 10]">
          <t-col :span="12">
            <t-form-item label="角色名称" name="name">
              <t-input
                v-model="formData.name"
                :style="{ width: '322px' }"
                placeholder="请输入内容"
              />
            </t-form-item>
          </t-col>
          <t-col :span="12">
            <t-form-item label="启用状态" name="isEnabled">
              <t-radio-group v-model="formData.isEnabled">
                <t-radio :value="true"> 启用 </t-radio>
                <t-radio :value="false"> 禁用 </t-radio>
              </t-radio-group>
            </t-form-item>
          </t-col>
        </t-row>
      </t-form>
    </template>
  </t-dialog>
</template>

<script lang="ts">
export default {
  name: "pagesRoleAddEditRole"
};
</script>
<script setup lang="ts">
import { FormInstanceFunctions, FormRule } from "tdesign-vue-next";

const FORM_RULES: Record<string, FormRule[]> = {
  name: [{ required: true, message: "请输入角色名称", type: "error" }],
  isEnabled: [{ required: true, message: "请选择启用状态", type: "error" }],
};

interface Props {
  visible: boolean;
  data?: Role;
}
const form = ref<FormInstanceFunctions>(null);
const props = defineProps<Props>();
const formData = ref<Role>(INIT.Role);

watch(props, () => {
  if (props.visible) {
    form.value.reset();
    formData.value = props.data ? { ...props.data } : { ...INIT.Role };
  }
});

const emit = defineEmits(["submit", "close"]);
const onSubmit = () => {
  form.value.validate().then((r) => {
    if (r === true) emit("submit", formData.value);
  });
};

const onClose = () => {
  emit("close");
};
</script>
