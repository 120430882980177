/* eslint-disable simple-import-sort/imports */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import "tdesign-vue-next/es/style/index.css";
import "@/style/index.less";


createApp(App).use(store).use(router).mount("#app");
