import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "256",
  height: "256",
  class: "icon",
  viewBox: "0 0 1024 1024"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#1296db",
      d: "M486.574 450.876c0 43.713 35.7 79.152 79.735 79.152 44.036 0 79.736-35.438 79.736-79.152 0-43.715-35.7-79.154-79.736-79.154s-79.735 35.44-79.735 79.154m0 0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#1296db",
      d: "M247.483 50.266c-58.875 0-106.425 47.227-106.425 105.485v79.21H72.859c-22.272 0-40.426 18.03-40.426 40.272 0 22.396 18.098 40.271 40.426 40.271h68.199v77.762H72.859c-22.272 0-40.426 18.03-40.426 40.272 0 22.399 18.098 40.27 40.426 40.27h68.199v77.769H72.859c-22.272 0-40.426 18.03-40.426 40.27 0 22.396 18.098 40.272 40.426 40.272h68.199v236.13c0 58.417 47.647 105.485 106.425 105.485h637.656c58.876 0 106.426-47.228 106.426-105.486V155.751c0-58.42-47.651-105.485-106.426-105.485zm318.826 690.843c-33.029 0-159.47-164.497-159.47-290.233 0-125.737 82.471-167.985 159.47-167.985 77 0 159.47 39.737 159.47 167.985S599.338 741.109 566.31 741.109m0 0"
    }, null, -1)
  ])))
}
export default { render: render }