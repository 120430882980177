namespace UTILS {
  export const jwtdecode = (token: string) => {
    const parts = token.split(".");
    let str = parts[1];
    str = decodeURIComponent(
      atob(str.replace(/-/g, "+"))
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(str);
  };

  export const getOuterHeight = (dom: Element) => {
    if (!dom) return 0;
    let height = dom.clientHeight;
    const computedStyle = window.getComputedStyle(dom);
    height += parseInt(computedStyle.marginTop, 10);
    height += parseInt(computedStyle.marginBottom, 10);
    height += parseInt(computedStyle.borderTopWidth, 10);
    height += parseInt(computedStyle.borderBottomWidth, 10);
    return height;
  };


  export const getAllParents = (el: Element) => {
    let parents = [];
    while (el !== null) {
      parents.push(el);
      el = el.parentElement;
    }
    return parents;
  }

  export const enumToOption = (data: Record<string, string>) => {
    const rdata: Record<string, string>[] = [];
    Object.keys(data).forEach((key) => {
      if (key.endsWith("Desc") && data[key]) {
        rdata.push({ label: data[key], value: key.replace("Desc", "") });
      }
    });
    return rdata;
  };

  export const getEnumDesc = (data: Record<string, string>, key: string) => {
    return data[key + "Desc"];
  };
  export const isIos = () => {
    return /(iPhone|Mac OS)/i.test(navigator.userAgent);
  }
  export const isWeChat = () => {
    return /MicroMessenger/i.test(navigator.userAgent) && !isQYWeChat();
  };

  export const isQYWeChat = () => {
    return /wxwork/i.test(navigator.userAgent);
  };

  export const isMobile = () => {
    return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      navigator.userAgent,
    );
  };
}

export default UTILS;
