<script lang="tsx">
export default {
  name: "pagesCardCardList"
};
</script>
<script setup lang="tsx">
import { DialogPlugin, MessagePlugin, PageInfo, Link, Form, FormItem, Textarea, Select } from "tdesign-vue-next";
import { useSettingStore } from "@/store";
import UTILS from "@/utils";
import { AmountOrCount, BatchState, CardKind, CardState } from "@/apis/enum";
import TImg from "@/components/TImg.vue";
import QrcodeVue, {
  GradientType,
  ImageSettings,
  Level,
  RenderAs,
} from "qrcode.vue";
import dayjs from "dayjs";
const submit = async (data: CardInfo) => {
};

const configstore = useConfigStore();
const { config } = storeToRefs(configstore);

const imageSettings = ref<ImageSettings>({
  src: "/logo.png",
  width: 30,
  height: 30,
  excavate: true,
});
const value = ref("qrcode");
const level = ref<Level>("M");
const renderAs = ref<RenderAs>("canvas");
const background = ref("#ffffff");
const foreground = ref("#000000");
const margin = ref(0);
const gradient = ref(true);
const gradientStartColor = ref("#fcc900");
const gradientEndColor = ref("#38bdf8");
const copy = async (row: CardInfo) => {
  try {
    const canvas = document.querySelector(
      ".qrcode_" + row.id,
    ) as HTMLCanvasElement;
    const newCanvas = document.createElement("canvas");
    let image = new Image();
    image.src = "/card.png";
    image.onload = () => {
      newCanvas.height = image.height;
      newCanvas.width = image.width;
      var ctx = newCanvas.getContext("2d");
      ctx.drawImage(image, 0, 0);
      ctx.drawImage(canvas, newCanvas.width * 0.7, newCanvas.height * 0.37);
      ctx.font = "italic 60px 宋体";
      ctx.fillText(
        "No：" + row.cardNo,
        newCanvas.width * 0.45,
        newCanvas.height * 0.6,
      );
      newCanvas.toBlob(async (blob) => {
        const img = new ClipboardItem({ [blob.type]: blob });
        navigator.clipboard.write([img]);
        MessagePlugin.success("复制成功，请到微信粘贴");
      });
    };
  } catch (e) {
    console.error(e);
  }
};
const COLUMNS: columns<CardInfo>[] = [
  { colKey: "row-select", type: "multiple", width: 60, fixed: "left" },
  {
    title: "卡券ID",
    colKey: "id",
    fixed: "left",
    width: 80,
  },

  {
    title: "卡券编号",
    colKey: "cardNo",
    fixed: "left",
    width: 120,
  },
  {
    title: "批次编号",
    colKey: "cardNo",
    width: 100,
    cell(_, { row }) {
      return row.batch?.batchNo;
    },
  },
  {
    title: "卡券名称",
    colKey: "batch",
    width: 100,
    cell(_, { row }) {
      return row.batch?.name;
    },
    ellipsis: true,
  },
  {
    title: "卡类型",
    colKey: "batch",
    cell(_, { row }) {
      return row.cardType?.name;
    },
    ellipsis: true,

    width: 120,
  },
  {
    title: "卡形态",
    colKey: "batch",
    cell(_, { row }) {

      return (
        <t-tag
          theme={row.batch?.cardKind == CardKind.ECard ? "primary" : "success"}
          variant="outline"
        >
          {UTILS.getEnumDesc(CardKind, row.batch?.cardKind)}
        </t-tag>
      );
    },
    width: 90,
  },
  {
    title: "批次状态",
    colKey: "batch",
    cell(_, { row }) {
      const desc = UTILS.getEnumDesc(BatchState, row.batch?.state);
      if (desc)
        return (
          <t-tag
            theme={
              row.batch?.state == BatchState.NotReviewed
                ? "warning"
                : row.batch?.state == BatchState.Reviewed
                  ? "primary"
                  : row.batch?.state == BatchState.CardReady
                    ? "success"
                    : ""
            }
            variant="outline"
          >
            {desc}
          </t-tag>
        );
    },
    width: 90,
  },
  {
    title: "卡状态",
    colKey: "cardState",
    cell(_, { row }) {
      const desc = UTILS.getEnumDesc(CardState, row.cardState);
      if (desc) {
        let color = "";
        if (row.cardState == CardState.Activated) color = "#efa306";
        else if (row.cardState == CardState.Received) color = "#1ebd6b";
        else if (row.cardState == CardState.InUse) color = "#18b1e7";
        else if (row.cardState == CardState.Expired) color = "#ff0505";
        else if (row.cardState == CardState.Deprecated) color = "#ff6405";
        return (
          <t-tag color={color} variant="outline">
            {desc}
          </t-tag>
        );
      }
    },
    width: 90,
  },
  {
    title: "余量",
    colKey: "cardState",
    cell(_, { row }) {
      return (
        <div style="font-size:12px">
          {row.cardType.amountOrCount == AmountOrCount.AmountCard
            ? row.leftAmount + "元"
            : row.leftCount + "次"}
        </div>
      );
    },
    width: 70,
  },
  {
    title: "分享卡",
    colKey: "client",
    width: 80,
    cell(_, { row }) {
      return (
        <div style="text-align:center">
          <div style="display:none">{config.value?.domain + "/mobile/card?key=" + row.key + "-" + row.cardNo}</div>
          <QrcodeVue
            style="display:none"
            class={"qrcode_" + row.id}
            value={config.value?.domain + "/mobile/card?key=" + row.key + "-" + row.cardNo}
            size={220}
            margin={2}
            level={level.value}
            render-as={renderAs.value}
            background={background.value}
            foreground={foreground.value}
            gradient={gradient.value}
            gradient-start-color={gradientStartColor.value}
            gradient-end-color={gradientEndColor.value}
            image-settings={imageSettings.value}
          />
          <Link onClick={() => copy(row)} theme="primary" disabled={row.cardState != CardState.Activated} underline={true}>
            复制
          </Link>
        </div>
      );
    },
  },
  {
    title: "客户",
    colKey: "bipClientName",
    ellipsis: true,
    width: 120,
  },
  {
    title: "领卡人",
    colKey: "client",
    className: "td-img",
    width: 150,
    cell(_, { row }) {
      return (
        <TImg
          showView
          height={26}
          srcs={[row.client?.avatar]}
          text={row.client?.nickName}
        />
      );
    },
  },
  {
    title: "过期日期",
    colKey: "expiredTime",
    width: 120,
    cell(_, { row }) {
      return dayjs(row.expiredTime).format("YYYY-MM-DD");
    },
  },

  {
    title: "创建时间",
    width: 180,
    colKey: "creadTime",
  },
  {
    title: "创建人",
    colKey: "creadBy",
    cell(_, { row }) {
      return row.creadBy?.name;
    },
    width: 80,
  },
];

const store = useSettingStore();

const tableHeight = ref(0);
watchEffect(() => {
  const height = store.getContentHeight();
  nextTick(() => {
    const headerEl = document.querySelector(".contentheader");
    const pageEl = document.querySelector(".t-table__pagination");
    tableHeight.value =
      height -
      UTILS.getOuterHeight(headerEl) -
      UTILS.getOuterHeight(pageEl) -
      40;
  });
});

const data = ref([]);
const pagination = ref({
  defaultPageSize: 20,
  total: 100,
  defaultCurrent: 1,
});

const dataLoading = ref(false);
const pageData = ref<CardInfoPage>({
  pageNum: 1,
  pageSize: 20,
  entity: { ...INIT.CardInfo, batch: { ...INIT.CardBatch } },
});

const startNo = ref<string>();
const endNo = ref<string>();

const fetchData = async () => {
  dataLoading.value = true;
  try {
    const PageData = await API.Card.GetCardList(pageData.value);
    data.value = PageData?.dataList;
    pagination.value = {
      ...pagination.value,
      total: PageData?.totalCount,
    };
  } catch (e) {
    console.log(e);
  } finally {
    dataLoading.value = false;
  }
};

const search = () => {
  const temp = [startNo.value, endNo.value];
  pageData.value.entity.cardNo = temp.filter(s => s).join('~')
  pageData.value.pageNum = 1;
  fetchData();
};
const reset = () => {
  pageData.value = {
    pageNum: 1,
    pageSize: 20,
    entity: { ...INIT.CardInfo, batch: { ...INIT.CardBatch } },
  };
  startNo.value = undefined;
  endNo.value = undefined;
  fetchData();
};
onMounted(() => {
  fetchData();
});

const selectedRowKeys = ref([]);

const rowKey = "id";

const rehandleSelectChange = (val: (string | number)[]) => {
  selectedRowKeys.value = val as number[];
};
const pageChange = (pageInfo: PageInfo) => {
  console.log("分页变化", pageInfo);
  pageData.value.pageNum = pageInfo.current;
  pageData.value.pageSize = pageInfo.pageSize;
  fetchData();
};
const rehandleChange = (changeParams: unknown, triggerAndData: unknown) => {
  console.log("统一Change", changeParams, triggerAndData);
};

const addEditVisible = ref(false);
const bipClientOptions = ref<{ label: string, value: string, client: BipClient }[]>();
const getBipClient = async (keyword: string) => {
  if (keyword) {
    const r = await API.Card.GetBIPClientList(keyword);
    bipClientOptions.value = r.map(s => ({ label: s.biPcode + " " + s.biPname, value: s.biPcode, client: s }));
  }
}
const attachClient = () => {
  const bipClientCode = ref<string>();
  const cardNos = ref<string>();
  const dialog = DialogPlugin.confirm({
    header: "关联客户",
    width: 400,
    body: () => <Form colon={true} labelWidth="50px" style="padding:0 10px">
      <FormItem label="客户">
        <Select v-model={bipClientCode.value} onSearch={getBipClient} options={bipClientOptions.value} filterable placeholder="关键词检索"></Select>
      </FormItem>
      <FormItem label="卡号">
        <Textarea v-model={cardNos.value} placeholder="支持输入 0001-00100 或者 0002，使用换行分割" />
      </FormItem>
    </Form>,
    onConfirm: async () => {
      const cardNoList = cardNos.value.replace('\r\n', "|").replace('\r', "|").replace('\n', "|").split("|")
      const client = bipClientOptions.value.find(s => s.value == bipClientCode.value).client;
      const r = await API.Card.AttachClient({ bipClient: client, cardNoList });
      if (r) {
        MessagePlugin.success("关联客户成功");
        dialog.destroy();
        fetchData();
      }
    },
  });
};



const activateCard = () => {
  const cardNos = ref<string>();
  const dialog = DialogPlugin.confirm({
    header: "激活卡",
    width: 400,
    body: () => <Form colon={true} labelWidth="50px" style="padding:0 10px">
      <FormItem label="卡号">
        <Textarea v-model={cardNos.value} placeholder="支持输入 0001-00100 或者 0002，使用换行分割" />
      </FormItem>
    </Form>,
    onConfirm: async () => {
      const cardNoList = cardNos.value.replace('\r\n', "|").replace('\r', "|").replace('\n', "|").split("|")
      const r = await API.Card.ActivateCard(cardNoList);
      if (r.length > 0) {
        MessagePlugin.warning({
          content: () => r.map(s => (<div>{s}</div>)),
          duration: 10000
        });
        dialog.destroy();
        fetchData();
      }
      else if (r.length == 0) {
        MessagePlugin.success("激活卡成功");
        dialog.destroy();
        fetchData();
      }
    },
  });
};


const cancel = (ids: number[]) => {
  const dialog = DialogPlugin.confirm({
    theme: "warning",
    header: "确认作废所选卡券？",
    onConfirm: async () => {
      const r = await API.Card.CardCancel(ids);
      if (r) {
        MessagePlugin.success("作废成功");
        dialog.destroy();
        fetchData();
      }
    },
  });
};

</script>
<template>
  <div>
    <t-card class="list-card-container" :bordered="false" style="padding-top: 8px">
      <div class="contentheader" style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        ">
        <div style="display: flex; align-items: center">
          <t-button @click="cancel(selectedRowKeys)" theme="danger" v-if="Per.CardCardCancel"
            :disabled="selectedRowKeys.length == 0">
            作废</t-button>
          <t-button @click="attachClient" theme="success" v-if="Per.CardAttachClient">
            关联客户</t-button>
          <t-button @click="activateCard" theme='primary' v-if="Per.CardActivateCard">
            激活卡</t-button>
          <p v-if="!!selectedRowKeys.length" style="margin-left: 10px; color: var(--td-text-color-secondary)">
            已选{{ selectedRowKeys.length }}项
          </p>
        </div>
        <div style="display: flex">
          <t-input v-model="startNo" placeholder="卡券开始编号" style="width: 140px;" />
          <t-input v-model="endNo" placeholder="卡券结束编号" style="width: 140px;" />
          <t-input v-model="pageData.entity.batch.batchNo" placeholder="批次编号" style="width: 140px;" />
          <t-button theme="primary" @click="search" style="margin-left: 10px">
            查询
          </t-button>
          <t-button @click="reset" variant="base" theme="default">
            重置
          </t-button>
        </div>
      </div>
      <t-table :data="data" :columns="COLUMNS" :row-key="rowKey" vertical-align="top" :hover="true" :bordered="true"
        :pagination="pagination" :selected-row-keys="selectedRowKeys" :loading="dataLoading" :selectOnRowClick="false"
        @page-change="pageChange" @change="rehandleChange" :max-height="tableHeight"
        @select-change="rehandleSelectChange">
      </t-table>
    </t-card>
  </div>
</template>
