import Layout from "@/layouts/index.vue";
import role from "./role.vue";
import authorize from "./authorize.vue";
import user from "./user.vue";

import { MemberIcon } from "tdesign-icons-vue-next";
import { RouteRecordRaw } from "vue-router";

const router: RouteRecordRaw = {
  path: "/role",
  component: Layout,
  redirect: "/role/list",
  name: "roleIndex",
  meta: {
    title: "角色用户",
    icon: shallowRef(MemberIcon),
    orderNo: 0,
  },
  children: [
    {
      path: "list",
      name: role.name,
      component: role,
      meta: {
        title: "角色列表",
        show: () => Per.RoleGetList,
      },
    },
    {
      path: "authorize",
      name: authorize.name,
      component: authorize,
      meta: {
        title: "角色授权",
        show: () => Per.RoleGetList && Per.RoleGetPermissionsList,
      },
    },
    {
      path: "user",
      name: user.name,
      component: user,
      meta: {
        title: "用户列表",
        show: () => Per.UserGetList,
      },
    },
  ],
};

export default router;
