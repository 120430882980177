<template>
  <div class="table-container">
    <div class="list-menu-wrapper">
      <div class="list-menu-operator">
        <t-input
          v-model="rolePageData.entity.name"
          placeholder="角色关键词"
          @enter="getRoleData()"
          class="filter-list"
        >
          <template #suffix-icon>
            <search-icon size="var(--td-comp-size-xxxs)" />
          </template>
        </t-input>
        <t-menu :style="{ height: heightObj.mheight + 'px' }" v-model="roleID">
          <t-menu-item v-for="role in roleData" :value="role.id">{{
            role.name
          }}</t-menu-item>
        </t-menu>
      </div>
      <div class="list-content">
        <div class="list-common-table">
          <div class="filter-table">
            <div class="filter-title">
              {{ role?.name ? role?.name + " - " : "" }}权限设置
            </div>
            <div style="display: flex">
              <t-input-group>
                <t-input
                  v-model="pkeyword"
                  class="form-item-content"
                  placeholder="模块名称 / 模块Key"
                  :style="{ minWidth: '160px' }"
                />
                <t-input
                  v-model="ckeyword"
                  class="form-item-content"
                  placeholder="权限名称 / 权限Key"
                  :style="{ minWidth: '160px' }"
                />
              </t-input-group>

              <t-button
                theme="primary"
                @click="getPerData"
                :style="{ marginLeft: 'var(--td-comp-margin-s)' }"
              >
                查询
              </t-button>
              <t-button @click="reset" variant="base" theme="default">
                重置
              </t-button>
            </div>
          </div>

          <div class="table-container">
            <t-table
              :data="perdata"
              :columns="COLUMNS"
              :row-key="rowKey"
              vertical-align="top"
              :hover="true"
              :bordered="true"
              :max-height="heightObj.theight"
              :loading="dataLoading"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="tsx">
export default {
  name: "pagesRoleAuthorize"
};
</script>

<script setup lang="tsx">
import { SearchIcon } from "tdesign-icons-vue-next";

import UTILS from "@/utils";
import { MessagePlugin, PrimaryTableCol, TableRowData } from "tdesign-vue-next";
import { onMounted, ref } from "vue";
import { useSettingStore } from "@/store";
import { truncate } from "fs/promises";

type dataType = TableRowData & PerUser & { pName: string; pKey: string };

const ckeyword = ref<string>();
const pkeyword = ref<string>();
const roleID = ref(0);
const roleData = ref<Role[]>([]);
const rolePageData = ref<RolePage>({
  pageNum: 0,
  entity: { name: undefined, isEnabled: true },
});

const reset = () => {
  ckeyword.value = undefined;
  pkeyword.value = undefined;
  getPerData();
};

const perdata = ref<dataType[]>([]);
const rowKey = "rowKey";
const dataLoading = ref(false);

const store = useSettingStore();

const heightObj = ref({ mheight: 0, theight: 0 });
watchEffect(() => {
  const height = store.getContentHeight();
  nextTick(() => {
    const computedStyle = getComputedStyle(document.documentElement);
    const paddingTBXxl = computedStyle.getPropertyValue(
      "--td-comp-paddingTB-xxl",
    );
    const filterheader = document.querySelector(".filter-list");
    const filtertable = document.querySelector(".filter-table");
    const pageEl = document.querySelector(".t-table__pagination");
    heightObj.value = {
      theight:
        height -
        UTILS.getOuterHeight(filtertable) -
        UTILS.getOuterHeight(pageEl) -
        40,
      mheight: height - UTILS.getOuterHeight(filterheader) - 40,
    };
  });
});

const perChange = async (row: dataType, state: boolean) => {
  const r = await API.Role.Authorization(
    role.value.id,
    row.pKey,
    row.key,
    state,
  );
  if (r) {
    selectAllState.value = perdata.value?.every((s) => s.enabled) ?? false;
    MessagePlugin.success("保存成功");
  }
};

let selectAllState = ref(false);

const COLUMNS: PrimaryTableCol[] = [
  {
    title: "模块名称",
    colKey: "pName",
    width: 200,
    cell(_, { row }) {
      return row.pName + " - " + row.pKey;
    },
    attrs: ({ colIndex, rowIndex }) => {
      const rowSpan = rowSpanList.value[rowIndex];
      const lastIndex = rowSpanList.value.findLastIndex((s) => s);
      return {
        class: rowSpan > 0 || rowIndex == -1 ? "spanTd" : "hiddenTd",
        style: {
          "vertical-align": "middle",
          "text-align": "center",
          display: rowSpan > 0 || rowIndex == -1 ? "" : "none",
          "border-bottom-width":
            lastIndex && lastIndex == rowIndex ? "0" : "1px",
        },
        rowspan: colIndex == 0 ? rowSpan : 0,
      };
    },
  },
  {
    title: "权限名称",
    colKey: "name",
    width: 200,
  },

  {
    title: "权限Key",
    colKey: "key",
    width: 200,
  },
  {
    title: "权限状态",
    colKey: "enabled",
    width: 240,
    render(_, { row, rowIndex }) {
      if (rowIndex == -1) {
        return [
          "权限状态",
          Per.RoleAuthorizationList ? (
            <t-switch
              onChange={selectAll}
              v-model={selectAllState.value}
              style="margin-left:10px"
            />
          ) : (
            ""
          ),
        ];
      } else {
        return [
          row.enabled ? (
            <t-tag theme="success" variant="light">
              有权限
            </t-tag>
          ) : (
            <t-tag theme="danger" variant="light">
              无权限
            </t-tag>
          ),
          Per.RoleAuthorization ? (
            <t-switch
              v-model={row.enabled}
              onChange={(state: boolean) => perChange(row as dataType, state)}
              style="margin-left: 13px"
            />
          ) : (
            ""
          ),
        ];
      }
    },
  },
];

const selectAll = async (state: boolean) => {
  const data = perdata.value.map((s) => ({ pKey: s.pKey, cKey: s.key }));
  const r = await API.Role.AuthorizationList(role.value.id, state, data);
  if (r) {
    await getPerData();
    MessagePlugin.success("操作成功");
  } else {
    selectAllState.value = !state;
  }
};

const role = ref<Role>();
watch(roleID, () => {
  role.value = roleData.value?.find((s) => s.id == roleID.value);
  getPerData();
});

const rowSpanList = ref([]);
const getPerData = async () => {
  if (!roleID.value) return;
  const r = await API.Role.GetPermissionsList(
    roleID.value,
    pkeyword.value,
    ckeyword.value,
  );
  const perList: (PerUser & { pName: string; pKey: string; rowKey: string })[] =
    [];
  rowSpanList.value = [];
  r?.forEach((p) => {
    p.childList.forEach((c) => {
      if (perList.some((s) => s.pKey == p.parent.key))
        rowSpanList.value.push(0);
      else rowSpanList.value.push(p.childList.length);
      perList.push({
        ...c,
        pName: p.parent.name,
        pKey: p.parent.key,
        rowKey: p.parent.key + c.key,
      });
    });
  });

  perdata.value = perList;
  selectAllState.value = perdata.value?.every((s) => s.enabled) ?? false;
};
const getRoleData = async () => {
  const r = await API.Role.GetList(rolePageData.value);
  roleData.value = r?.dataList;
  roleID.value = roleData.value?.[0]?.id;
};

onMounted(() => {
  getRoleData();
});
</script>
<style lang="less" scoped>
.table-container {
  background-color: var(--td-bg-color-container);
  border-radius: var(--td-radius-medium);
}

.list-menu-wrapper {
  overflow-y: hidden;
}

.list-menu-operator {
  width: 280px;
  float: left;
  padding: 20px;
  border-right: 1px solid var(--td-border-level-1-color);
  margin-right: -1px;
}

.list-content {
  border-left: 1px solid var(--td-border-level-1-color);
  overflow: auto;
}

.list-common-table {
  background-color: var(--td-bg-color-container);
  padding: 20px;
  border-radius: var(--td-radius-medium);
}

.filter-table {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.form-item-content {
  width: 100%;
}

.filter-title {
  font-size: 20px;
  line-height: var(--td-comp-size-m);
  display: flex;
  min-width: 200px;
}

.operation-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .expand {
    .t-button__text {
      display: flex;
      align-items: center;
    }
  }
}
</style>
