<template>
  <div>
    <t-card
      class="list-card-container"
      :bordered="false"
      style="padding-top: 8px"
    >
      <div
        class="contentheader"
        style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        "
      >
        <div style="display: flex; align-items: center">
          <t-button @click="addOrEditData()" v-if="Per.RoleAdd">
            新建</t-button
          >
          <t-button
            @click="addOrEditData(selectedRowKeys[0])"
            theme="success"
            v-if="Per.RoleEdit"
            :disabled="selectedRowKeys.length != 1"
          >
            编辑</t-button
          >
          <p
            v-if="!!selectedRowKeys.length"
            style="margin-left: 10px; color: var(--td-text-color-secondary)"
          >
            已选{{ selectedRowKeys.length }}项
          </p>
        </div>
        <div style="display: flex">
          <t-input
            v-model="pageData.entity.name"
            placeholder="请输入角色名称"
            style="width: 140px"
          />
          <t-select
            v-model="pageData.entity.isEnabled"
            :options="[
              { label: '启用', value: true },
              { label: '禁用', value: false },
            ]"
            placeholder="启用状态"
            style="width: 120px"
            clearable
          />
          <t-button theme="primary" @click="search" style="margin-left: 10px">
            查询
          </t-button>
          <t-button variant="base" @click="reset" theme="default">
            重置
          </t-button>
        </div>
      </div>
      <t-table
        :data="data"
        :columns="COLUMNS"
        :row-key="rowKey"
        vertical-align="top"
        :hover="true"
        :bordered="true"
        :pagination="pagination"
        :selected-row-keys="selectedRowKeys"
        :loading="dataLoading"
        :selectOnRowClick="false"
        @page-change="pageChange"
        @change="rehandleChange"
        :max-height="tableHeight"
        @select-change="rehandleSelectChange"
      >
      </t-table>
    </t-card>

    <AddEditRole
      v-model:visible="addEditVisible"
      :data="editData"
      @submit="submit"
      @close="addEditVisible = false"
    />
  </div>
</template>

<script lang="tsx">
export default {
  name: "pagesRoleRole"
};
</script>
<script setup lang="tsx">
import { SearchIcon } from "tdesign-icons-vue-next";
import {
  DialogPlugin,
  MessagePlugin,
  PageInfo,
  PrimaryTableCol,
  TableRowData,
} from "tdesign-vue-next";
import { computed, onMounted, ref } from "vue";
import { useSettingStore, useUserStore } from "@/store";
import AddEditRole from "./addEditRole.vue";
import UTILS from "@/utils";
import API from "@/apis";

const submit = async (data: Role) => {
  const r = data.id > 0 ? await API.Role.Edit(data) : await API.Role.Add(data);
  if (r) {
    addEditVisible.value = false;
    MessagePlugin.success("提交成功");
    await fetchData();
  }
};

const COLUMNS: columns<Role>[] = [
  { colKey: "row-select", type: "multiple", width: 60, fixed: "left" },
  {
    title: "角色ID",
    colKey: "id",
    fixed: "left",
    width: 100,
  },
  {
    title: "角色名称",
    colKey: "name",
    fixed: "left",
    width: 160,
  },
  {
    title: "状态",
    colKey: "isEnabled",
    width: 100,
    cell(_, { row }) {
      return (
        <t-tag theme={row.isEnabled ? "success" : "danger"} variant="light">
          {row.isEnabled ? "启用" : "禁用"}
        </t-tag>
      );
    },
  },
  {
    title: "创建时间",
    width: 180,
    colKey: "creadTime",
  },
  {
    title: "创建人",
    colKey: "creadBy",
    cell(_, { row }) {
      return row.creadBy?.name;
    },
    width: 100,
  },
  {
    title: "修改时间",
    width: 180,
    colKey: "editTime",
  },
  {
    title: "修改人",
    colKey: "editBy",
    cell(_, { row }) {
      return row.editBy?.name;
    },
    width: 100,
  },
];

const store = useSettingStore();
const userstore = useUserStore();

const tableHeight = ref(0);
watchEffect(() => {
  const height = store.getContentHeight();
  nextTick(() => {
    const headerEl = document.querySelector(".contentheader");
    const pageEl = document.querySelector(".t-table__pagination");
    tableHeight.value =
      height -
      UTILS.getOuterHeight(headerEl) -
      UTILS.getOuterHeight(pageEl) -
      40;
  });
});

const data = ref([]);
const pagination = ref({
  defaultPageSize: 20,
  total: 100,
  defaultCurrent: 1,
});

const dataLoading = ref(false);
const pageData = ref<RolePage>({
  pageNum: 1,
  pageSize: 20,
  entity: { ...INIT.Role },
});
const fetchData = async () => {
  dataLoading.value = true;
  try {
    const RolePageData = await API.Role.GetList(pageData.value);
    data.value = RolePageData?.dataList;
    pagination.value = {
      ...pagination?.value,
      total: RolePageData?.totalCount,
    };
  } catch (e) {
    console.log(e);
  } finally {
    dataLoading.value = false;
  }
};

const search = () => {
  pageData.value.pageNum = 1;
  fetchData();
};
const reset = () => {
  pageData.value = {
    pageNum: 1,
    pageSize: 20,
    entity: { ...INIT.Role },
  };
  fetchData();
};
onMounted(() => {
  fetchData();
});

const selectedRowKeys = ref([]);

const rowKey = "id";

const rehandleSelectChange = (val: (string | number)[]) => {
  selectedRowKeys.value = val as number[];
};
const pageChange = (pageInfo: PageInfo) => {
  console.log("分页变化", pageInfo);
  pageData.value.pageNum = pageInfo.current;
  pageData.value.pageSize = pageInfo.pageSize;
  fetchData();
};
const rehandleChange = (changeParams: unknown, triggerAndData: unknown) => {
  console.log("统一Change", changeParams, triggerAndData);
};

const addEditVisible = ref(false);
const editData = ref<Role>();
const addOrEditData = (id: number = 0) => {
  if (id == 0) editData.value = INIT.Role;
  else editData.value = data.value.find((z) => z.id == id);
  addEditVisible.value = true;
};

const delData = (ids: number[]) => {
  const dialog = DialogPlugin.confirm({
    theme: "warning",
    header: "确认删除当前角色？",
    onConfirm: async () => {
      const r = await API.Role.Delete(ids);
      if (r) {
        MessagePlugin.success("删除成功");
        dialog.destroy();
        fetchData();
      }
    },
  });
};
</script>
