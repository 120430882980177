<template>
  <t-dialog :visible="visible" width="95%" header="兑换" :close-btn="false" :closeOnOverlayClick="false" 
    :closeOnEscKeydown="false" confirm-btn="提交" cancel-btn="取消" @confirm="onSubmit" @close="onClose">
    <template #body>
      <t-form ref="form" class="base-form" :data="formData" :label-width="70" :rules="FORM_RULES" label-align="left"
        @submit="onSubmit">
        <t-form-item label="卡号">
          <strong>{{ data?.cardNo }}</strong>
        </t-form-item>
        <t-form-item label="兑换商品">
          <strong>{{
            data?.cardType.cardTypeGoodList
              ?.map((s) => s.goods.name + s.goods.stand + "x" + s.qty)
              .join(",")
          }}</strong>
        </t-form-item>
        <t-form-item label="兑换次数" name="count" v-if="data?.cardType.amountOrCount == AmountOrCount.CountCard">
          <t-input-number :min="1" :max="data.leftCount" v-model="formData.count" style="width: 100%"
            placeholder="兑换次数" />
        </t-form-item>
        <t-form-item label="提货方式" name="delivery">
          <t-select v-model="formData.delivery" :options="[
            { label: '快递', value: 'Express' },
            { label: '自提', value: 'SelfPickup' },
          ]" placeholder="请选择" />
          </t-form-item>
        <t-form-item label="收件人" v-if="formData.delivery != 'SelfPickup'" name="addressId">
          <t-select v-model="formData.addressId" :options="optionsData"
            :popup-props="{ overlayClassName: 'select__overlay-option' }" placeholder="请选择" @change="addressSelect"
            @focus="addressFocus" />
          </t-form-item>
        <t-form-item label="收件电话" v-if="formData.delivery != 'SelfPickup'">
          <strong>{{ selectAddress?.phone }}</strong>
        </t-form-item>
        <t-form-item label="收货地址" v-if="formData.delivery != 'SelfPickup'">
          <strong>{{ selectAddress?.province }} {{ selectAddress?.city }}
            {{ selectAddress?.region }} {{ selectAddress?.town }}
            {{ selectAddress?.detail }}</strong>
        </t-form-item>
      </t-form>
    </template>
  </t-dialog>
  <addressEditer :data="address" @close="visibleAddressEditer = false" @update="addressUpdate"
    :visible="visibleAddressEditer" />
</template>

<script lang="tsx">
export default {
  name: "pagesMobileExchange"
};
</script>
<script setup lang="tsx">
import { AmountOrCount, CardKind } from "@/apis/enum";
import UTILS from "@/utils";
import dayjs from "dayjs";
import {
  FormInstanceFunctions,
  FormRule,
  MessagePlugin,
  SelectOption,
  SelectProps,
} from "tdesign-vue-next";
import addressEditer from "./addressEditer.vue";

const address = ref({ ...INIT.Address });
const visibleAddressEditer = ref(false);
const addressUpdate = async (data: Address) => {
  await getAddressList();
}
const addressFocus = () => {
  if (addressList.value.length == 0) {
    visibleAddressEditer.value = true;
  }
}

const FORM_RULES: Record<string, FormRule[]> = {
  name: [{ required: true, message: "请输入商品名称", type: "error" }],
  qty: [
    { required: true, message: "请输入数量", type: "error" },
    { number: true, message: "请输入数量", type: "error" },
  ],
  cardType: [{ required: true, message: "请选择卡类型", type: "error" }],
  cardKind: [{ required: true, message: "请选择卡形态", type: "error" }],
};

const selectAddress = ref<Address>();
const addressSelect = (_: any, context: any) => {
  selectAddress.value = context.option;
  formData.value.addressId = selectAddress.value?.id;

};

interface Props {
  visible: boolean;
  data?: CardInfo;
}
const form = ref<FormInstanceFunctions>(null);

const props = defineProps<Props>();
const formData = ref(INIT.Order);

watch(props, () => {
  if (props.visible) {
    formData.value = {
      cardId: props.data?.id
    };
    getAddressList();
    if (!formData.value.count) formData.value.count = 1;
  }
});

const optionsData = computed<SelectProps["options"]>(() =>
  addressList.value?.map((item) => ({
    value: item.id,
    label: item.name,
    ...item,
    content: () => (
      <div>
        <h3>
          {item.name} {item.phone}
        </h3>
        <div>
          {item.province} {item.city} {item.region} {item.town}
        </div >
        <div style="font-size:12px">{item.detail}</div>
      </div>
    ),
  })),
);
const addressList = ref<Address[]>();

const getAddressList = async () => {
  addressList.value = await API.Mobile.GetAddressList();
  if (!formData.value.addressId) {
    selectAddress.value = addressList.value?.find((s) => s.isDefault) ?? addressList.value?.[0];
    formData.value.addressId = selectAddress.value?.id;
  }
}

onMounted(async () => {
  getAddressList();
});

const emit = defineEmits(["submit", "close"]);
const onSubmit = () => {
  if (formData.value.count > props.data?.leftCount) {
    MessagePlugin.error("兑换次数不能 大于 "+props.data?.leftCount);
    return;
  }
  if (!formData.value.delivery) {
    MessagePlugin.error("请选择提货方式");
    return;
  }
  if (formData.value.delivery != "SelfPickup" && !formData.value.addressId) {
    MessagePlugin.error("非自提方式，请选择地址");
    return;
  }
  emit("submit", formData.value);
};

const onClose = () => {
  emit("close");
};
</script>

<style>

.select__overlay-option {

  
  .t-select-option {
    height: 100%;
    padding: 8px;
  }
}
</style>
<style scoped>
.base-form .t-form__item {
  margin-bottom: 15px;
}
</style>
