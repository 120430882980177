<script lang="ts">
export default {
  name: "pagesMobileAddressEditer"
};
</script>
<script setup lang="ts">
import axios from "axios";
import { MessagePlugin } from "tdesign-vue-next";
import { getLocation, IOpenAddress, openAddress } from "tencent-wx-jssdk";


interface Props {
  visible: boolean;
  data?: Address;
}

const props = defineProps<Props>();

watch(props, () => {
  if (props.visible) {
    if (props.data?.id) initAdress(props.data);
    else {
      getLocationInfo();
    }
  }
});
const emit = defineEmits(["update", "close"]);

const editData = ref<Address>({ ...INIT.Address });

const onSubmit = async () => {
  editData.value.province = selectAdress.value.province?.name;
  editData.value.city = selectAdress.value.city?.name;
  editData.value.region = selectAdress.value.area?.name;
  editData.value.town = selectAdress.value.town?.name;



  if (!editData.value.name) {
    MessagePlugin.error("请输入收件人");
    return;
  }

  if (!editData.value.phone) {
    MessagePlugin.error("请输入手机号");
    return;
  }
  if (!editData.value.province) {
    MessagePlugin.error("请选择省份");
    return;
  }
  if (!editData.value.region) {
    MessagePlugin.error("请选择区县");
    return;
  }
  await API.Mobile.AddOrEditAddress(editData.value);
  emit("update");
  emit("close");
  onClose();
};
const onClose = () => {
  popupShow.value = false;
  emit("close");
};
const poisAddressList = ref<string[]>();
const isLocation = ref(false);

const getAddress = () => {
  openAddress({
    success: function (res) {
      const address: Address = {
        province: res.provinceName,
        city: res.cityName,
        region: res.countryName,
        detail: res.detailInfo,
        name: res.userName,
        phone: res.telNumber,
      }
      initAdress(address);
      isLocation.value = false;
    },
    fail: function () {
      getLocationInfo();
    },
    cancel: function () {
      getLocationInfo();
    }
  } as IOpenAddress & { cancel: () => void })
}

const getLocationInfo = () => {
  selectStr.value = undefined;
  isLocation.value = true;
  getLocation({
    type: "wgs84",
    success: async (res) => {
      const r = await axios.get(
        `https://redirect.jsalc.com/getAddress?location=${res.latitude},${res.longitude}`,
      );
      const s = r.data;
      poisAddressList.value = s.result.pois.map(
        (z: any) => {
          let address = z.address.replace(s.result.address_reference.town.title, "").replace(z.title, "");
          let start = z.title.substr(0, 2);
          let index = address.lastIndexOf(start);
          if (index != -1) {
            const end = address.substr(index);
            if (z.title.includes(end))
              address = address.replace(address.substr(index), "");
          }
          return address +
            " " +
            z.title;
        }
      );
      const address: Address = {
        province: s.result.address_component.province,
        city: s.result.address_component.city,
        region: s.result.address_component.district,
        town: s.result.address_reference.town.title,
        detail: s.result.address_component.street_number ? s.result.address_component.street_number : s.result.address_component.street
      }
      initAdress(address);
      isLocation.value = false;
    },
    fail: () => {
      selectAdress.value = { province: undefined, city: undefined, area: undefined, town: undefined };
      if (!options.value?.length) {
        load().then(r => {
          options.value = r;
          isLocation.value = false;
        })
      }
    },
  });
};



const popupShow = ref(false);
const selectAdress = ref<SelectAdData>({
  province: undefined,
  city: undefined,
  area: undefined,
  town: undefined,
});



const load = async (data?: AdData, prev = false) => {
  console.log(data);
  if (data?.type == "Province") {
    if (prev) {
      selectAdress.value.province = undefined;
      return load();
    }
    selectAdress.value.province = data;
  }
  else if (data?.type == "City") {
    if (prev) {
      selectAdress.value.city = undefined;
      return load();
    }
    selectAdress.value.city = data;
  }
  else if (data?.type == "Area") {
    if (prev) {
      selectAdress.value.area = undefined;
      return load(selectAdress.value.city ? selectAdress.value.province : undefined);
    }
    selectAdress.value.area = data;
  }
  else if (data?.type == "Town") {
    if (prev) {
      selectAdress.value.town = undefined;
      return load(selectAdress.value.city ?? selectAdress.value.province);
    }
    selectAdress.value.town = data;
  }
  const r = await API.Mobile.GetAdData(data);
  let options = r.map(s => ({ label: s.name, value: s.name, data: s }));
  return options;

};

const options = ref<{ label: string, value: string, data: AdData }[]>();

const addressSelect = async (_: any, context: any) => {
  const temp = await load(context.option.data);
  if (temp.length > 0) options.value = temp;
  else {
    popupShow.value = false;
  }
  selectStr.value = getAddressStr();
};

const getAddressStr = () => {
  return (
    (selectAdress.value.province?.name ?? "") +
    (selectAdress.value.city?.name ?? "") +
    (selectAdress.value.area?.name ?? "") +
    (selectAdress.value.town?.name ?? "")
  );
};

const selectStr = ref("");

const preAddress = async () => {
  const temp = await load(
    selectAdress.value.town ??
    selectAdress.value.area ??
    selectAdress.value.city ??
    selectAdress.value.province,
    true,
  );
  if (temp.length > 0) options.value = temp;
  selectStr.value = getAddressStr();
  if (!selectAdress.value.province) popupShow.value = false;
};
const route = useRoute();

const initAdress = async (address: Address) => {
  editData.value.id = address.id;
  editData.value.detail = address.detail;
  editData.value.name = address.name;
  editData.value.phone = address.phone;
  
  const r = await API.Mobile.InitAdData(address);
  selectAdress.value = r;
  options.value = await load(selectAdress.value.town ? selectAdress.value.area : selectAdress.value.city);
  selectStr.value = getAddressStr();

};

const userStore = useUserStore();
onMounted(async () => {
  load()
});

</script>

<template>
  <t-dialog :header="'添加收货地址'" :visible="visible" width="95%" :close-btn="false" :closeOnOverlayClick="false" 
    :closeOnEscKeydown="false" confirm-btn="提交" cancel-btn="取消" @confirm="onSubmit" @close="onClose">
    <t-form :labelWidth="70" style="margin: 0 10px" :data="editData">
      <t-form-item label="收件人" name="name">
        <t-input placeholder="收件人姓名" v-model="editData.name" />
      </t-form-item>
      <t-form-item label="手机号" name="phone">
        <t-input placeholder="手机号" v-model="editData.phone" />
      </t-form-item>
      <t-form-item label="所在地区" name="adress">
        <t-select :disabled="isLocation" :placeholder="isLocation ? '定位中...' : '省、市、区、街道'" :options="options"
          @change="addressSelect" v-model="selectStr" :popup-visible="popupShow" @focus="popupShow = true">
          <template #panelTopContent>
            <div style="padding: 10px 15px 5px 15px; font-size: 12px">
              <t-link theme="primary" underline @click="preAddress()">
                上一级
              </t-link>
              <t-link theme="primary" underline @click="popupShow = false" style="margin-left: 15px">
                确定
              </t-link>
            </div>
          </template>
        </t-select>
      </t-form-item>
      <t-form-item label="详细地址" name="detail">
        <t-auto-complete v-model="editData.detail" :popup-props="{ overlayClassName: 'select__overlay-option' }"
          :disabled="isLocation" :filterable="false" :options="poisAddressList">
          <t-textarea :disabled="isLocation" v-model="editData.detail" :autosize="{ minRows: 3 }"
            :placeholder="isLocation ? '定位中...' : '小区、写字楼、门牌号等'"></t-textarea>
        </t-auto-complete>
        </t-form-item>
      <t-form-item label="默认地址" name="isDefault">
        <t-radio-group v-model="editData.isDefault">
          <t-radio :value="true"> 是 </t-radio>
          <t-radio :value="false"> 否 </t-radio>
        </t-radio-group>
      </t-form-item>
    </t-form>
  </t-dialog>
</template>

<style>

.select__overlay-option .t-select-option {
  height: 100%;
  padding: 5px;
}
</style>
