/** 卡类型1 */
export enum AmountOrCount {
	/**  */
	Unknown = "Unknown",
	/** 金额卡 */
	AmountCard = "AmountCard",
	/** 计次卡 */
	CountCard = "CountCard",
	/**  中文值 */
	UnknownDesc = "",
	/** 金额卡 中文值 */
	AmountCardDesc = "金额卡",
	/** 计次卡 中文值 */
	CountCardDesc = "计次卡",
}


/** 批次状态 */
export enum BatchState {
	/** 未审核 */
	NotReviewed = "NotReviewed",
	/** 已审核 */
	Reviewed = "Reviewed",
	/** 已制卡 */
	CardReady = "CardReady",
	/** 已作废 */
	Deprecated = "Deprecated",
	/** 未审核 中文值 */
	NotReviewedDesc = "未审核",
	/** 已审核 中文值 */
	ReviewedDesc = "已审核",
	/** 已制卡 中文值 */
	CardReadyDesc = "已制卡",
	/** 已作废 中文值 */
	DeprecatedDesc = "已作废",
}


/** 卡类型 */
export enum CardKind {
	/** 实体卡 */
	PCard = "PCard",
	/** 电子卡 */
	ECard = "ECard",
	/** 实体卡 中文值 */
	PCardDesc = "实体卡",
	/** 电子卡 中文值 */
	ECardDesc = "电子卡",
}


/** 卡状态 */
export enum CardState {
	/** 已激活 */
	Activated = "Activated",
	/** 已领取 */
	Received = "Received",
	/** 使用中 */
	InUse = "InUse",
	/** 已完成 */
	Done = "Done",
	/** 已过期 */
	Expired = "Expired",
	/** 已作废 */
	Deprecated = "Deprecated",
	/** 已激活 中文值 */
	ActivatedDesc = "已激活",
	/** 已领取 中文值 */
	ReceivedDesc = "已领取",
	/** 使用中 中文值 */
	InUseDesc = "使用中",
	/** 已完成 中文值 */
	DoneDesc = "已完成",
	/** 已过期 中文值 */
	ExpiredDesc = "已过期",
	/** 已作废 中文值 */
	DeprecatedDesc = "已作废",
}


/**  */
export enum DataType {
	/**  */
	None = "None",
	/**  */
	Province = "Province",
	/**  */
	City = "City",
	/**  */
	Area = "Area",
	/**  */
	Town = "Town",
	/**  中文值 */
	NoneDesc = "",
	/**  中文值 */
	ProvinceDesc = "",
	/**  中文值 */
	CityDesc = "",
	/**  中文值 */
	AreaDesc = "",
	/**  中文值 */
	TownDesc = "",
}


/** 发货方式 */
export enum Delivery {
	/** 自提 */
	SelfPickup = "SelfPickup",
	/** 快递 */
	Express = "Express",
	/** 自提 中文值 */
	SelfPickupDesc = "自提",
	/** 快递 中文值 */
	ExpressDesc = "快递",
}


/** 订单状态 */
export enum OrderState {
	/** 未发货 */
	Unshipped = "Unshipped",
	/** 已发货 */
	Shipped = "Shipped",
	/** 已签收 */
	Signed = "Signed",
	/** 未发货 中文值 */
	UnshippedDesc = "未发货",
	/** 已发货 中文值 */
	ShippedDesc = "已发货",
	/** 已签收 中文值 */
	SignedDesc = "已签收",
}


/** 返回状态 */
export enum ResultState {
	/** 成功 */
	Success = "Success",
	/** 失败 */
	Error = "Error",
	/** 未登录 */
	Unauthorized = "Unauthorized",
	/** 无权限 */
	NoPermission = "NoPermission",
	/** 参数错误 */
	BadRequest = "BadRequest",
	/** 成功 中文值 */
	SuccessDesc = "成功",
	/** 失败 中文值 */
	ErrorDesc = "失败",
	/** 未登录 中文值 */
	UnauthorizedDesc = "未登录",
	/** 无权限 中文值 */
	NoPermissionDesc = "无权限",
	/** 参数错误 中文值 */
	BadRequestDesc = "参数错误",
}


/**  */
export enum Warehouse {
	/**  */
	邮政电商仓 = "邮政电商仓",
	/**  */
	韵达电商仓 = "韵达电商仓",
	/**  中文值 */
	邮政电商仓Desc = "",
	/**  中文值 */
	韵达电商仓Desc = "",
}
