import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "200",
  height: "140",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g mask=\"url(#mask0_17_619)\"><path fill=\"#97A3B7\" d=\"M30 62h88v60H30z\"></path><g filter=\"url(#filter0_f_17_619)\"><path fill=\"#E3E6EB\" d=\"M12 84h80v60H12z\"></path></g><g filter=\"url(#filter1_f_17_619)\"><path fill=\"#E3E6EB\" d=\"M80 54h80v60H80z\"></path></g><path fill=\"#fff\" d=\"M46 105h32v2H46zM46 98h32v2H46zM46 88h16v2H46z\"></path></g><path fill=\"currentcolor\" d=\"M63 20h88v10H63z\" opacity=\".9\"></path><mask id=\"a\" width=\"88\" height=\"50\" x=\"63\" y=\"30\" maskUnits=\"userSpaceOnUse\" style=\"mask-type:alpha;\"><path fill=\"currentcolor\" d=\"M63 30h88v50H63z\"></path></mask><g mask=\"url(#a)\"><path fill=\"currentcolor\" d=\"M63 30h88v50H63z\"></path><g filter=\"url(#filter2_f_17_619)\" opacity=\".3\"><path fill=\"#97A3B7\" d=\"M30 62h88v60H30z\"></path></g></g><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"m95.686 40.858 10.278 10.277A4 4 0 0 1 107 51c2.213 0 4 1.786 4 4q-.002.538-.134 1.033l10.276 10.28-2.828 2.829-4.598-4.597A17.1 17.1 0 0 1 107 65.909c-7.273 0-13.484-4.524-16-10.91a17.3 17.3 0 0 1 5.696-7.472l-3.838-3.84zM99 55a8.003 8.003 0 0 0 12.063 6.892l-3.029-3.026q-.496.132-1.034.134c-2.213 0-4-1.787-4-4q.002-.538.134-1.034l-3.027-3.027A7.96 7.96 0 0 0 99 54.999m8-10.91c7.273 0 13.484 4.524 16 10.91a17.3 17.3 0 0 1-4.166 6.177l-4.105-4.105a8.003 8.003 0 0 0-9.801-9.8l-2.55-2.551c1.47-.41 3.02-.63 4.622-.63\" clip-rule=\"evenodd\"></path><path fill=\"#fff\" d=\"M68 24h2v2h-2zM74 24h2v2h-2zM80 24h66v2H80z\"></path><path fill=\"#fff\" stroke=\"#000\" d=\"m157 54 24.249 42h-48.498z\"></path><path stroke=\"#000\" d=\"M157 89V71\"></path>", 8)
  ])))
}
export default { render: render }