import { request } from "@/utils/request";

/** 后端API */ 
namespace API {
	/** 账户 */ 
	export namespace Account { 
		/**
		 * @description 登录
		 */
		export const Login = async (data: LoginData) => {
			return request.post<string>({ url: `/account/login`, data });
		}

		/**
		 * @description 检查token是否有效
		 */
		export const CheckToken = async () => {
			return request.get<string>({ url: `/account/checktoken` });
		}

		/**
		 * @description 修改个人用户信息
		 */
		export const EditSelf = async (data: User) => {
			return request.post<string>({ url: `/account/editself`, data });
		}

		/**
		 * @description 查看用户权限
		 */
		export const GetUserPermissions = async () => {
			return request.get<object>({ url: `/account/getuserpermissions` });
		}

		/**
		 * @description 查看所有权限
		 */
		export const GetPermissions = async () => {
			return request.get<PerBase[]>({ url: `/account/getpermissions` });
		}

	} 
	/** 卡管理 */ 
	export namespace Card { 
		/**
		 * @description 查看批次列表
		 */
		export const GetBatchList = async (data: CardBatchPage) => {
			return request.post<CardBatchPageData>({ url: `/card/getbatchlist`, data });
		}

		/**
		 * @description 批次导出Excel
		 * @param batchId 
		 */
		export const ExportBatch = async (batchId: number) => {
			return request.get<CardInfo[]>({ url: `/card/exportbatch`, params: { batchId } });
		}

		/**
		 * @description 查看卡列表
		 */
		export const GetCardList = async (data: CardInfoPage) => {
			return request.post<CardInfoPageData>({ url: `/card/getcardlist`, data });
		}

		/**
		 * @description 卡作废
		 */
		export const CardCancel = async (data: number[]) => {
			return request.post<boolean>({ url: `/card/cardcancel`, data });
		}

		/**
		 * @description 新增卡批次
		 */
		export const AddCardBatch = async (data: CardBatch) => {
			return request.post<boolean>({ url: `/card/addcardbatch`, data });
		}

		/**
		 * @description 修改卡批次
		 */
		export const EditCardBatch = async (data: CardBatch) => {
			return request.put<boolean>({ url: `/card/editcardbatch`, data });
		}

		/**
		 * @description 删除卡批次
		 */
		export const DeleteCardBatch = async (data: number[]) => {
			return request.post<boolean>({ url: `/card/deletecardbatch`, data });
		}

		/**
		 * @description 获取BIP客户
		 * @param keyWord 
		 */
		export const GetBIPClientList = async (keyWord: string) => {
			return request.get<BipClient[]>({ url: `/card/getbipclientlist`, params: { keyWord } });
		}

		/**
		 * @description 关联客户
		 */
		export const AttachClient = async (data: AttachClientData) => {
			return request.post<boolean>({ url: `/card/attachclient`, data });
		}

		/**
		 * @description 激活卡
		 */
		export const ActivateCard = async (data: string[]) => {
			return request.post<string[]>({ url: `/card/activatecard`, data });
		}

		/**
		 * @description 审核卡批次
		 */
		export const VerifiedCardBatch = async (data: number[]) => {
			return request.post<boolean>({ url: `/card/verifiedcardbatch`, data });
		}

		/**
		 * @description 卡批次制卡
		 */
		export const MakeCard = async (data: number[]) => {
			return request.post<boolean>({ url: `/card/makecard`, data });
		}

	} 
	/** 卡类型 */ 
	export namespace CardType { 
		/**
		 * @description 查看卡类型列表
		 */
		export const GetList = async (data: CardTypePage) => {
			return request.post<CardTypePageData>({ url: `/cardtype/getlist`, data });
		}

		/**
		 * @description 新增卡类型
		 */
		export const Add = async (data: CardType) => {
			return request.post<boolean>({ url: `/cardtype/add`, data });
		}

		/**
		 * @description 编辑卡类型
		 */
		export const Edit = async (data: CardType) => {
			return request.put<boolean>({ url: `/cardtype/edit`, data });
		}

		/**
		 * @description 删除卡类型
		 */
		export const Delete = async (data: number[]) => {
			return request.post<boolean>({ url: `/cardtype/delete`, data });
		}

	} 
	/** 公开配置 */ 
	export namespace Config { 
		/**
		 * @description 系统配置
		 */
		export const SysConfig = async () => {
			return request.get<SysConfig>({ url: `/config/sysconfig` });
		}

	} 
	/** 文件 */ 
	export namespace File { 
		/**
		 * @description 上传文件
		 * @param file 
		 */
		export const Upload = async (file: File) => {
			return request.post<Upload>({ url: `/file/upload`, params: { file }, headers: {"Content-Type":"multipart/form-data"} });
		}

	} 
	/** 商品 */ 
	export namespace Goods { 
		/**
		 * @description 查看商品列表
		 */
		export const GetList = async (data: GoodsPage) => {
			return request.post<GoodsPageData>({ url: `/goods/getlist`, data });
		}

		/**
		 * @description 查看BIP商品
		 * @param keyword 
		 */
		export const GetBipGoods = async (keyword: string) => {
			return request.get<BipGoods[]>({ url: `/goods/getbipgoods`, params: { keyword } });
		}

		/**
		 * @description 导入商品
		 */
		export const Import = async (data: BipGoods[]) => {
			return request.post<boolean>({ url: `/goods/import`, data });
		}

		/**
		 * @description 导出商品到快麦
		 */
		export const SyncGoods = async () => {
			return request.post<boolean>({ url: `/goods/syncgoods` });
		}

		/**
		 * @description 编辑商品
		 */
		export const Edit = async (data: Goods) => {
			return request.put<boolean>({ url: `/goods/edit`, data });
		}

	} 
	/** 手机端接口 */ 
	export namespace Mobile { 
		/**
		 * @description 检查token
		 */
		export const CheckToken = async () => {
			return request.get<string>({ url: `/mobile/checktoken` });
		}

		/**
		 * @description 登录
		 * @param OpenId 
		 * @param WXImage 
		 * @param Uname 
		 */
		export const Login = async (OpenId: string,WXImage: string,Uname: string) => {
			return request.get<string>({ url: `/mobile/login`, params: { OpenId, WXImage, Uname } });
		}

		/**
		 * @description 获取卡
		 */
		export const GetCard = async (data: CardInfoPage) => {
			return request.post<CardInfoPageData>({ url: `/mobile/getcard`, data });
		}

		/**
		 * @description 获取订单
		 */
		export const GetOrder = async (data: OrderPage) => {
			return request.post<OrderPageData>({ url: `/mobile/getorder`, data });
		}

		/**
		 * @description 获取地址选项
		 */
		export const GetAdData = async (data: AdData) => {
			return request.post<AdData[]>({ url: `/mobile/getaddata`, data });
		}

		/**
		 * @description 根据地址初始化地址选项
		 */
		export const InitAdData = async (data: Address) => {
			return request.post<SelectAdData>({ url: `/mobile/initaddata`, data });
		}

		/**
		 * @description 关联卡
		 * @param cardKey 
		 * @param cardNo 
		 */
		export const AttachCard = async (cardKey: string,cardNo: string) => {
			return request.get<string>({ url: `/mobile/attachcard`, params: { cardKey, cardNo } });
		}

		/**
		 * @description 新增修改地址
		 */
		export const AddOrEditAddress = async (data: Address) => {
			return request.post<boolean>({ url: `/mobile/addoreditaddress`, data });
		}

		/**
		 * @description 获取地址
		 */
		export const GetAddressList = async () => {
			return request.post<Address[]>({ url: `/mobile/getaddresslist` });
		}

		/**
		 * @description 获取物流轨迹
		 * @param orderId 
		 */
		export const GetLogisticsTrade = async (orderId: number) => {
			return request.get<TradeData[]>({ url: `/mobile/getlogisticstrade`, params: { orderId } });
		}

		/**
		 * @description 订单签收
		 * @param orderId 
		 */
		export const Sign = async (orderId: number) => {
			return request.get<boolean>({ url: `/mobile/sign`, params: { orderId } });
		}

		/**
		 * @description 卡兑换
		 */
		export const Exchange = async (data: Order) => {
			return request.post<string>({ url: `/mobile/exchange`, data });
		}

	} 
	/** 操作日志 */ 
	export namespace OpLog { 
		/**
		 * @description 查看操作日志列表
		 */
		export const GetList = async (data: OpLogPage) => {
			return request.post<OpLogPageData>({ url: `/oplog/getlist`, data });
		}

	} 
	/** 订单 */ 
	export namespace Order { 
		/**
		 * @description 查看订单列表
		 */
		export const GetList = async (data: OrderPage) => {
			return request.post<OrderPageData>({ url: `/order/getlist`, data });
		}

		/**
		 * @description 发货
		 * @param warehouse 
		 */
		export const Delivery = async (warehouse: '邮政电商仓' | '韵达电商仓',data: number[]) => {
			return request.post<string[]>({ url: `/order/delivery`, data, params: { warehouse } });
		}

		/**
		 * @description 同步发货状态
		 */
		export const SyncDeliveryState = async () => {
			return request.post<boolean>({ url: `/order/syncdeliverystate` });
		}

	} 
	/** 角色 */ 
	export namespace Role { 
		/**
		 * @description 查看角色列表
		 */
		export const GetList = async (data: RolePage) => {
			return request.post<RolePageData>({ url: `/role/getlist`, data });
		}

		/**
		 * @description 查看角色权限
		 * @param roleId 
		 * @param pKey 
		 * @param cKey 
		 */
		export const GetPermissionsList = async (roleId: number,pKey: string,cKey: string) => {
			return request.get<PerBasePerUserPermissions[]>({ url: `/role/getpermissionslist`, params: { roleId, pKey, cKey } });
		}

		/**
		 * @description 角色授权
		 * @param roleid 角色id
		 * @param mkey 
		 * @param pkey 
		 * @param enabled 
		 */
		export const Authorization = async (roleid: number,mkey: string,pkey: string,enabled: boolean) => {
			return request.get<boolean>({ url: `/role/authorization`, params: { roleid, mkey, pkey, enabled } });
		}

		/**
		 * @description 角色批量授权
		 * @param roleid 角色id
		 * @param enabled 
		 * @param data 权限
		 */
		export const AuthorizationList = async (roleid: number,enabled: boolean,data: PerInfo[]) => {
			return request.post<boolean>({ url: `/role/authorizationlist`, data, params: { roleid, enabled } });
		}

		/**
		 * @description 新增角色
		 */
		export const Add = async (data: Role) => {
			return request.post<boolean>({ url: `/role/add`, data });
		}

		/**
		 * @description 编辑角色
		 */
		export const Edit = async (data: Role) => {
			return request.put<boolean>({ url: `/role/edit`, data });
		}

		/**
		 * @description 删除角色
		 */
		export const Delete = async (data: number[]) => {
			return request.post<boolean>({ url: `/role/delete`, data });
		}

	} 
	/** 用户 */ 
	export namespace User { 
		/**
		 * @description 查看用户列表
		 */
		export const GetList = async (data: UserPage) => {
			return request.post<UserPageData>({ url: `/user/getlist`, data });
		}

		/**
		 * @description 新增用户
		 */
		export const Add = async (data: User) => {
			return request.post<boolean>({ url: `/user/add`, data });
		}

		/**
		 * @description 编辑用户
		 */
		export const Edit = async (data: User) => {
			return request.put<boolean>({ url: `/user/edit`, data });
		}

		/**
		 * @description 删除用户
		 */
		export const Delete = async (data: number[]) => {
			return request.post<boolean>({ url: `/user/delete`, data });
		}

	} 
}

export default API 
