<template>
  <t-dialog
    :visible="visible"
    :width="560"
    :close-btn="true"
    :closeOnOverlayClick="false"
    :closeOnEscKeydown="false"
    confirm-btn="提交"
    cancel-btn="取消"
    @confirm="onSubmit"
    @close="onClose"
  >
    <template #header>{{
      formData?.id > 0 ? "修改卡类型" : "新增卡类型"
    }}</template>
    <template #body>
      <t-form
        ref="form"
        class="base-form"
        :data="formData"
        :rules="FORM_RULES"
        label-align="top"
        @submit="onSubmit"
      >
        <t-row class="row-gap" :gutter="[30, 10]" style="margin: 0">
          <t-col :span="6">
            <t-form-item label="类型名称" name="name">
              <t-input v-model="formData.name" placeholder="类型名称" />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="消费方式" name="type">
              <t-select
                v-model="formData.amountOrCount"
                placeholder="- 请选择 -"
                :options="amountOrCount"
              />
            </t-form-item>
          </t-col>

          <t-col :span="6">
            <t-form-item label="卡金额" name="amount">
              <t-input
                v-model="formData.amount"
                type="number"
                placeholder="卡金额"
                :disabled="formData.amountOrCount !== 'AmountCard'"
              />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="卡次数" name="count">
              <t-input
                v-model="formData.count"
                type="number"
                placeholder="卡次数"
                :disabled="formData.amountOrCount !== 'CountCard'"
              />
            </t-form-item>
          </t-col>

          <t-col :span="12">
            <t-form-item label="类型说明" name="description">
              <t-input v-model="formData.description" placeholder="类型说明" />
            </t-form-item>
          </t-col>
          <t-col :span="12">
            <t-form-item label="卡图片" name="description">
              <ImgUpload
                :fileList="[formData.image]"
                @change="
                  (file: Upload) => {
                    formData.imageId = file.id;
                    formData.image = file;
                  }
                "
                :max-size="1024"
              />
            </t-form-item>
          </t-col>
          <t-col :span="12">
            <t-form-item name="goods">
              <template #label>
                关联商品
                <t-button
                  shape="round"
                  size="small"
                  theme="primary"
                  variant="dashed"
                  style="font-size: 10px; margin-left: 5px; height: 20px"
                  @click="addgoods"
                  >新增</t-button
                >
              </template>
              <t-space direction="vertical">
                <t-space
                  :key="i"
                  v-for="(goods, i) of formData.cardTypeGoodList"
                >
                  <t-select
                    style="width: 270px"
                    :key="i"
                    filterable
                    clearable
                    v-model="goods.goodsId"
                    placeholder="- 请选择 -"
                    :options="goodsOptions"
                  />
                  <t-input
                    v-if="formData.amountOrCount == AmountOrCount.CountCard"
                    style="width: 100px"
                    :key="i"
                    v-model="goods.qty"
                    placeholder="数量"
                  />
                  <t-button
                    theme="danger"
                    variant="dashed"
                    @click="removeGoods(i)"
                    >移除</t-button
                  >
                </t-space>
              </t-space>
            </t-form-item>
          </t-col>
        </t-row>
      </t-form>
    </template>
  </t-dialog>
</template>

<script lang="ts">
export default {
  name: "pagesCardTypeEditer"
};
</script>
<script setup lang="ts">
import { AmountOrCount } from "@/apis/enum";
import UTILS from "@/utils";
import dayjs from "dayjs";
import { FormInstanceFunctions, FormRule } from "tdesign-vue-next";
import { AddIcon } from "tdesign-icons-vue-next";
const FORM_RULES: Record<string, FormRule[]> = {
  name: [{ required: true, message: "请输入类型名称", type: "error" }],
  amount: [{ number: true, message: "请输入金额", type: "error" }],
  count: [{ number: true, message: "请输入次数", type: "error" }],
  amountOrCount: [{ required: true, message: "请选择消费方式", type: "error" }],
};

const amountOrCount = UTILS.enumToOption(AmountOrCount);

interface Props {
  visible: boolean;
  data?: User;
}
const form = ref<FormInstanceFunctions>(null);

const props = defineProps<Props>();
const formData = ref<CardType>(INIT.CardType);

const imgUploadKey = ref(0);
watch(props, () => {
  imgUploadKey.value = dayjs().unix();
  formData.value = props.data ? { ...props.data } : { ...INIT.CardType };
});

const goodsOptions = ref([]);
const getGoodsOptions = async () => {
  const r = await API.Goods.GetList({ pageSize: 0 });
  goodsOptions.value = r.dataList.map((s) => ({
    label: s.code + " - " + s.name + s.stand,
    value: s.id,
  }));
};

onMounted(() => {
  getGoodsOptions();
});

const addgoods = () => {
  if (!formData.value.cardTypeGoodList) formData.value.cardTypeGoodList = [];
  formData.value.cardTypeGoodList.push({ ...INIT.Goods });
};

const removeGoods = (i: number) => {
  formData.value.cardTypeGoodList.splice(i, 1);
};

const emit = defineEmits(["submit", "close"]);
const onSubmit = () => {

  form.value.validate().then((r) => {
    if (r === true) emit("submit", formData.value);
  });
};

const onClose = () => {

  if (formData.value.cardTypeGoodList)
    formData.value.cardTypeGoodList = formData.value.cardTypeGoodList.filter(
      (s) => s.id > 0,
    );
  emit("close");
};
</script>
