<template>
  <t-dialog
    :visible="visible"
    :width="560"
    :close-btn="true"
    :closeOnOverlayClick="false"
    :closeOnEscKeydown="false"
    confirm-btn="提交"
    cancel-btn="取消"
    @confirm="onSubmit"
    @close="onClose"
  >
    <template #header>{{
      formData?.id > 0 ? "修改商品" : "新增商品"
    }}</template>
    <template #body>
      <t-form
        ref="form"
        class="base-form"
        :data="formData"
        :rules="FORM_RULES"
        label-align="top"
        @submit="onSubmit"
      >
        <t-row class="row-gap" :gutter="[30, 10]" style="margin: 0">
          <t-col :span="6">
            <t-form-item label="商品编号" name="code">
              <t-input
                v-model="formData.code"
                placeholder="商品编号"
                disabled
              />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="商品名称" name="name">
              <t-input
                v-model="formData.name"
                placeholder="商品名称"
                disabled
              />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="商品规格" name="stand">
              <t-input
                v-model="formData.stand"
                placeholder="商品名称"
                disabled
              />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="商品单价" name="price">
              <t-input
                v-model="formData.price"
                placeholder="商品单价"
                type="number"
              />
            </t-form-item>
          </t-col>
          <t-col :span="12">
            <t-form-item label="商品主图" name="imageId">
              <ImgUpload
                :fileList="[formData.image]"
                @change="
                  (file: Upload) => {
                    formData.imageId = file.id;
                    formData.image = file;
                  }
                "
                :max-size="1024"
              />
            </t-form-item>
          </t-col>
          <t-col :span="12">
            <t-form-item label="详情图" name="stand">
              <ImgUpload
                :fileList="formData.details?.map((s) => s.image)"
                @change="
                  (_, files?: Upload[]) => {
                    formData.details = files.map((s, i) => ({
                      imageId: s.id,
                      image: s,
                      sort: i,
                    }));
                  }
                "
                multiple
                :max-size="1000"
              />
            </t-form-item>
          </t-col>
          </t-row>
      </t-form>
    </template>
  </t-dialog>
</template>

<script lang="ts">
export default {
  name: "pagesGoodsGoodsEditer"
};
</script>
<script setup lang="ts">
import { CardKind } from "@/apis/enum";
import UTILS from "@/utils";
import dayjs from "dayjs";
import {
  FormInstanceFunctions,
  FormRule,
  SelectOption,
} from "tdesign-vue-next";

const FORM_RULES: Record<string, FormRule[]> = {
  name: [{ required: true, message: "请输入商品名称", type: "error" }],
  qty: [
    { required: true, message: "请输入数量", type: "error" },
    { number: true, message: "请输入数量", type: "error" },
  ],
  cardType: [{ required: true, message: "请选择卡类型", type: "error" }],
  cardKind: [{ required: true, message: "请选择卡形态", type: "error" }],
};

const cardKinds = UTILS.enumToOption(CardKind);
const cardTypes = ref<SelectOption[]>();

interface Props {
  visible: boolean;
  data?: User;
}
const form = ref<FormInstanceFunctions>(null);

const props = defineProps<Props>();
const formData = ref<Goods>(INIT.CardBatch);

const imgUploadKey = ref(0);
watch(props, () => {
  imgUploadKey.value = dayjs().unix();
  formData.value = props.data ? { ...props.data } : { ...INIT.CardBatch };
});

const getCardType = async () => {
  const r = await API.CardType.GetList({ pageSize: 0 });
  cardTypes.value = r.dataList.map((s) => ({ label: s.name, value: s.id }));
};
onMounted(() => {
  getCardType();
});

const emit = defineEmits(["submit", "close"]);
const onSubmit = () => {
  form.value.validate().then((r) => {
    if (r === true) emit("submit", formData.value);
  });
};

const onClose = () => {
  emit("close");
};
</script>
