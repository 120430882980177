import { defineStore } from "pinia";
import { isEmpty } from "lodash-es";

import API from "@/apis";
import utils from "@/utils";
import router, { pageRouterList } from "@/router";
import { RouteRecordRaw } from "vue-router";
import dayjs from "dayjs";
import UTILS from "@/utils";
import axios from "axios";
import { config, jsApiList } from "tencent-wx-jssdk";

const InitUserInfo: UserInfo = {
  id: "", // 主键id
  exp: 0, // 过期时间 单位秒
  name: "",
  avatar: "",
  phone: "",
  wid: "",
  typ: "pc",
};
type rconfig = {
  appId: string;
  jsapi_ticket: string;
  nonceStr: string;
  signature: string;
  timestamp: number;
};
export const useUserStore = defineStore("user", {
  state: () => ({
    token: "",
    permission: {} as Record<PerType, boolean>,
    menuList: [] as Array<RouteRecordRaw>,
    timeDiff: 0,
    userInfo: { ...InitUserInfo },
    pageUrl: ""
  }),

  actions: {
    initMenus() {
      const templist: Array<RouteRecordRaw> = [];
      pageRouterList.forEach((r) => {
        if (!r.meta?.show || r.meta?.show()) {
          const route = { ...r };
          const children: Array<RouteRecordRaw> = [];
          r.children?.forEach((childRouter) => {
            const show = childRouter.meta?.show;
            if (!show || show()) {
              children.push(childRouter);
            }
          });
          if (children.length > 0) {
            route.children = children;
            templist.push(route);
          }
        }
      });
      this.menuList = templist;
    },
    async configWxApi(jsApiList: jsApiList) {
      if (UTILS.isIos()) {
        if (!this.pageUrl) this.pageUrl = location.href;
      }
      else {
        this.pageUrl = location.href;
      }
      const r = await axios.get<rconfig>("https://redirect.jsalc.com/sign?" + this.pageUrl);
      const configObj = {
        ...r.data,
        debug: false,
        jsApiList,
        openTagList: ['']
      };
      config(configObj);
    },
    async login(userInfo: LoginData) {
      this.token = await API.Account.Login(userInfo);
      this.initUserInfo();
      await this.initPermission();
      const redirect = router.currentRoute.value.query.redirect ?? '/';
      const path = Array.isArray(redirect) ? redirect[0] : redirect;
      router.push(path);
      return !isEmpty(this.token);
    },

    async mobileLogin(OpenId: string, WXImage: string, Uname: string) {
      this.token = await API.Mobile.Login(OpenId, WXImage, Uname);
      this.initUserInfo();
      return !isEmpty(this.token);
    },
    async editSelf(userInfo: User) {
      this.token = await API.Account.EditSelf(userInfo);
      this.initUserInfo();
      // await this.initPermission();
      return !isEmpty(this.token);
    },
    initUserInfo() {
      if (!this.token) return;
      const dtoken = utils.jwtdecode(this.token);
      this.timeDiff = dayjs().unix() - dtoken.nbf;
      this.userInfo = {
        id: dtoken.id,
        wid: dtoken.wid,
        phone: dtoken.phone,
        exp: dtoken.exp,
        name: dtoken.name,
        typ: dtoken.typ,
        avatar: dtoken.avatar,
      };
      if (this.userInfo.typ == "pc")
        this.userInfo.avatar = dtoken.avatar
          ? dtoken.avatar + "?t=" + this.token
          : "";
    },

    async logout() {
      // const isMobile = router.currentRoute.value.path.startsWith("/mobile")
      const isWeChat = UTILS.isWeChat();

      if (!isEmpty(this.token)) {
        this.token = "";
        this.permission = {} as Record<PerType, boolean>;
        this.userInfo = { ...InitUserInfo, typ: this.userInfo.typ };
      }
      if (isWeChat) {
        router.push({
          path: "/mobile",
          query: {
            redirect: router.currentRoute.value.query.redirect ?? encodeURIComponent(router.currentRoute.value.fullPath),
            // r: dayjs().unix(),
          },
        });
      } else {
        router.push({
          path: "/login",
          query: {
            redirect: router.currentRoute.value.query.redirect ?? encodeURIComponent(router.currentRoute.value.fullPath),
          },
        });
      }
    },
    async checkToken() {
      if (!this.token) return false;
      const leftTime = this.userInfo.exp + this.timeDiff - dayjs().unix();
      console.log("token剩余：" + leftTime);
      if (leftTime > 5 * 60) return true;
      const r =
        this.userInfo.typ == "pc"
          ? await API.Account.CheckToken()
          : await API.Mobile.CheckToken();
      if (!isEmpty(r)) {
        this.token = r;
        this.initUserInfo();
        return true;
      }
      return false;
    },
    async initPermission() {
      if (!this.token) return;
      const r = await API.Account.GetUserPermissions();
      if (!isEmpty(r)) {
        this.permission = r as Record<PerType, boolean>;
        this.initMenus();
        // initRouter();
      }
    },
    checkPermission(pers: PerType[]) {
      if (!pers) pers = [];
      if (isEmpty(this.permission)) return false;
      return pers.every((per) => this.permission[per]);
    },
    getImgUrl(imgUrl?: string) {
      if (!imgUrl) return "/null.png";
      if (imgUrl.includes("?t=")) return imgUrl;
      else return `${imgUrl}?t=${this.token}`;
    },
  },
  persist: {
    key: "user",
    pick: ["token", "userInfo", "timeDiff"],
  },
});

export function checkPer(per: PerType) {
  const userstore = useUserStore(store);
  return userstore.checkPermission([per]);
}

export function checkPers(pers: PerType[]) {
  const userstore = useUserStore(store);
  return userstore.checkPermission(pers);
}

// export function getImgUrl(imgUrl?: string) {
//   const userstore = useUserStore(store);
//   console.log(userstore.token)
//   if (imgUrl.includes('?t=')) return imgUrl;
//   else return `${imgUrl}?t=${userstore.token}`
// }
