import layout from "./layout.vue";
import index from "./index.vue";
import card from "./card.vue";
import order from "./order.vue";
import address from "./address.vue";
import { RouteRecordRaw } from "vue-router";

const router: RouteRecordRaw = {
  path: "/mobile",
  component: layout,
  redirect: "/mobile/index",
  children: [
    {
      path: "index",
      name: index.name,
      component: index,
    },
    {
      path: "card",
      name: card.name,
      meta: {
        title: "卡券列表",
      },
      component: card,
    },
    {
      path: "order",
      name: order.name,
      meta: {
        title: "订单列表",
      },
      component: order,
    },
    {
      path: "address",
      name: address.name,
      meta: {
        title: "收货地址",
      },
      component: address,
    },
  ],
};

export default router;
