<script lang="tsx">
export default {
  name: "pagesOrderOrderList"
};
</script>
<script setup lang="tsx">
import { DialogPlugin, MessagePlugin, PageInfo } from "tdesign-vue-next";
import { useSettingStore } from "@/store";
import TImg from "@/components/TImg.vue";

import UTILS from "@/utils";
import { AmountOrCount, Delivery, OrderState, Warehouse } from "@/apis/enum";

const COLUMNS: columns<Order>[] = [
  { colKey: "row-select", type: "multiple", width: 60, fixed: "left" },
  {
    title: "订单编号",
    colKey: "code",
    fixed: "left",
    width: 180,
  },
  {
    title: "创建时间",
    width: 170,
    colKey: "creadTime",
  },
  {
    title: "卡编号",
    width: 110,
    colKey: "card",
    cell(_, { row }) {
      return row.card?.cardNo;
    },
  },
  {
    title: "卡名称",
    width: 100,
    colKey: "card",
    ellipsis: true,
    cell(_, { row }) {
      return row.card?.batch?.name;
    },
  },
  {
    title: "扣减",
    width: 80,
    colKey: "card",
    ellipsis: true,
    cell(_, { row }) {
      if (row.card.cardType.amountOrCount == AmountOrCount.AmountCard)
        return row.amount + "元";
      else
        return row.count + "次";
    },
  },
  {
    title: "卡类型",
    width: 100,
    colKey: "card",
    ellipsis: true,
    cell(_, { row }) {
      return row.card?.cardType?.name;
    },
  },
  {
    title: "下单客户",
    colKey: "client",
    className: "td-img",
    width: 100,
    ellipsis: true,
    cell(_, { row }) {
      return (
        <TImg
          showView
          height={26}
          srcs={[row.client?.avatar]}
          text={row.client?.nickName}
        />
      );
    },
  },
  {
    title: "提货方式",
    width: 90,
    colKey: "delivery",
    cell(_, { row }) {
      return UTILS.getEnumDesc(Delivery, row.delivery);
    },
  },
  {
    title: "发货信息",
    width: 170,
    colKey: "address",
    ellipsis: true,
    cell(_, { row }) {
      return (row.address?.name ?? "") + " " + (row.address?.phone ?? "") + " " + (row.address?.province ?? "") +
        (row.address?.city ?? "") +
        (row.address?.region ?? "") +
        (row.address?.town ?? "") +
        (row.address?.detail ?? "")
    },
  },
  {
    title: "订单商品",
    width: 150,
    colKey: "detailList",
    cell(_, { row }) {
      return (
        <div style="font-size:12px">
          {row.detailList.map((s) => (
            <div>{s.goods.name + " " + s.goods.stand + " x " + s.qty}</div>
          ))}
        </div>
      );
    },
  },

  {
    title: "发货状态",
    width: 150,
    colKey: "shipper",
    cell(_, { row }) {
      return <div style="font-size:12px">
        <div>状态：<span style={row.orderState == OrderState.Unshipped ? 'color:orange;font-weight:bold' : 'color:green;font-weight:bold'}>{UTILS.getEnumDesc(OrderState, row.orderState)}</span></div>
        <div>发货人：{row.shipper?.name}</div>
        <div>{row.deliveryTime}</div>
      </div>;
    },
  },
  {
    title: "快递信息",
    width: 140,
    colKey: "deliveryTime",
    cell(_, { row }) {
      return <div style="font-size:12px">
        <div>
          {row.expressCompany}
        </div>
        {row.expressNumber.split("|").map(s => <div>{s}</div>)}
      </div>
    },
  },
];

const store = useSettingStore();
const userstore = useUserStore();

const tableHeight = ref(0);
watchEffect(() => {
  const height = store.getContentHeight();
  nextTick(() => {
    const headerEl = document.querySelector(".contentheader");
    const pageEl = document.querySelector(".t-table__pagination");
    tableHeight.value =
      height -
      UTILS.getOuterHeight(headerEl) -
      UTILS.getOuterHeight(pageEl) -
      40;
  });
});

const data = ref([]);
const pagination = ref({
  defaultPageSize: 20,
  total: 100,
  defaultCurrent: 1,
});

const dataLoading = ref(false);
const pageData = ref<OrderPage>({
  pageNum: 1,
  pageSize: 20,
  entity: { ...INIT.Order, card: { ...INIT.CardInfo }, address: { ...INIT.Address } },
});
const fetchData = async () => {
  dataLoading.value = true;
  try {
    const PageData = await API.Order.GetList(pageData.value);
    data.value = PageData?.dataList;
    pagination.value = {
      ...pagination.value,
      total: PageData?.totalCount,
    };
  } catch (e) {
    console.log(e);
  } finally {
    dataLoading.value = false;
  }
};

const search = () => {
  pageData.value.pageNum = 1;
  fetchData();
};
const reset = () => {
  pageData.value = {
    pageNum: 1,
    pageSize: 20,
    entity: { ...INIT.Order, card: { ...INIT.CardInfo }, address: { ...INIT.Address } }
  };
  fetchData();
};
onMounted(() => {
  fetchData();
});

const selectedRowKeys = ref([]);

const rowKey = "id";

const rehandleSelectChange = (val: (string | number)[]) => {
  selectedRowKeys.value = val as number[];
};
const pageChange = (pageInfo: PageInfo) => {
  console.log("分页变化", pageInfo);
  pageData.value.pageNum = pageInfo.current;
  pageData.value.pageSize = pageInfo.pageSize;
  fetchData();
};
const rehandleChange = (changeParams: unknown, triggerAndData: unknown) => {
  console.log("统一Change", changeParams, triggerAndData);
};

const ShipVisible = ref(false);

const DeliveryFunc = () => {
  const warehouse = ref<'邮政电商仓' | '韵达电商仓'>();
  const dialog = DialogPlugin({
    header: '选择发货仓库',
    body: () => <t-radio-group v-model={warehouse.value}>
      <t-radio-button value={Warehouse.邮政电商仓}>{Warehouse.邮政电商仓}</t-radio-button>
      <t-radio-button value={Warehouse.韵达电商仓}>{Warehouse.韵达电商仓}</t-radio-button>
    </t-radio-group>,
    onCancel: () => dialog.destroy(),
    onClose: () => dialog.destroy(),
    onConfirm: async () => {
      if (!warehouse.value) {
        MessagePlugin.error("请选择仓库");
        return;
      }
      const r = await API.Order.Delivery(warehouse.value, selectedRowKeys.value);
      if (r.length > 0) {
        MessagePlugin.warning({
          content: () => r.map(s => (<div>{s}</div>)),
          duration: 10000
        });
      }
      else
        MessagePlugin.success("发货成功");
    }

  });
}

const orderState = UTILS.enumToOption(OrderState);
const deliveryOption = UTILS.enumToOption(Delivery);
const SyncDeliveryState = async () => {

  const dialog = DialogPlugin.confirm({
    theme: "warning",
    header: "确认同步发货状态？",
    onConfirm: async () => {
      dialog.destroy();
      const r = await API.Order.SyncDeliveryState();
      if (r) {
        MessagePlugin.success("同步完成");
        fetchData();
      }
    },
  });
}

</script>
<template>
  <div>
    <t-card class="list-card-container" :bordered="false" style="padding-top: 8px">
      <div class="contentheader" style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        ">
        <div style="display: flex; align-items: center">
          <t-button @click="DeliveryFunc" theme="primary" v-if="Per.OrderDelivery"
            :disabled="selectedRowKeys.length == 0">
            发货</t-button>
          <t-button theme="success" @click="SyncDeliveryState">
            同步发货状态</t-button>

          <p v-if="!!selectedRowKeys.length" style="margin-left: 10px; color: var(--td-text-color-secondary)">
            已选{{ selectedRowKeys.length }}项
          </p>
        </div>
        <div style="display: flex">
          <t-input style="width: 180px;" v-model="pageData.entity.code" placeholder="订单编号 / 卡编号" />
          <t-input style="width: 150px;" v-model="pageData.entity.address.name" placeholder="收货人 / 电话" />
          <t-input style="width: 150px;" v-model="pageData.entity.expressNumber" placeholder="运单号" />
          <t-select style="width: 150px;" v-model="pageData.entity.orderState" placeholder="状态" :options="orderState"
            clearable />
          <t-select style="width: 150px;" v-model="pageData.entity.delivery" placeholder="提货方式"
            :options="deliveryOption" clearable />

          <t-button theme="primary" @click="search" style="margin-left: 10px">
            查询
          </t-button>
          <t-button variant="base" @click="reset" theme="default">
            重置
          </t-button>
        </div>
      </div>
      <t-table :data="data" :columns="COLUMNS" :row-key="rowKey" vertical-align="top" :hover="true" :bordered="true"
        :pagination="pagination" :selected-row-keys="selectedRowKeys" :loading="dataLoading" :selectOnRowClick="false"
        @page-change="pageChange" @change="rehandleChange" :max-height="tableHeight" :expand-icon="true"
        @select-change="rehandleSelectChange">
        <template #expandedRow="{ row }">
          <t-list>
            <t-list-item v-for="detail in (row.detailList as OrderDetail[])">
              <t-list-item-meta class="meta" :image="userstore.getImgUrl(detail.goods.image?.fileUrl)" :title="detail.goods.name +
                ' ' +
                detail.goods.stand +
                ' x ' +
                detail.qty
                " :description="'物料编码：' + detail.goods?.code" />
            </t-list-item>
          </t-list>
        </template>
      </t-table>
    </t-card>
  </div>
</template>

<style lang="less" scoped>
.meta {
  align-items: center;

  & :first-child {
    border-radius: 0;
  }
}
</style>
