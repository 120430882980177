<script lang="tsx">
export default {
  name: "componentsImgUpload"
};
</script>
<script setup lang="tsx">
import { UploadFile, UploadProps, UploadRemoveContext } from "tdesign-vue-next";
import {
  BrowseIcon,
  DeleteIcon,
  AddIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "tdesign-icons-vue-next";

interface Props {
  
  maxSize?: number;
  fileList?: Upload[];
  multiple?: boolean;
  ukey?: number;
}
const uploadRef = ref();
const useStore = useUserStore();
const props = defineProps<Props>();
const files = ref<UploadFile[]>([]);

const visible = ref(false);
const imgIndex = ref(0);
const showImg = (index: number) => {
  imgIndex.value = index;
  visible.value = true;
};

watchEffect(() => {
  files.value =
    props.fileList
      ?.filter((s) => s?.fileUrl)
      .map((s) => ({ id: s.id, url: useStore.getImgUrl(s.fileUrl) })) ?? [];
});
const emit = defineEmits<{
  change: [file: Upload, files: Upload[]];
}>();

const requestMethod: UploadProps["requestMethod"] = (
  files: UploadFile | UploadFile[],
) => {
  const file = Array.isArray(files) ? files[0] : files;
  return new Promise((resolve) => {
    API.File.Upload(file.raw).then((s) => {
      if (s) {
        file.id = s.id;
        resolve({
          status: "success",
          response: {
            url: `${s.fileUrl}?t=${useStore.token}`,
          },
        });
      } else {
        resolve({
          status: "fail",
          error: "上传失败",
          response: {},
        });
      }
    });
  });
};

const success = () => {
  const tempList = files.value.map((s) => ({ id: s.id, fileUrl: s.url }));
  emit("change", tempList?.[0] ?? INIT.Upload, tempList);
};
const remove = (img: UploadFile) => {
  files.value = files.value.filter((s) => s.id != img.id);
  success();
};

const prev = (index: number) => {
  if (index > 0) {
    const file = files.value[index];
    files.value.splice(index, 1);
    files.value.splice(index - 1, 0, file);
    success();
  }
};
const next = (index: number) => {
  if (index < files.value.length) {
    const file = files.value[index];
    files.value.splice(index, 1);
    files.value.splice(index + 1, 0, file);
    success();
  }
};
</script>

<template>
  <t-image-viewer
    v-model:visible="visible"
    :images="files.map((s: UploadFile) => s.url)"
    :index="imgIndex"
    @IndexChange="(i) => (imgIndex = i)"
    :close-on-overlay="true"
  />
  <t-upload
    ref="uploadRef"
    :key="ukey"
    theme="image"
    accept="image/*"
    v-model="files"
    :multiple="props.multiple == true"
    :request-method="requestMethod"
    :sizeLimit="props.maxSize ?? 2048"
    :show-image-file-name="false"
    @success="success"
    @remove="remove"
    :locale="{ sizeLimitMessage: `不能大于 ${props.maxSize ?? 2048}` }"
  >
    <template #fileListDisplay="{ files }">
      <ul class="t-upload__card">
        <li
          class="t-upload__card-item t-is-background"
          v-for="(img, i) in files"
        >
          <div class="t-upload__card-content t-upload__card-box">
            <img
              :src="img.url"
              style="width: 100%; height: 100%; object-fit: contain"
            />
            <div
              class="t-upload__card-mask"
              style="justify-content: space-around"
            >
              <ArrowLeftIcon v-if="multiple" @click="prev(i)" />
              <BrowseIcon @click="showImg(i)" />
              <DeleteIcon @click="remove(img)" />
              <ArrowRightIcon v-if="multiple" @click="next(i)" />
            </div>
          </div>
        </li>
        <li
          v-if="multiple || files.length == 0"
          class="t-upload__card-item t-is-background"
        >
          <div
            class="t-upload__image-add t-upload__card-container t-upload__card-box"
            @click="uploadRef.triggerUpload()"
          >
            <AddIcon />
            <p class="t-size-s t-upload__add-text">点击上传图片</p>
          </div>
        </li>
      </ul>
    </template>
  </t-upload>
</template>
