<script lang="tsx">
export default {
  name: "pagesOplogLogList"
};
</script>
<script setup lang="tsx">
import { DialogPlugin, MessagePlugin, PageInfo } from "tdesign-vue-next";
import { useSettingStore } from "@/store";
import TImg from "@/components/TImg.vue";

import UTILS from "@/utils";

const COLUMNS: columns<OpLog>[] = [
  { colKey: "row-select", type: "multiple", width: 60, fixed: "left" },
  {
    title: "日志ID",
    colKey: "id",
    fixed: "left",
    width: 120,

  },

  {
    title: "操作页面",
    colKey: "name",
    width: 120,

  },
  {
    title: "操作日志",
    colKey: "content",
  },
  {
    title: "操作时间",
    colKey: "creadTime",
    width: 180,

  },
  {
    title: "操作人",
    colKey: "creadBy",
    width: 120,
    cell(_, { row }) {
      return row.creadBy?.name;
    },
  }
];

const store = useSettingStore();
const userstore = useUserStore();

const tableHeight = ref(0);
watchEffect(() => {
  const height = store.getContentHeight();
  nextTick(() => {
    const headerEl = document.querySelector(".contentheader");
    const pageEl = document.querySelector(".t-table__pagination");
    tableHeight.value =
      height -
      UTILS.getOuterHeight(headerEl) -
      UTILS.getOuterHeight(pageEl) -
      40;
  });
});

const data = ref([]);
const pagination = ref({
  defaultPageSize: 20,
  total: 100,
  defaultCurrent: 1,
});

const dataLoading = ref(false);
const pageData = ref<OpLogPage>({
  pageNum: 1,
  pageSize: 20,
  entity: { ...INIT.OpLog },
});
const fetchData = async () => {
  dataLoading.value = true;
  try {
    const PageData = await API.OpLog.GetList(pageData.value);
    data.value = PageData?.dataList;
    pagination.value = {
      ...pagination.value,
      total: PageData?.totalCount,
    };
  } catch (e) {
    console.log(e);
  } finally {
    dataLoading.value = false;
  }
};

const search = () => {
  pageData.value.pageNum = 1;
  fetchData();
};
const reset = () => {
  pageData.value = {
    pageNum: 1,
    pageSize: 20,
    entity: { ...INIT.OpLog }
  };
  fetchData();
};
onMounted(() => {
  fetchData();
});

const selectedRowKeys = ref([]);

const rowKey = "id";

const rehandleSelectChange = (val: (string | number)[]) => {
  selectedRowKeys.value = val as number[];
};
const pageChange = (pageInfo: PageInfo) => {
  console.log("分页变化", pageInfo);
  pageData.value.pageNum = pageInfo.current;
  pageData.value.pageSize = pageInfo.pageSize;
  fetchData();
};
const rehandleChange = (changeParams: unknown, triggerAndData: unknown) => {
  console.log("统一Change", changeParams, triggerAndData);
};

</script>
<template>
  <div>
    <t-card class="list-card-container" :bOpLoged="false" style="padding-top: 8px">
      <div class="contentheader" style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        ">
        <div style="display: flex; align-items: center">

          <p v-if="!!selectedRowKeys.length" style="margin-left: 10px; color: var(--td-text-color-secondary)">
            已选{{ selectedRowKeys.length }}项
          </p>
        </div>
        <div style="display: flex">
          <t-input style="width: 180px;" v-model="pageData.entity.name" placeholder="操作页面" />
          <t-input style="width: 150px;" v-model="pageData.entity.content" placeholder="操作日志" />
          <t-button theme="primary" @click="search" style="margin-left: 10px">
            查询
          </t-button>
          <t-button variant="base" @click="reset" theme="default">
            重置
          </t-button>
        </div>
      </div>
      <t-table :data="data" :columns="COLUMNS" :row-key="rowKey" vertical-align="top" :hover="true" :bordered="true"
        :pagination="pagination" :selected-row-keys="selectedRowKeys" :loading="dataLoading" :selectOnRowClick="false"
        @page-change="pageChange" @change="rehandleChange" :max-height="tableHeight" :expand-icon="true"
        @select-change="rehandleSelectChange">
      </t-table>
    </t-card>
  </div>
</template>
