<script lang="ts">
export default {
  name: "pagesGoodsGoodsImport"
};
</script>
<script setup lang="ts">
import {
  DataOption,
  TargetParams,
  TransferValue,
} from "tdesign-vue-next/es/transfer/type";

const props = defineProps<{ visible: boolean }>();
const emit = defineEmits(["submit", "close"]);
const onSubmit = () => {
  const selectProduct = productList.value.filter((s) =>
    selectCodes.value.includes(s.code),
  );
  if (selectProduct.length > 0) emit("submit", selectProduct);
};

const onClose = () => {
  emit("close");
};

const productList = ref<BipGoods[]>();
const options = ref<DataOption[]>();
const selectCodes = ref([]);
onMounted(() => {
  getData();
});

const getData = async () => {
  productList.value = await API.Goods.GetBipGoods("");
  options.value = productList.value.map((s) => ({
    label: s.name + s.danWei,
    value: s.code,
  }));
  selectCodes.value = productList.value
    .filter((s) => s.isImport)
    .map((s) => s.code);
};

watch(props, () => {
  if (props.visible) getData();
  else
    selectCodes.value = productList.value
      .filter((s) => s.isImport)
      .map((s) => s.code);
});
</script>
<template>
  <t-dialog
    :visible="visible"
    :width="710"
    :close-btn="true"
    :closeOnOverlayClick="false"
    :closeOnEscKeydown="false"
    confirm-btn="提交"
    cancel-btn="取消"
    @confirm="onSubmit"
    @close="onClose"
  >
    <template #header>BIP商品列表</template>
    <t-transfer
      class="tran"
      v-model="selectCodes"
      :data="options"
      :search="true"
      direction="right"
    />
  </t-dialog>
</template>

<style lang="less" scoped>
.tran > *:not(.t-transfer__operations) {
  height: 400px;
  width: 300px;
}
</style>
