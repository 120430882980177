import { defineStore } from "pinia";
import API from "@/apis";

import { INIT } from "@/apis/defaultValues";
import { isEmpty } from "lodash-es";

export const useConfigStore = defineStore("config", {
  state: () => ({
    config: undefined,
  }),

  actions: {
    async GetConfig() {
      if (isEmpty(this.config)) {
        this.config = await API.Config.SysConfig();
      }
      return this.config;
    },
  },
});
