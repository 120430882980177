<script lang="ts">
export default {
  name: "pagesMobileAddress"
};
</script>
<script setup lang="ts">

import addressEditer from "./addressEditer.vue";

const visible = ref(false);

const editData = ref({ ...INIT.Address });

const onSubmit = async () => {
  getAddressList();
  visible.value = false;
};



const addressList = ref<Address[]>();
const getAddressList = async () => {
  addressList.value = await API.Mobile.GetAddressList();
};

const addOrEditData = (data: Address = INIT.Address) => {
  editData.value = { ...data };
  visible.value = true;
};





const userStore = useUserStore();
onMounted(async () => {
  getAddressList();
});

</script>

<template>
  <div class="body">
    <h3 class="head">
      收货地址
      <t-button variant="outline" theme="primary" size="small" @click="addOrEditData()">新增</t-button>
    </h3>
    <t-list split class="list">
      <t-list-item v-for="address in addressList">
        <t-list-item-meta>
          <template #description>
            <div>
              <div style="margin-bottom: 5px; color: #777">
                {{ address.province }} {{ address.city }} {{ address.region }}
                {{ address.town }}<t-tag theme="primary" variant="outline" style="margin-left: 3px;" v-if="address.isDefault"
                  size="small">默</t-tag>
              </div>
              <b>{{ address.detail }}</b>
              <div style="margin-top: 5px">
                {{ address.name }} {{ address.phone }}
              </div>
            </div>
          </template>
        </t-list-item-meta>
        <template #action>
          <t-link theme="primary" hover="color" @click="addOrEditData(address)">编辑</t-link>
        </template>
      </t-list-item>
    </t-list>
    <addressEditer :data="editData" @close="visible = false" @update="onSubmit" :visible="visible" />
  </div>
</template>

<style lang="less" scoped>
.body {
  padding: 0px;

  & .head {
    padding: 12px 20px 0 20px;
    display: flex;
    justify-content: space-between;
  }

  & .list {
    padding: 0 15px;
    font-size: 12px;
    max-height: calc(100vh - 100px);
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .empty {
    margin-top: 60%;
  }
}
</style>
