<script lang="tsx">
export default {
  name: "pagesCardBatchList"
};
</script>
<script setup lang="tsx">
import { DialogPlugin, MessagePlugin, PageInfo, SelectOption } from "tdesign-vue-next";
import { useSettingStore } from "@/store";
import AddEdit from "./batchEditer.vue";
import UTILS from "@/utils";
import { BatchState, CardKind, CardState } from "@/apis/enum";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";



const submit = async (data: CardBatch) => {
  const r =
    data.id > 0
      ? await API.Card.EditCardBatch(data)
      : await API.Card.AddCardBatch(data);
  if (r) {
    addEditVisible.value = false;
    MessagePlugin.success("提交成功");
    await fetchData();
  }
};

const cardTypes = ref<SelectOption[]>();

const getCardType = async () => {
  const r = await API.CardType.GetList({ pageSize: 0 });
  cardTypes.value = r.dataList.map((s) => ({ label: s.name, value: s.id }));
};


const COLUMNS: columns<CardBatch>[] = [
  { colKey: "row-select", type: "multiple", width: 60, fixed: "left" },
  {
    title: "批次ID",
    colKey: "id",
    fixed: "left",
    width: 80,
  },
  {
    title: "批次编号",
    colKey: "batchNo",
    fixed: "left",
    width: 100,
  },
  {
    title: "批次名称",
    colKey: "name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "卡类型",
    colKey: "cardType",
    cell(_, { row }) {
      return row.cardType?.name;
    },
    ellipsis: true,
    width: 120,
  },
  {
    title: "卡形态",
    colKey: "cardKind",
    cell(_, { row }) {
      const desc = UTILS.getEnumDesc(CardKind, row.cardKind);
      if (desc)
        return (
          <t-tag
            theme={row.cardKind == CardKind.ECard ? "primary" : "success"}
            variant="outline"
          >
            {desc}
          </t-tag>
        );
    },
    width: 90,
  },
  {
    title: "卡数量",
    colKey: "qty",
    width: 90,
  },
  {
    title: "卡号范围",
    colKey: "cardNoStart",
    cell(_, { row }) {
      return row.cardNoStart + " - " + row.cardNoEnd;
    },
    width: 220,
  },
  {
    title: "状态",
    colKey: "state",
    width: 90,
    cell(_, { row }) {
      const desc = UTILS.getEnumDesc(BatchState, row.state);
      if (desc)
        return (
          <t-tag
            theme={
              row.state == BatchState.NotReviewed
                ? "warning"
                : row.state == BatchState.Reviewed
                  ? "primary"
                  : row.state == BatchState.CardReady
                    ? "success"
                    : ""
            }
            variant="outline"
          >
            {desc}
          </t-tag>
        );
    },
  },
  {
    title: "生效日期",
    colKey: "effectiveTime",
    width: 120,
    cell(_, { row }) {
      return dayjs(row.effectiveTime).format("YYYY-MM-DD");
    },
  },
  {
    title: "过期日期",
    colKey: "expiredTime",
    width: 120,
    cell(_, { row }) {
      return dayjs(row.expiredTime).format("YYYY-MM-DD");
    },
  },
  {
    title: "有效期",
    colKey: "days",
    width: 80,
    cell(_, { row }) {
      return row.days + "天";
    },
  },
  {
    title: "备注",
    colKey: "remark",
  },
  {
    title: "创建时间",
    width: 180,
    colKey: "creadTime",
  },
  {
    title: "创建人",
    colKey: "creadBy",
    width: 80,
    cell(_, { row }) {
      return row.creadBy?.name;
    },
  },
];

const store = useSettingStore();

const tableHeight = ref(0);
watchEffect(() => {
  const height = store.getContentHeight();
  nextTick(() => {
    const headerEl = document.querySelector(".contentheader");
    const pageEl = document.querySelector(".t-table__pagination");
    tableHeight.value =
      height -
      UTILS.getOuterHeight(headerEl) -
      UTILS.getOuterHeight(pageEl) -
      40;
  });
});

const data = ref([]);
const pagination = ref({
  defaultPageSize: 20,
  total: 100,
  defaultCurrent: 1,
});

const dataLoading = ref(false);
const pageData = ref<CardBatchPage>({
  pageNum: 1,
  pageSize: 20,
  entity: { ...INIT.CardBatch, cardType: { ...INIT.CardType } },
});
const fetchData = async () => {
  dataLoading.value = true;
  try {
    const PageData = await API.Card.GetBatchList(pageData.value);
    data.value = PageData?.dataList;
    pagination.value = {
      ...pagination.value,
      total: PageData?.totalCount,
    };
  } catch (e) {
    console.log(e);
  } finally {
    dataLoading.value = false;
  }
};

const search = () => {
  pageData.value.pageNum = 1;
  fetchData();
};

const reset = () => {
  pageData.value = {
    pageNum: 1,
    pageSize: 20,
    entity: { ...INIT.CardBatch },
  };
  fetchData();
};

onMounted(() => {
  getCardType();
  fetchData();
});

const selectedRowKeys = ref<number[]>([]);

const rowKey = "id";

const rehandleSelectChange = (val: (string | number)[]) => {
  selectedRowKeys.value = val as number[];
};
const pageChange = (pageInfo: PageInfo) => {
  console.log("分页变化", pageInfo);
  pageData.value.pageNum = pageInfo.current;
  pageData.value.pageSize = pageInfo.pageSize;
  fetchData();
};
const rehandleChange = (changeParams: unknown, triggerAndData: unknown) => {
  console.log("统一Change", changeParams, triggerAndData);
};

const addEditVisible = ref(false);
const editData = ref<CardBatch>();
const addOrEditData = (id: number = 0) => {
  if (id == 0) editData.value = INIT.CardBatch;
  else editData.value = data.value.find((z) => z.id == id);
  addEditVisible.value = true;
};
const close = () => {
  addEditVisible.value = false;
};

const delData = (ids: number[]) => {
  const dialog = DialogPlugin.confirm({
    theme: "warning",
    header: "确认删除所选批次？",
    onConfirm: async () => {
      const r = await API.Card.DeleteCardBatch(ids);
      if (r) {
        MessagePlugin.success("删除成功");
        dialog.destroy();
        fetchData();
      }
    },
  });
};

const verified = (ids: number[]) => {
  const dialog = DialogPlugin.confirm({
    theme: "warning",
    header: "确认审核所选批次？",
    onConfirm: async () => {
      const r = await API.Card.VerifiedCardBatch(ids);
      if (r) {
        MessagePlugin.success("审核成功");
        dialog.destroy();
        fetchData();
      }
    },
  });
};

const makeCard = (ids: number[]) => {
  const dialog = DialogPlugin.confirm({
    theme: "warning",
    header: "确认所选批次制卡完成？",
    onConfirm: async () => {
      const r = await API.Card.MakeCard(ids);
      if (r) {
        MessagePlugin.success("操作成功");
        dialog.destroy();
        fetchData();
      }
    },
  });
};

const configstore = useConfigStore();
const { config } = storeToRefs(configstore);
const exportBatch = (id: number) => {
  const dialog = DialogPlugin.confirm({
    theme: "warning",
    header: "确认导出所选批次？",
    onConfirm: async () => {
      const r = await API.Card.ExportBatch(id);
      if (r) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('批次号'+r[0]?.batch?.batchNo, { views: [{ state: 'frozen', ySplit: 1 }] });
        worksheet.columns = [
          { header: '卡号', key: 'cardNo', width: 20 },
          { header: '链接', key: 'ref', width: 100 },
        ];
        worksheet.properties.defaultRowHeight = 20;
        worksheet.getRow(1).eachCell({ includeEmpty: true }, function (cell) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'AFEEEE' },
          };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          cell.alignment = { vertical: 'middle' };
        });
        worksheet.getRow(1).height = 20;

        r.forEach(s => {
          worksheet.addRow({ cardNo: s.cardNo, ref: config.value?.domain + "/mobile/card?key=" + s.key + "-" + s.cardNo });
          worksheet.lastRow?.eachCell({ includeEmpty: true }, function (cell) {
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
            cell.alignment = { vertical: 'middle' };
            if (worksheet.lastRow)
              worksheet.lastRow.height = 20;
          });
        });
        workbook.xlsx.writeBuffer().then((buffer: any) => {
          saveAs(new Blob([buffer], {
            type: 'application/octet-stream'
          }), `${r[0]?.batch?.batchNo}批次卡.xlsx`)
        })


        MessagePlugin.success("操作成功");
        dialog.destroy();
        fetchData();
      }
    },
  });
};


const cardKinds = UTILS.enumToOption(CardKind);
const batchStates = UTILS.enumToOption(BatchState);
</script>
<template>
  <div style="min-width: 1300px;">
    <t-card class="list-card-container" :bordered="false" style="padding-top: 8px">
      <div class="contentheader" style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        ">
        <div style="display: flex; align-items: center">
          <t-button @click="addOrEditData()" v-if="Per.CardAddCardBatch">
            新建</t-button>
          <t-button @click="addOrEditData(selectedRowKeys[0])" theme="success" v-if="Per.CardEditCardBatch"
            :disabled="selectedRowKeys.length != 1">
            编辑</t-button>
          <t-button @click="delData(selectedRowKeys)" theme="danger" v-if="Per.CardDeleteCardBatch"
            :disabled="selectedRowKeys.length == 0">
            删除</t-button>
          <t-button @click="verified(selectedRowKeys)" theme="warning" v-if="Per.CardVerifiedCardBatch"
            :disabled="selectedRowKeys.length == 0">
            审核</t-button>
          <t-button @click="exportBatch(selectedRowKeys[0])" theme="primary" v-if="Per.CardExportBatch"
            :disabled="selectedRowKeys.length != 1">
            导出</t-button>
          <t-button @click="makeCard(selectedRowKeys)" theme="success" v-if="Per.CardMakeCard"
            :disabled="selectedRowKeys.length == 0">
            制卡</t-button>

          <p v-if="!!selectedRowKeys.length" style="margin-left: 10px; color: var(--td-text-color-secondary)">
            已选{{ selectedRowKeys.length }}项
          </p>
        </div>
        <div style="display: flex">
          <t-input style="width: 180px;" v-model="pageData.entity.name" placeholder="批次名称" clearable />
          <t-input style="width: 180px;" v-model="pageData.entity.batchNo" placeholder="批次编号" clearable />
          <t-select style="width: 160px;" v-model="pageData.entity.cardTypeId" placeholder="卡类型" :options="cardTypes"
            clearable />
          <t-select style="width: 120px;" v-model="pageData.entity.cardKind" placeholder="卡形态" :options="cardKinds"
            clearable />
          <t-select style="width: 120px;" v-model="pageData.entity.state" placeholder="状态" :options="batchStates"
            clearable />

          <t-button theme="primary" @click="search" style="margin-left: 10px">
            查询
          </t-button>
          <t-button variant="base" @click="reset" theme="default">
            重置
          </t-button>
        </div>
      </div>
      <t-table :data="data" :columns="COLUMNS" :row-key="rowKey" vertical-align="top" :hover="true" :bordered="true"
        :pagination="pagination" :selected-row-keys="selectedRowKeys" :loading="dataLoading" :selectOnRowClick="false"
        @page-change="pageChange" @change="rehandleChange" :max-height="tableHeight"
        @select-change="rehandleSelectChange">
      </t-table>
    </t-card>
    <AddEdit v-model:visible="addEditVisible" :data="editData" @submit="submit" @close="close" />
  </div>
</template>
