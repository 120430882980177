<template>
  <t-dialog
    :visible="visible"
    :width="560"
    :close-btn="true"
    :closeOnOverlayClick="false"
    :closeOnEscKeydown="false"
    confirm-btn="提交"
    cancel-btn="取消"
    @confirm="onSubmit"
    @close="onClose"
  >
    <template #header>{{
      formData?.id > 0 ? "修改批次" : "新增批次"
    }}</template>
    <template #body>
      <t-form
        ref="form"
        class="base-form"
        :data="formData"
        :rules="FORM_RULES"
        label-align="top"
        @submit="onSubmit"
      >
        <t-row class="row-gap" :gutter="[30, 10]" style="margin: 0">
          <t-col :span="6">
            <t-form-item label="批次编号" name="batchNo">
              <t-input
                v-model="formData.batchNo"
                placeholder="批次编号"
                disabled
              />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="批次名称" name="name">
              <t-input
                v-model="formData.name"
                placeholder="批次名称"
                :disabled="formData.id > 0"
              />
            </t-form-item>
          </t-col>

          <t-col :span="6">
            <t-form-item label="卡类型" name="cardTypeId">
              <t-select
                v-model="formData.cardTypeId"
                placeholder="- 请选择 -"
                :options="cardTypes"
              />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="卡数量" name="qty">
              <t-input
                v-model="formData.qty"
                type="number"
                placeholder="数量"
                :disabled="formData.id > 0"
              />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="卡形态" name="cardKind">
              <t-select
                v-model="formData.cardKind"
                placeholder="- 请选择 -"
                :options="cardKinds"
              />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="生效日期" name="effectiveTime">
              <t-date-picker
                v-model="formData.effectiveTime"
                placeholder="生效日期"
                clearable
                style="width: 100%"
                @change="calcExTime"
              />
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="有效期" name="days">
              <t-input-group style="width: 100%">
                <t-input
                  style="width: calc(100% - 60px)"
                  v-model="timeValue"
                  type="number"
                  placeholder="有效期"
                  @change="calcExTime"
                />
                <t-select
                  style="width: 60px; margin-left: -1px"
                  v-model="timeType"
                  placeholder="- 请选择 -"
                  :options="[
                    { label: '年', value: 'year' },
                    { label: '月', value: 'month' },
                    { label: '天', value: 'day' },
                  ]"
                  @change="calcExTime"
                />
              </t-input-group>
            </t-form-item>
          </t-col>
          <t-col :span="6">
            <t-form-item label="过期日期" name="expiredTime">
              <t-date-picker
                v-model="formData.expiredTime"
                placeholder="过期日期"
                disabled
                style="width: 100%"
              />
            </t-form-item>
          </t-col>
          <t-col :span="12">
            <t-form-item label="备注" name="effectiveTime">
              <t-input v-model="formData.remark" placeholder="备注" />
            </t-form-item>
          </t-col>
          </t-row>
      </t-form>
    </template>
  </t-dialog>
</template>

<script lang="ts">
export default {
  name: "pagesCardBatchEditer"
};
</script>
<script setup lang="ts">
import { CardKind } from "@/apis/enum";
import UTILS from "@/utils";
import dayjs from "dayjs";
import {
  FormInstanceFunctions,
  FormRule,
  SelectOption,
  SelectProps,
  SelectValue,
} from "tdesign-vue-next";

const FORM_RULES: Record<string, FormRule[]> = {
  name: [{ required: true, message: "请输入批次名称", type: "error" }],
  qty: [
    { required: true, message: "请输入数量", type: "error" },
    { number: true, message: "请输入数量", type: "error" },
  ],
  cardTypeId: [{ required: true, message: "请选择卡类型", type: "error" }],
  cardKind: [{ required: true, message: "请选择卡形态", type: "error" }],
};

const timeType = ref<"year" | "month" | "day">("year");
const timeValue = ref<number>(1);

const calcExTime = () => {
  if (formData.value.effectiveTime && timeType.value && timeValue.value) {
    formData.value.expiredTime = dayjs(formData.value.effectiveTime)
      .add(timeValue.value, timeType.value)
      .format("YYYY-MM-DD");
    formData.value.days = dayjs(formData.value.expiredTime).diff(
      dayjs(formData.value.effectiveTime),
      "day",
    );
  } else {
    formData.value.expiredTime = undefined;
    formData.value.days = 0;
  }
};

const cardKinds = UTILS.enumToOption(CardKind);
const cardTypes = ref<SelectOption[]>();

interface Props {
  visible: boolean;
  data?: User;
}
const form = ref<FormInstanceFunctions>(null);

const props = defineProps<Props>();
const formData = ref<CardBatch>(INIT.CardBatch);

const imgUploadKey = ref(0);
watch(props, () => {
  if (props.visible) {
    form.value.reset();
    imgUploadKey.value = dayjs().unix();
    formData.value = props.data ? { ...props.data } : { ...INIT.CardBatch };

    if (!formData.value.days) formData.value.days = 365;
    timeType.value = "day";
    timeValue.value = formData.value.days;
  }
});

const getCardType = async () => {
  const r = await API.CardType.GetList({ pageSize: 0 });
  cardTypes.value = r.dataList.map((s) => ({ label: s.name, value: s.id }));
};
onMounted(() => {
  getCardType();
});

const emit = defineEmits(["submit", "close"]);
const onSubmit = () => {

  form.value.validate().then((r) => {
    if (r === true) emit("submit", formData.value);
  });
};

const onClose = () => {
  emit("close");
};
</script>
