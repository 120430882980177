<script lang="tsx">
export default {
  name: "pagesMobileCard"
};
</script>
<script setup lang="tsx">
import { AmountOrCount, CardState } from "@/apis/enum";
import dayjs from "dayjs";
import {
  DialogPlugin,
  ListProps,
  MessagePlugin,
  PageInfo,
  RadioValue,
  TabValue,
} from "tdesign-vue-next";
import { ScanIcon } from "tdesign-icons-vue-next";
import exchange from "./exchange.vue";
import { isEmpty } from "lodash-es";
import { scanQRCode } from "tencent-wx-jssdk";
import UTILS from "@/utils";

const route = useRoute();
const router = useRouter();

const useStore = useUserStore();
const rData = ref<CardInfoPageData>();
const pageData = ref<CardInfoPage>({
  pageNum: 1,
  pageSize: 20,
  entity: { cardState: CardState.Received },
});

const getData = async () => {
  const r = await API.Mobile.GetCard(pageData.value);
  rData.value = r;
};

const userStore = useUserStore();

onMounted(async () => {
  if (!isEmpty(route.query.key)) {
    const key = Array.isArray(route.query.key) ? route.query.key[0] : route.query.key;
    const r = await API.Mobile.AttachCard(key.split("-")[0], key.split("-")[1]);
    if (r) {
      MessagePlugin.success("添加卡 " + r + " 成功！");
      router.replace("/mobile/card");
    }
  }
  getData();
});
const exchangeVisible = ref(false);
const cardInfo = ref<Order>();
const openExchange = (card: CardInfo) => {
  cardInfo.value = card;
  exchangeVisible.value = true;
};
const submit = async (data: Order) => {

  const r = await API.Mobile.Exchange(data);
  if (r) {
    const alert = DialogPlugin.alert({
      header: '提交成功',
      theme: 'success',
      width: "95%",
      closeBtn: false,
      onConfirm: () => alert.destroy(),
      body: () => <div>兑换成功，订单编号：<t-link theme="primary" underline onClick={() => {
        alert.destroy();
        router.push('/mobile/order')
      }}> {r} </t-link></div>
    });
    exchangeVisible.value = false;
    getData();
  }
};
const close = () => {
  exchangeVisible.value = false;
};

const onChange = (pageInfo: PageInfo) => {
  pageData.value.pageNum = pageInfo.current;
  pageData.value.pageSize = pageInfo.pageSize;
  getData();
};

const tabChange = (value: RadioValue) => {
  pageData.value.entity.cardState = value as any;
  getData();
};

const scan = () => {
  scanQRCode({
    needResult: 1,
    scanType: ["qrCode", "barCode"],
    success: async (res) => {
      try {
        const url = new URL(res.resultStr);
        const params = new URLSearchParams(url.search.replace("?", "&"));
        const key = params.get("key");
        if (key) {
          const r = await API.Mobile.AttachCard(key.split("-")[0], key.split("-")[1]);
          if (r) {
            MessagePlugin.success("添加卡 " + r + " 成功！");
            getData();
          }
        } else {
          throw "cardNo为空";
        }
      } catch {
      }
    },
    fail: (res) => {
      MessagePlugin.error("扫码失败");
    }
  });
};


const keys: typeof pageData.value.entity.cardState[] = [CardState.Received, CardState.InUse, CardState.Done, CardState.Expired]

let startX = 0;
let startY = 0;
const touchstart = (event: TouchEvent) => {
  startX = event.changedTouches[0].pageX;
  startY = event.changedTouches[0].pageY;
}
const touchend = (event: TouchEvent) => {
  const ele = event.target as Element;
  const parents = UTILS.getAllParents(ele);
  if (parents.some(s => s.className.startsWith("t-tabs") || s.className.startsWith("t-dialog")) 
  || Math.abs(event.changedTouches[0].pageY - startY) > Math.abs(event.changedTouches[0].pageX - startX))
    return;
  let index = keys.indexOf(pageData.value?.entity?.cardState)
  if (event.changedTouches[0].pageX - startX > 10) index--;
  else if (event.changedTouches[0].pageX - startX < -10) index++;
  if (index < 0 || index >= keys.length) return;
  pageData.value.entity.cardState = keys[index];

  getData();
}



</script>

<template>
  <div class="body" @touchstart="touchstart" @touchend="touchend">

    <t-tabs v-model="pageData.entity.cardState" @change="tabChange">
      <t-tab-panel :value="CardState.Received" label="未使用"> </t-tab-panel>
      <t-tab-panel :value="CardState.InUse" label="使用中"> </t-tab-panel>
      <t-tab-panel :value="CardState.Done" label="已完成"> </t-tab-panel>
      <t-tab-panel :value="CardState.Expired" label="已过期"> </t-tab-panel>
    </t-tabs>
    <ScanIcon style="position: absolute;margin-top: -34px;margin-left: calc(100% - 50px);z-index: 999;" size="20"
      @click="scan()" />

    <t-list split class="tlist">
      <t-list-item v-for="item in rData?.dataList">
        <t-list-item-meta class="meta">
          <template #image>
            <div class="imgContainer">
              <img :src="useStore.getImgUrl(item?.cardType?.image?.fileUrl)" />

            </div>
          </template>
          <template #description>
            <div style="font-size: 12px; margin-top: 4px">
              卡号：{{ item.cardNo }} <span style="margin-left: 8px"> {{ item.batch?.name }}
              </span>
            </div>
            <div style="font-size: 12px; margin-top: 4px"
              v-if="item.cardType.amountOrCount == AmountOrCount.AmountCard">
              <span>剩余：{{
                item.leftAmount }}元
              </span>
              <span style="margin-left: 8px">已兑换：{{
                item.cardType?.amount - item.leftAmount }}元
              </span>
            </div>
            <div style="font-size: 12px; margin-top: 4px" v-else>
              <span>剩余：{{
                item.leftCount }}次
              </span>
              <span style="margin-left: 8px">已兑换：{{
                item.cardType?.count - item.leftCount }}次
              </span>
            </div>
            <div style="font-size: 12px">
              有效期：{{
                dayjs(item.batch?.effectiveTime).format("YYYY-MM-DD") +
                " ~ " +
                dayjs(item.batch.expiredTime).format("YYYY-MM-DD")
              }}
            </div>
          </template>
        </t-list-item-meta>
        <template #action v-if="item.cardState == CardState.Received || item.cardState == CardState.InUse">
          <t-link theme="primary" hover="color" style="margin-left: 16px" @click="openExchange(item)">兑换</t-link>
        </template>
      </t-list-item>
      <template #footer v-if="rData?.pageCount > 1">
        <t-pagination class="page" v-model="pageData.pageNum" :showPageSize="false" :totalContent="false"
          v-model:pageSize="pageData.pageSize" :total="rData?.totalCount" @change="onChange" />
      </template>
    </t-list>
    <t-empty class="empty" v-if="rData?.dataCount == 0" />
    <exchange v-model:visible="exchangeVisible" :data="cardInfo" @submit="submit" @close="close" />
  </div>

</template>

<style lang="less" scoped>
.body {
  padding: 0 10px;

  & .tlist {
    max-height: calc(100vh - 100px);
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .empty {
    margin-top: 60%;
  }
}

.page {
  justify-content: center;
}

.meta {
  align-items: center;

  :first-child {
    border-radius: 0;
    height: auto;
  }

  .imgContainer {
    display: flex;
  }
}
</style>
